import React, { memo } from 'react'
import {
  BWPanelHeader,
  BWInput,
  BWText,
  BWLink,
  BWPanelContent,
  emailRule,
  requiredRule,
  passwordRule
} from 'src/libs/qb-brand-web-components'
import { Link } from 'react-router-dom'

import { FORGOT_PASSWORD_URL, SIGNUP_URL } from '../../constants/navigation'
import { LOGIN_PROFILE_DISPATCH } from '../../store/reducers/auth/login'
import { ERROR_TEXT, LABEL_TEXT } from '../../constants/messages'
import Button from '../shared/button/Button'
import SocialMediaWrapper from 'wrappers/SocialMediaWrapper'
import customConfig from 'customConfig'
import Panel from '../shared/panel/Panel'

const Login = ({
  onFormSubmit,
  dispatchAction,
  onResendVerification,
  loginProfile: { error, success, email, password, isProcessing },
  onSocialMediaLogin,
  t
}) => {
  const resendVerifyEmailLink = (error) => {
    if (error && error === ERROR_TEXT.EMAIL_VERIFICATION_FAILED) {
      return (
        <>
          {t(error)}{' '}
          <BWLink
            as='a'
            size={10}
            label={t('common.resend-verification-email')}
            onClick={onResendVerification}
          />
        </>
      )
    }
    return typeof error === 'boolean' ? error : t(error)
  }

  return (
    <Panel onFormSubmit={onFormSubmit} as='form'>
      <BWPanelHeader
        title={t(customConfig.loginPageTitle)}
        description={t(customConfig.loginPageDescLabel)}
      />
      <BWPanelContent>
        <SocialMediaWrapper
          label={LABEL_TEXT.LOGIN}
          t={t}
          onSocialMediaLogin={onSocialMediaLogin}
        />
        <BWInput
          id='email'
          type='email'
          label={t('common.email-address')}
          error={resendVerifyEmailLink(buildEmailErrors(error))}
          success={t(success[LOGIN_PROFILE_DISPATCH.EMAIL])}
          value={email}
          validate={[requiredRule, emailRule]}
          onChange={(value) =>
            dispatchAction(LOGIN_PROFILE_DISPATCH.EMAIL, value)
          }
        />
        <BWInput
          id='password'
          label={t('common.password')}
          eyePassword
          error={t(error[LOGIN_PROFILE_DISPATCH.PASSWORD])}
          value={password}
          validate={[requiredRule, passwordRule]}
          onChange={(value) =>
            dispatchAction(LOGIN_PROFILE_DISPATCH.PASSWORD, value)
          }
        />
        <BWText
          alignCenter
          label={
            <>
              <BWText label={t('login.forgot-your-password')} />
              <BWLink
                label={t(customConfig.resetItLabel)}
                to={FORGOT_PASSWORD_URL}
                as={Link}
              />
            </>
          }
        />
      </BWPanelContent>
      <Button
        id='login-button'
        label={t('login.log-in')}
        type='submit'
        disabled={isProcessing}
      />
      <BWText
        alignCenter
        label={
          <>
            <BWText label={t('login.no-account-yet')} />
            <BWLink label={t('sign-up.sign-up')} to={SIGNUP_URL} as={Link} />
          </>
        }
      />
    </Panel>
  )
}

export default memo(Login)

const buildEmailErrors = (error) => {
  const defaultError = error[LOGIN_PROFILE_DISPATCH.EMAIL]
  if (defaultError) {
    return defaultError
  }
}
