import { breakpoints, SIZE_UNIT } from '../themes/units'

const mq = (n) => {
  const mqWidth = n === SIZE_UNIT.SM ? 'max' : 'min'
  return `@media only screen and (${mqWidth}-width: ${
    breakpoints[SIZE_UNIT.SM]
  }px)`
}

export { mq }
