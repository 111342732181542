import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { buildFooterSiteLinks } from '../../../util/siteLink.helpers'
import Footer from '../../../components/shared/footer/Footer'
import customConfig from 'customConfig'

const FooterWrapper = ({ noPoweredBy = false, ...restProps }) => {
  const { t } = useTranslation()
  return (
    <Footer
      links={buildFooterSiteLinks(t)}
      linkColor={customConfig.footerColor}
      noPoweredBy={noPoweredBy}
      {...restProps}
    />
  )
}

export default memo(FooterWrapper)
