import React, { memo } from 'react'
import {
  BWNoRecordsFound,
  BWTransactionCard,
  BWPageContent,
  BWLoading
} from 'src/libs/qb-brand-web-components'
import { useTranslation } from 'react-i18next'

import { formatTransaction } from '../../util/transactionRow.helpers'
import brandConfig from 'brandConfig'
import customConfig from 'customConfig'
import PageHeaderWrapper from 'wrappers/PageHeaderWrapper'
import { TRANSACTION_STATUS_LABEL } from '../../constants/transactions'

const Transactions = ({
  transactions,
  tokenProfile,
  loading,
  accountProfile,
  onShowFuturePointsInfo
}) => {
  const { t } = useTranslation()
  return (
    <>
      <PageHeaderWrapper
        label={t(customConfig.allYourTransactionsLabel)}
        icon={customConfig.transactionsIcon}
        header
      />
      {transactions.length <= 0 ? (
        <BWNoRecordsFound
          icon={customConfig.transactionsIcon}
          description={t(customConfig.noTransactionsLabel)}
        />
      ) : (
        <BWPageContent>
          {transactions.map((transaction, index) => {
            const { status, isNft, ...restProps } = formatTransaction(
              transaction,
              t,
              tokenProfile,
              accountProfile.authId
            )
            const isFutureReward = status === TRANSACTION_STATUS_LABEL.FUTURE
            return (
              <BWTransactionCard
                key={index}
                onClick={() =>
                  isFutureReward ? onShowFuturePointsInfo(isNft) : null
                }
                anchor={isFutureReward}
                iconColor={brandConfig.prefIconColor}
                status={status}
                {...restProps}
              />
            )
          })}
          {loading && (
            <BWLoading isInvert iconColor={brandConfig.prefIconColor} />
          )}
        </BWPageContent>
      )}
    </>
  )
}

export default memo(Transactions)
