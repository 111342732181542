import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  useLanguageSelect,
  getLanguageLabelsByPref
} from 'src/libs/qb-brand-web-components'

import i18n from '../i18n'
import { updateUserAttributes } from '../store/actions/auth'
import { getSupportedLanguages } from '../util/translation.helpers'
import brandConfig from 'brandConfig'

const requireLanguage = (ChildComponent) => {
  const ComposedComponent = (props) => {
    const { accountProfile, updateUserAttributes } = props

    // Get saved language or default to i18n language
    const savedLanguage = accountProfile
      ? accountProfile.language
      : i18n.language

    // Hook to manage language selection
    const [selectedLanguage, openLanguagePopup, renderLanguagePopup] =
      useLanguageSelect(
        i18n,
        getLanguageLabelsByPref(getSupportedLanguages()),
        updateUserAttributes,
        accountProfile?.language,
        brandConfig.invertLanguageBar
      )

    return (
      <ChildComponent
        {...props}
        selectedLanguage={selectedLanguage || savedLanguage}
        openLanguagePopup={openLanguagePopup}
        renderLanguagePopup={renderLanguagePopup}
      />
    )
  }

  return connect(null, mapDispatchToProps)(ComposedComponent)
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAttributes
    },
    dispatch
  )

export default requireLanguage
