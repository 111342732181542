import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  cssCustomGap,
  cssGap,
  CSS_ALIGN_ITEMS_CENTER,
  CSS_GRID,
  CSS_GRID_COLUMN,
  CSS_JUSTIFY_CONTENT_CENTER,
  CSS_TEXT_ALIGN_CENTER,
  CSS_FLEX
} from '../../styles/shared'
import BWSiteLinks from '../BWSiteLinks'
import { mq } from '../../util/theme.helpers'
import { SIZE_UNIT } from '../../themes/units'

const BWFooter = ({ languageLink, links, children, customCss, linkColor }) => {
  const footerLinkLength =
    links && links.reduce((current, next) => next.label.length + current, 0)
  return (
    <FooterContainer css={customCss} data-testid='bw-footer'>
      <FooterLinks css={footerLinkLength > 50 && CSS_CUSTOM_FOOTER_LINKS}>
        {languageLink}
        {links && <BWSiteLinks color={linkColor} links={links} fontSize={12} />}
      </FooterLinks>
      {children}
    </FooterContainer>
  )
}

export default memo(BWFooter)

const FooterContainer = styled.div`
  ${CSS_GRID};
  ${CSS_JUSTIFY_CONTENT_CENTER};
  ${CSS_ALIGN_ITEMS_CENTER};
  gap: 5px;
  ${mq(SIZE_UNIT.MD)} {
    padding-top: 30px;
  }
`

const FooterLinks = styled.div`
  ${CSS_FLEX};
  ${cssGap(SIZE_UNIT.XL)};
  ${CSS_GRID_COLUMN};
`

const CSS_CUSTOM_FOOTER_LINKS = css`
  ${cssCustomGap(5)};
  ${CSS_TEXT_ALIGN_CENTER};
  justify-content: center;
  flex-wrap: wrap;
`
