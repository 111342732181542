import {
  mergeMap,
  catchError,
  map,
  tap,
  switchMap,
  takeUntil,
  startWith
} from 'rxjs/operators'
import { interval, of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'

import {
  BUY_POINTS_ACTION,
  EXCHANGE_ACTION,
  EXCHANGE_PROVIDER_ACTION
} from '../../constants/actions'

import {
  extractTokenId,
  extractUserId,
  wrapUserAccessToken
} from '../../util/auth.helpers'
import { formatMembershipsToObject } from '../../util/pointsExchange.helpers'
import { formatRewardTypes } from '../../util/epics.helpers'
import recaptchaService from '../../services/recaptcha'
import { LOYALTY_EVENT_TYPES } from '../../constants/transactions'
import { commonParser } from '../../util/apiParser.helpers'
import { CRYPTO_EXCHANGE_RATES_INTERVAL } from '../../constants/pointsExchange'

// MILES EXCHANGE PROVIDERS
const handleConnectExchangeProvider = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER),
    mergeMap(({ payload: { membership } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createUserMembership(extractUserId(state$), membership, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleUpdateExchangeProvider = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER),
    mergeMap(({ payload: { membership } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createUserMembership(extractUserId(state$), membership, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetUserMembershipsAfterMembershipUpdate = (action$, state$) =>
  action$.pipe(
    ofType(
      EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER_SUCCESS,
      EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER_SUCCESS
    ),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService.getUserMemberships(extractUserId(state$), accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: EXCHANGE_PROVIDER_ACTION.ON_GET_USER_MEMBERSHIPS_SUCCESS,
            payload: formatMembershipsToObject(response.data)
          })),
          catchError((err) =>
            of({
              type: EXCHANGE_PROVIDER_ACTION.ON_GET_USER_MEMBERSHIPS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleGetRewardTypesForBrand = (action$) =>
  action$.pipe(
    ofType(EXCHANGE_PROVIDER_ACTION.ON_GET_REWARD_TYPES_FOR_BRAND),
    mergeMap(({ payload }) =>
      apiService.getRewardTypes(payload).pipe(
        tap((data) => logger(data)),
        map(({ response }) => ({
          type: EXCHANGE_PROVIDER_ACTION.ON_GET_REWARD_TYPES_FOR_BRAND_SUCCESS,
          payload: formatRewardTypes(response.data)
        })),
        catchError((err) =>
          of({
            type: EXCHANGE_PROVIDER_ACTION.ON_GET_REWARD_TYPES_FOR_BRAND_FAILED,
            payload: err
          })
        )
      )
    )
  )

const handleGetCryptoExchangeRates = (action$) =>
  action$.pipe(
    ofType(EXCHANGE_ACTION.ON_START_CRYPTO_EXCHANGE_RATES_POLLING),
    switchMap(() =>
      interval(CRYPTO_EXCHANGE_RATES_INTERVAL * 1000).pipe(
        startWith(0),
        switchMap(() =>
          wrapUserAccessToken((accessToken) =>
            apiService.getCryptoExchangeRates(accessToken).pipe(
              tap((data) => logger(data)),
              map(({ response }) => ({
                type: EXCHANGE_ACTION.ON_GET_CRYPTO_EXCHANGE_RATES_SUCCESS,
                payload: response.data
              })),
              catchError((err) =>
                of({
                  type: EXCHANGE_ACTION.ON_GET_CRYPTO_EXCHANGE_RATES_FAILED,
                  payload: err
                })
              )
            )
          )
        ),
        takeUntil(
          action$.ofType(EXCHANGE_ACTION.ON_STOP_CRYPTO_EXCHANGE_RATES_POLLING)
        )
      )
    )
  )

const handleReceiveExternalRewardFromBrandPoints = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS),
    mergeMap(
      ({
        payload: {
          provider: { brandId, symbol, tokenId },
          amount,
          accountNumber
        }
      }) =>
        wrapUserAccessToken((accessToken) =>
          recaptchaService.execute$().pipe(
            mergeMap((recaptchaToken) =>
              apiService
                .receiveExternalRewardFromBrandPoints(
                  LOYALTY_EVENT_TYPES.EXCHANGE,
                  {
                    amount,
                    accountNumber,
                    brandId,
                    symbol,
                    tokenId: extractTokenId(state$),
                    toTokenId: tokenId
                  },
                  accessToken,
                  recaptchaToken
                )
                .pipe(
                  tap((data) => logger(data)),
                  map(({ response }) => ({
                    type: EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_SUCCESS,
                    payload: response
                  })),
                  catchError((err) =>
                    of({
                      type: EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_FAILED,
                      payload: err
                    })
                  )
                )
            )
          )
        )
    )
  )

const handleGenerateBuyPointsCheckoutSessionEpic = (action$, state$) =>
  action$.pipe(
    ofType(BUY_POINTS_ACTION.ON_GENERATE_CHECKOUT_SESSION),
    mergeMap(({ payload: { pointsAmount } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .generateBuyPointsCheckoutSession(
            {
              points_amount: parseInt(pointsAmount),
              token_id: extractTokenId(state$)
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: BUY_POINTS_ACTION.ON_GENERATE_CHECKOUT_SESSION_SUCCESS,
              payload: response.data
            })),
            catchError((error) =>
              of({
                type: BUY_POINTS_ACTION.ON_GENERATE_CHECKOUT_SESSION_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleGetPaymentSessionEpic = (action$) =>
  action$.pipe(
    ofType(BUY_POINTS_ACTION.ON_GET_PAYMENT_SESSION),
    mergeMap(({ payload: { sessionId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getPaymentSession(sessionId, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: BUY_POINTS_ACTION.ON_GET_PAYMENT_SESSION_SUCCESS,
            payload: commonParser(response.data)
          })),
          catchError((error) =>
            of({
              type: BUY_POINTS_ACTION.ON_GET_PAYMENT_SESSION_FAILED,
              payload: error
            })
          )
        )
      )
    )
  )

export const exchangeEpic = combineEpics(
  // EXCHANGE PROVIDERS
  handleConnectExchangeProvider,
  handleUpdateExchangeProvider,

  // MEMBERSHIP
  handleGetUserMembershipsAfterMembershipUpdate,

  // GET REWARD TYPES FOR BRAND
  handleGetRewardTypesForBrand,

  // CRYPTO EXCHANGE RATES
  handleGetCryptoExchangeRates,

  // RECEIVE EXTERNAL REWARDS
  handleReceiveExternalRewardFromBrandPoints,

  // BUY POINTS CHECKOUT SESSION
  handleGenerateBuyPointsCheckoutSessionEpic,
  handleGetPaymentSessionEpic
)
