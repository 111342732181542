import React, { memo } from 'react'

import CopyrightText from '../../../components/shared/copyrightText/CopyrightText'
import brandConfig from 'brandConfig'

const CopyrightTextWrapper = (props) => {
  return <CopyrightText brandName={brandConfig.name} {...props} />
}

export default memo(CopyrightTextWrapper)
