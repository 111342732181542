import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { EXCHANGE_ACTION } from '../../../constants/actions'

export const cryptoExchangeProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_ACTION.ON_GET_CRYPTO_EXCHANGE_RATES_SUCCESS:
      return mergeToNewObject(state, { cryptoExchanges: payload })

    default:
      return state
  }
}

const INITIAL_STATE = {
  cryptoExchanges: {}
}
