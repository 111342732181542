import React, { Fragment, memo } from 'react'

import BWText from '../BWText'

const BWCopyrightText = ({ brandName, fontSize }) => {
  return (
    <BWText
      data-testid='bw-copyright-text'
      alignCenter
      size={fontSize}
      label={
        <Fragment>
          Copyright &copy; {new Date().getFullYear()} {brandName}
        </Fragment>
      }
    />
  )
}

export default memo(BWCopyrightText)
