import React, { lazy } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import brandConfig from 'brandConfig'
import AuthorizedLayoutWrapper from 'wrappers/AuthorizedLayoutWrapper'
import { buildMultiLanguageLink } from '../components/shared/buildMultiLanguageLink'
import TermsNotAcceptedWrapper from 'wrappers/TermsNotAcceptedWrapper'
import { extractCognitoUserTermsAccepted } from '../util/auth.helpers'

import {
  signOut,
  changeSignupInputAndErrorState,
  changeSignupState,
  acceptTerms
} from '../store/actions/auth'
import { SIGNUP_PROFILE_DISPATCH } from '../store/reducers/auth/signup'

const PointsLink = lazy(
  () => import('../components/navHeader/headerLinks/PointsLink')
)
const ExchangePointsLink = lazy(
  () => import('../components/navHeader/headerLinks/ExchangePointsLink')
)
const NftLink = lazy(
  () => import('../components/navHeader/headerLinks/NftLink')
)
const CashbackLink = lazy(
  () => import('../components/navHeader/headerLinks/CashbackLink')
)
const ImpactReportLink = lazy(
  () => import('../components/navHeader/headerLinks/ImpactReportLink')
)
const EngravingMessagesLink = lazy(
  () => import('../components/navHeader/headerLinks/EngravingMessagesLink')
)

const AuthorizedLayoutContainer = ({
  isExchangeEnabled,
  isNftEnabled,
  children,
  authProfile: { cognitoUser, initSignOut },
  signOut,
  changeSignupState,
  acceptTerms,
  signupProfile,
  changeSignupInputAndErrorState,
  brandAppConfig,
  enablePurchasePoints,
  ...restProps
}) => {
  const { selectedLanguage, openLanguagePopup, renderLanguagePopup } = restProps

  const handleAcceptTermsSubmit = () => {
    const { termsAccepted, privacyPolicyAccepted, mandatoryCheckbox } =
      signupProfile
    const { features } = brandConfig
    const {
      disableTermsCheckbox,
      disablePrivacyCheckbox,
      enableMandatoryCheckbox
    } = features
    const termsPolicy = !disableTermsCheckbox ? termsAccepted : true
    const privacyPolicy = !disablePrivacyCheckbox ? privacyPolicyAccepted : true
    if (enableMandatoryCheckbox) {
      if (termsPolicy && privacyPolicy && mandatoryCheckbox) {
        acceptTerms()
      } else {
        changeSignupState(SIGNUP_PROFILE_DISPATCH.ERROR, {
          termsAccepted: true,
          privacyPolicyAccepted: true,
          mandatoryCheckbox: true
        })
      }
    } else if (termsPolicy && privacyPolicy) {
      acceptTerms()
    } else {
      changeSignupState(SIGNUP_PROFILE_DISPATCH.ERROR, {
        termsAccepted: true,
        privacyPolicyAccepted: true
      })
    }
  }

  const features = brandConfig.features
  const termsAccepted = extractCognitoUserTermsAccepted(cognitoUser)
  const renderPopup = () => {
    if (!termsAccepted) {
      return (
        <TermsNotAcceptedWrapper
          signupProfile={signupProfile}
          dispatchActionSignup={changeSignupInputAndErrorState}
          onAcceptTermsSubmit={handleAcceptTermsSubmit}
          onCancelTerms={signOut}
          changeSignupState={changeSignupState}
          acceptTerms={acceptTerms}
        />
      )
    }
  }

  return (
    <>
      <AuthorizedLayoutWrapper
        {...restProps}
        signOut={signOut}
        initSignOut={initSignOut}
        pointsLink={
          !features.disablePoints && (
            <PointsLink enablePurchasePoints={enablePurchasePoints} />
          )
        }
        exchangePointsLink={
          isExchangeEnabled &&
          !features.disableExchangeLink && <ExchangePointsLink />
        }
        nftLink={isNftEnabled && <NftLink />}
        cashbackLink={brandAppConfig.cashbackEarnOption && <CashbackLink />}
        impactReport={features.enableImpactReport && <ImpactReportLink />}
        engravingMessagesLink={
          features.showEngravingMessages && <EngravingMessagesLink />
        }
        multiLanguage={buildMultiLanguageLink(
          selectedLanguage,
          openLanguagePopup,
          renderLanguagePopup
        )}
      >
        {children}
      </AuthorizedLayoutWrapper>
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({ authReducer }) => {
  const { authProfile, signupProfile } = authReducer
  return {
    authProfile,
    signupProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      changeSignupInputAndErrorState,
      changeSignupState,
      acceptTerms
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedLayoutContainer)
