import React, { memo } from 'react'
import {
  BWPanel,
  BWText,
  BWPanelContent,
  BW_LABEL_TEXT,
  openExternalUrl
} from 'src/libs/qb-brand-web-components'

import CopyrightTextWrapper from 'wrappers/CopyrightTextWrapper'
import CustomerHelpDeskWrapper from 'wrappers/CustomerHelpDeskWrapper'
import TermsPoliciesWrapper from 'wrappers/TermsPoliciesWrapper'
import { UNSUBSCRIBE_URL } from '../../constants/externalUrls'
import Button from '../shared/button/Button'
import customConfig from 'customConfig'
import brandConfig from 'brandConfig'

const MoreInformation = ({ t, color, customCss, extraLink }) => {
  const { disableTermsCheckbox } = brandConfig.features
  const handleUnsubscribe = () => {
    openExternalUrl(UNSUBSCRIBE_URL)
  }
  return (
    <BWPanel color={color} css={customCss}>
      <BWPanelContent>
        <BWText
          label={t('user-account.more-information')}
          size={18}
          weight={700}
          alignCenter
        />
        <CustomerHelpDeskWrapper t={t} />
      </BWPanelContent>
      {!disableTermsCheckbox && (
        <BWText
          alignCenter
          label={
            <TermsPoliciesWrapper
              t={t}
              i18nKey={BW_LABEL_TEXT.VIEW_TERMS_POLICY}
            />
          }
        />
      )}
      {extraLink}
      <BWPanelContent>
        <BWText alignCenter label={t(customConfig.unsubscribeMessageLabel)} />
        <Button
          label={t(customConfig.deleteAccountLabel)}
          onClick={handleUnsubscribe}
        />
      </BWPanelContent>
      <CopyrightTextWrapper />
    </BWPanel>
  )
}

export default memo(MoreInformation)
