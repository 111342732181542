import React, { memo } from 'react'
import { BW_COLOR_ACCENT } from 'src/libs/qb-brand-web-components'
import customConfig from 'customConfig'

import Popup from '../../shared/popup/Popup'

const FailedStatus = ({ onSubmit, title, description, icon, color, t }) => (
  <Popup
    title={title}
    description={description}
    icon={icon}
    color={color || BW_COLOR_ACCENT}
    submitLabel={t(customConfig.tryAgainLabel)}
    onFormSubmit={onSubmit}
  />
)

export default memo(FailedStatus)
