import { css } from '@emotion/react'

import { fontSizes, fontWeight, SIZE_UNIT, spacing } from '../themes/units'
import { INPUT_BOX_HEIGHT } from '../constants/constants'
import { getShadows } from '../util/style.helpers'
import { mq } from '../util/theme.helpers'

export const cssFontSize = (size) => css`
  font-size: ${fontSizes[size] || '16px'};
`

export const cssCustomFontSize = (size) => css`
  font-size: ${size || 16}px;
`

export const cssFontWeight = (weight) => css`
  font-weight: ${fontWeight[weight] || 'normal'};
`

export const cssBoxShadow = (colors, size = 1) => css`
  box-shadow: ${getShadows(colors)[size]};
`

export const cssBorderRadius = (size) => css`
  border-radius: ${spacing[size]};
`

export const cssPadding = (topBottom, leftRight) => css`
  padding: ${topBottom && spacing[topBottom]} ${leftRight && spacing[leftRight]};
`

export const cssMargin = (topBottom, leftRight) => css`
  margin: ${topBottom && spacing[topBottom]} ${leftRight && spacing[leftRight]};
`

export const cssGap = (size) => css`
  gap: ${spacing[size]};
`

export const cssCustomGap = (size) => `
  gap: ${size}px;
`

export const CSS_CURSOR_POINTER = css`
  cursor: pointer;
`

export const CSS_TEXT_ALIGN_CENTER = css`
  text-align: center;
`

export const CSS_TEXT_ALIGN_RIGHT = css`
  text-align: right;
`

export const CSS_OUTLINE_NONE = css`
  outline: none;
`

export const CSS_HEIGHT_40 = css`
  height: 40px;
`

export const CSS_HEIGHT_50 = css`
  height: 50px;
`

export const CSS_INPUT_BOX_HEIGHT = css`
  height: ${INPUT_BOX_HEIGHT}px;
`

export const CSS_GRID = css`
  display: grid;
`

export const CSS_GRID_COLUMN = css`
  grid-auto-flow: column;
`

export const CSS_FLEX = css`
  display: flex;
`

export const CSS_ALIGN_ITEMS_CENTER = css`
  place-items: center;
`

export const CSS_PLACE_ITEMS_START = css`
  place-items: start;
`

export const CSS_PLACE_CONTENT_START = css`
  place-content: start;
`

export const CSS_PLACE_CONTENT_BASELINE = css`
  place-content: baseline;
`

export const CSS_ITEMS_CENTER = css`
  align-items: center;
`

export const CSS_ALIGN_ITEMS_START = css`
  align-items: start;
`

export const CSS_BOX_SIZING_BORDER_BOX = css`
  box-sizing: border-box;
`

export const CSS_CENTER_ITEMS = css`
  justify-content: center;
  align-items: center;
  align-content: center;
`

export const CSS_FLEX_ITEMS_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CSS_SELF_CENTER = css`
  justify-self: center;
`

export const CSS_JUSTIFY_CONTENT_CENTER = css`
  justify-content: center;
`

export const CSS_JUSTIFY_CONTENT_START = css`
  justify-content: flex-start;
`

export const CSS_JUSTIFY_CONTENT_STRETCH = css`
  justify-content: stretch;
`

export const CSS_JUSTIFY_CONTENT_END = css`
  justify-content: flex-end;
`

export const CSS_JUSTIFY_ITEMS_SELF_START = css`
  justify-items: self-start;
`

export const CSS_JUSTIFY_SELF_END = css`
  justify-self: self-end;
`

export const CSS_ALIGN_CONTENT_START = css`
  align-content: start;
`

export const CSS_GRID_AND_GAP_DEFAULT = css`
  display: grid;
  grid-gap: 30px;
`

export const CSS_DEFAULT_CONTENT_PADDING = css`
  padding: 30px;
`

export const CSS_DEFAULT_CONTENT_MARGIN = css`
  margin: 30px;
`

export const CSS_FULL_WIDTH = css`
  width: 100%;
`

export const CSS_FULL_HEIGHT = css`
  height: 100%;
`

export const CSS_HEIGHT_AUTO = css`
  height: auto;
`

export const CSS_POINTER_EVENTS_NONE = css`
  pointer-events: none;
`

export const FULL_WIDTH_HEIGHT = css`
  ${CSS_FULL_WIDTH};
  ${CSS_FULL_HEIGHT};
`

export const CSS_DEFAULT_WIDTH_PADDING = css`
  ${CSS_FULL_WIDTH};
  padding: 30px;
`

export const CSS_FLEX_COLUMN = css`
  flex-direction: column;
`

export const CSS_NO_USER_SELECT = css`
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
`

export const CSS_DEFAULT_FIELD_HEIGHT_PADDING = css`
  min-height: 30px;
  padding: 2px 7px;
`

export const CSS_TEXT_TRANSFORM_NONE = css`
  text-transform: none;
`

export const CSS_TEXT_UPPERCASE = css`
  text-transform: uppercase;
`

export const CSS_TEXT_CAPITALIZE = css`
  text-transform: capitalize;
`

export const CSS_TEXT_LOWERCASE = css`
  text-transform: lowercase;
`

export const CSS_POSITION_RELATIVE = css`
  position: relative;
`

export const CSS_POSITION_ABSOLUTE = css`
  position: absolute;
`

export const CSS_WORD_BREAK_ALL = css`
  word-break: break-all;
`

export const CSS_CENTERED_COLUMN_GRID = css`
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
`

export const CSS_MAIN_GRID_CONTAINER = css`
  ${CSS_GRID};
  ${CSS_FULL_HEIGHT};
  ${mq(SIZE_UNIT.MD)} {
    grid-area: main;
  }
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
`

export const CSS_GRID_COLUMN_FULL = css`
  grid-column: 1/3;
`

export const CSS_PADDING_TOP_10 = css`
  padding-top: 10px;
`

export const CSS_GRID_TWO_COLUMNS = css`
  grid-template-columns: 1fr 1fr;
`

export const CSS_GAP_0 = css`
  gap: 0px;
`

export const CSS_GAP_30 = css`
  gap: 30px;
`

export const CSS_FONT_STYLE_ITALIC = css`
  font-style: italic;
`

export const CSS_BORDER_RADIUS_HALF = css`
  border-radius: 50%;
`

export const CSS_FIXED_WORD_SPACING = css`
  word-spacing: 100px;
`

export const CSS_CUSTOM_PANEL_PADDING_30 = css`
  padding: 30px 15px;
`

export const CSS_CUSTOM_PANEL_PADDING_40 = css`
  padding: 40px 15px;
`

export const CSS_NAV_PADDING_SMALL = css`
  padding: 30px 25px;
`

export const CSS_NAV_PADDING_DEFAULT = css`
  padding: 53px 25px;
  ${mq(SIZE_UNIT.SM)} {
    ${CSS_NAV_PADDING_SMALL};
  }
`

export const CSS_DISABLED = css`
  cursor: default;
  pointer-events: none;
`

export const disabledStyles = (colors) => css`
  ${CSS_DISABLED};
  color: ${colors.gray} !important;
`
