export const BW_API_RESPONSE = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  CONFLICT: 409
}

export const BW_COGNITO_ERRORS = {
  USERNAME_EXISTS_EXCEPTION: 'UsernameExistsException',
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
  EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
  CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
  ALIAS_EXISTS_EXCEPTION: 'AliasExistsException',
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  LIMIT_EXCEEDED_EXCEPTION: 'LimitExceededException',
  RESOURCE_NOT_FOUND_EXCEPTION: 'ResourceNotFoundException'
}
