import React, { useState, useEffect } from 'react'

import { BW_AUTH_LANGUAGE_ATTR } from '../constants/auth'
import BWChooseLanguage from '../components/BWChooseLanguage'

const useLanguageSelect = (
  i18n,
  languages,
  updateLanguage,
  savedLanguage = null,
  invert = false
) => {
  const [chooseLanguage, setChooseLanguage] = useState(false)
  const [language, setLanguage] = useState(savedLanguage || i18n.language)

  useEffect(() => {
    setLanguage(savedLanguage)
  }, [savedLanguage])

  const handleLanguageClick = () => {
    setChooseLanguage(true)
  }

  const handleCancelPopup = () => {
    setChooseLanguage(false)
  }

  const handleLanguageSelect = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage)
    setLanguage(selectedLanguage)
    if (savedLanguage) {
      updateLanguage({ [BW_AUTH_LANGUAGE_ATTR]: selectedLanguage })
    }
    handleCancelPopup()
  }

  const renderPopup = (t, language) => {
    if (chooseLanguage) {
      return (
        <BWChooseLanguage
          languages={languages}
          language={language}
          onCancel={handleCancelPopup}
          onSelect={handleLanguageSelect}
          t={t}
          invert={invert}
        />
      )
    }
  }

  return [language, handleLanguageClick, renderPopup]
}

export { useLanguageSelect }
