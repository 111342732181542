import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openExternalUrl } from 'src/libs/qb-brand-web-components'
import { useNavigate, useNavigationType } from 'react-router-dom'
import Loading from '../components/shared/loading/Loading'
import { POP } from '../constants/app'
import { HOME_URL } from '../constants/navigation'
import { FACEBOOK_APP_SETTINGS_URL } from '../constants/externalUrls'
import { getCurrentUser, closeEmailMissing } from '../store/actions/auth'
import { EmailMissing } from '../components/popups/signUp'
import {
  isAuthenticated,
  isNotAuthenticated,
  isCheckingAuth
} from '../util/account.helpers'
import UnauthorizedLayoutWrapper from 'wrappers/UnauthorizedLayoutWrapper'

const requireUnauth = (ChildComponent) => {
  const ComposedComponent = (props) => {
    const navigate = useNavigate()
    const navigationType = useNavigationType()
    const { authState, getCurrentUser, closeEmailMissing } = props

    useEffect(() => {
      if (isNotAuthenticated(authState) && navigationType === POP) {
        getCurrentUser()
      } else if (isAuthenticated(authState)) {
        navigate(HOME_URL, { replace: true })
      }
    }, [])

    useEffect(() => {
      if (isAuthenticated(authState)) {
        navigate(HOME_URL, { replace: true })
      }
    }, [authState])

    const handleShareEmail = () => {
      handleCloseEmailMissing()
      openExternalUrl(FACEBOOK_APP_SETTINGS_URL)
    }

    const handleCloseEmailMissing = () => {
      closeEmailMissing()
    }

    const renderPopup = () => {
      const { emailMissing } = props
      if (emailMissing) {
        return (
          <EmailMissing
            onSubmit={handleShareEmail}
            onCancel={handleCloseEmailMissing}
          />
        )
      }
    }

    return isCheckingAuth(props.authState) ? (
      <Loading main />
    ) : (
      <UnauthorizedLayoutWrapper>
        <ChildComponent {...props} />
        {renderPopup()}
      </UnauthorizedLayoutWrapper>
    )
  }

  const mapStateToProps = ({ authReducer }) => {
    const { authProfile } = authReducer
    const { authState, emailMissing } = authProfile
    return {
      authState,
      emailMissing
    }
  }

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        getCurrentUser,
        closeEmailMissing
      },
      dispatch
    )

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent)
}

export default requireUnauth
