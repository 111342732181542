import { lazy } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import requireUnauth from '../../hoc/requireUnauth'
import {
  changeRewardsState,
  changeRewardsInputAndErrorState
} from '../../store/actions/rewards'
import brandConfig from 'brandConfig'

const RewardContainer = lazy(() =>
  !brandConfig.features.disableQRCode
    ? import('./RewardContainerQrCode')
    : import('./RewardContainer')
)

const mapStateToProps = ({ rewardsReducer }) => {
  const { rewardsProfile } = rewardsReducer
  return {
    rewardsProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeRewardsState,
      changeRewardsInputAndErrorState
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(RewardContainer)
)
