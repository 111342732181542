import React, { memo } from 'react'
import { BWText } from 'src/libs/qb-brand-web-components'

import FacebookContainer from '../../../containers/socialMediaButtons/FacebookContainer'
import GoogleContainer from '../../../containers/socialMediaButtons/GoogleContainer'

const SocialMediaWrapper = (props) => {
  const { label, t, onSocialMediaLogin } = props
  return (
    <>
      <FacebookContainer
        label={label}
        onSocialMediaLogin={onSocialMediaLogin}
      />
      <GoogleContainer label={label} onSocialMediaLogin={onSocialMediaLogin} />
      <BWText label={t('common.or')} />
    </>
  )
}

export default memo(SocialMediaWrapper)
