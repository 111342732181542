import {
  ENGRAVING_ACTION,
  NFT_ACTION,
  SELL_NFT_ACTION,
  TRANSFER_NFT_ACTION
} from '../../constants/actions'

const getEngravings = () => ({
  type: ENGRAVING_ACTION.ON_GET_ENGRAVINGS
})

const changeTransferNftState = (property, value) => ({
  type: TRANSFER_NFT_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeSellNftState = (property, value) => ({
  type: SELL_NFT_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeTransferNftInputAndErrorState = (property, value) => ({
  type: TRANSFER_NFT_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const changeSellNftInputAndErrorState = (property, value) => ({
  type: SELL_NFT_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const resetTransferNftState = () => ({
  type: TRANSFER_NFT_ACTION.RESET_STATE
})

const resetSellNftState = () => ({
  type: SELL_NFT_ACTION.RESET_STATE
})

const resetNftsState = () => ({
  type: NFT_ACTION.RESET_STATE
})

const listNftForSale = (nftId, tokenId, usdPrice) => ({
  type: SELL_NFT_ACTION.LIST_NFT_FOR_SALE,
  payload: { nftId, tokenId, usdPrice }
})

const getNftsListedForSale = () => ({
  type: SELL_NFT_ACTION.GET_LISTED_NFTS
})

const getNftClaims = (nftId, nftTokenId) => ({
  type: NFT_ACTION.GET_NFT_CLAIMS,
  payload: { nftId, nftTokenId }
})

const getListedNftById = (nftId, nftTokenId) => ({
  type: SELL_NFT_ACTION.GET_LISTED_NFT_BY_ID,
  payload: { nftId, nftTokenId }
})

const cancelNftListing = (orderId) => ({
  type: SELL_NFT_ACTION.CANCEL_NFT_LISTING,
  payload: { orderId }
})

const changeNftActionState = (action) => ({
  type: NFT_ACTION.CHANGE_NFT_ACTION_STATE,
  payload: action
})

const buyNft = (redeemOption) => ({
  type: NFT_ACTION.BUY_NFT,
  payload: { redeemOption }
})

const transferNft = (tokenNftId, accountNumber, nftId) => ({
  type: TRANSFER_NFT_ACTION.TRANSFER_NFT,
  payload: { tokenNftId, accountNumber, nftId }
})

const getNftExclusiveContent = (nftUrl) => ({
  type: NFT_ACTION.ON_GET_EXCLUSIVE_CONTENT,
  payload: { nftUrl }
})

const refreshMembership = (token) => ({
  type: NFT_ACTION.ON_REFRESH_MEMBERSHIP,
  payload: { token }
})

const resetRefreshMembership = () => ({
  type: NFT_ACTION.RESET_REFRESH_MEMBERSHIP_STATE
})

export {
  getEngravings,
  changeTransferNftState,
  resetTransferNftState,
  changeTransferNftInputAndErrorState,
  resetSellNftState,
  changeSellNftInputAndErrorState,
  changeSellNftState,
  listNftForSale,
  getNftsListedForSale,
  cancelNftListing,
  changeNftActionState,
  buyNft,
  getListedNftById,
  transferNft,
  resetNftsState,
  getNftClaims,
  getNftExclusiveContent,
  refreshMembership,
  resetRefreshMembership
}
