import { parseToken } from './apiParser.helpers'
import { isTokenTypeNft, isTokenTypePoints } from './pointsExchange.helpers'

export const formatBrandTokens = (tokens) => {
  const tokensData = {
    points: [],
    nfts: []
  }
  if (tokens && tokens.length > 0) {
    tokens.forEach((token) => {
      let formattedToken = parseToken(token)
      const tokenType = formattedToken.token.type
      if (isTokenTypePoints(tokenType)) {
        tokensData.points.push(formattedToken)
      }
      if (isTokenTypeNft(tokenType)) {
        tokensData.nfts.push(formattedToken)
      }
    })
    if (tokensData.nfts.length) {
      tokensData.nfts = tokensData.nfts.sort((a, b) =>
        a.token.name.localeCompare(b.token.name)
      )
    }
  }
  return tokensData
}

export const formatTokens = (tokens) => {
  if (tokens && tokens.length > 0) {
    return tokens.map((token) => parseToken(token))
  }
  return []
}
