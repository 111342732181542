import React, { memo } from 'react'
import CountUpAnimation from 'react-countup'
import { BWText } from 'src/libs/qb-brand-web-components'

import { getNonNegativeNumber } from '../../../util/brand.helpers'
import { formatTicks } from '../../../util/string.helpers'

const BalanceWithAnimation = ({
  previousUserPoints,
  currentUserPoints,
  fontSize = 50
}) => {
  return (
    <BWText
      label={
        <CountUpAnimation
          start={previousUserPoints}
          end={getNonNegativeNumber(currentUserPoints)}
          duration={2}
          formattingFn={formatTicks}
        />
      }
      size={fontSize}
      weight={800}
    />
  )
}

export default memo(BalanceWithAnimation)
