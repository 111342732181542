export const TRANSACTION_TYPE = {
  REWARD: 'reward',
  REDEEM: 'redeem'
}

export const TRANSACTION_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
  CANCELLED: 'cancelled'
}

export const TRANSACTION_STATUS_LABEL = {
  FUTURE: 'future'
}

export const BW_REWARD_TYPE = {
  CODE_TO_POINTS: 'code_to_points',
  POINTS_TO_REWARDS: 'points_to_rewards',
  CODE_TO_EXTERNAL_REWARDS: 'code_to_external_rewards',
  CODE_TO_NFT: 'code_to_nft',
  NFT_PERSONALISATION: 'nft_personalisation',
  CREDIT_POINTS: 'credit_points',
  DEBIT_POINTS: 'debit_points',
  SIGNUP_BONUS: 'signup_bonus',
  ON_DEMAND_CODE_CREATION: 'on_demand_code_creation',
  EXCHANGE: 'exchange',
  REVERT_EXCHANGE: 'revert_exchange'
}
