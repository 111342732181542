import { toLowerCamelCase } from './string.helpers'

function mergeToNewObject(obj1, ...rest) {
  const newObject = rest.reduce((curr, next) => {
    return Object.assign({}, curr, next)
  }, {})

  return Object.assign({}, obj1, newObject)
}

const deepEqualObjects = (x, y) => {
  const keys = Object.keys
  const tx = typeof x
  const ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? keys(x).length === keys(y).length &&
        keys(x).every((key) => deepEqualObjects(x[key], y[key]))
    : x === y
}

const removeEmpty = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] !== '') {
      newObj[prop] = obj[prop]
    }
  })
  return newObj
}

const deepMerge = (target, source) => {
  const result = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(result, { [key]: source[key] })
        } else {
          result[key] = deepMerge(target[key], source[key])
        }
      } else {
        Object.assign(result, { [key]: source[key] })
      }
    })
  }
  return result
}

const isObject = (obj) => {
  return obj === Object(obj) && !isArray(obj) && typeof obj !== 'function'
}

const isArray = (arr) => {
  return Array.isArray(arr)
}

const keysToCamel = (obj) => {
  if (isObject(obj)) {
    const n = {}
    Object.keys(obj).forEach((k) => {
      n[toLowerCamelCase(k)] = keysToCamel(obj[k])
    })
    return n
  } else if (isArray(obj)) {
    return obj.map((i) => {
      return keysToCamel(i)
    })
  }
  return obj
}

const containsNoValues = (values) => {
  return !Object.values(values).some((value) => value)
}

const containsValues = (value) => {
  return Object.keys(value).length > 0
}

const extractInputValueAndError = ({ property, value }, prevError) => {
  return { [property]: value, error: { ...prevError, [property]: '' } }
}

export {
  mergeToNewObject,
  deepEqualObjects,
  removeEmpty,
  deepMerge,
  isObject,
  isArray,
  keysToCamel,
  containsNoValues,
  containsValues,
  extractInputValueAndError
}
