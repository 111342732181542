import React from 'react'
import { faQrcode } from '@fortawesome/pro-light-svg-icons'
import {
  BW_LABEL_TEXT,
  BW_COLOR_ACCENT
} from 'src/libs/qb-brand-web-components'
import { QRCodeSVG } from 'qrcode.react'

import Popup from '../../shared/popup/Popup'
import customConfig from 'customConfig'

const AccountNumberQRCode = ({ onCancel, authId, t }) => (
  <Popup
    title={t(customConfig.accountNumberQrCodeLabel)}
    description={t(customConfig.scanQrCodeDescLabel)}
    icon={faQrcode}
    color={BW_COLOR_ACCENT}
    cancelLabel={t(BW_LABEL_TEXT.CLOSE)}
    onCancel={onCancel}
  >
    <QRCodeSVG value={authId} size={215} />
  </Popup>
)

export { AccountNumberQRCode }
