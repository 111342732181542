import { mergeToNewObject } from './object.helpers'

import { BW_ERROR_TEXT } from '../constants/messages'

const requiredRule = (value) => {
  return !isValidRequired(value) ? BW_ERROR_TEXT.FIELD_REQUIRED : ''
}

const isValidRequired = (value) => {
  return value && value.length
}

const emailRule = (email) => {
  return !isValidEmail(email) ? BW_ERROR_TEXT.INVALID_EMAIL_ADDRESS : ''
}

const passwordRule = (password) => {
  return !isValidPassword(password) ? BW_ERROR_TEXT.INVALID_PASSWORD : ''
}

const isValidEmail = (email) => {
  const valid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return valid.test(email)
}

const isValidPassword = (password) => {
  const valid =
    /^[><?@+'`~^%&\\*\\[\]\\{\\}.!#|\\\\"$';,:;=/\\(\\),\-\w+]{8,99}$/
  return valid.test(password)
}

const isValidPositiveNumber = (value) => {
  const valid = /^[1-9]\d*$/
  return valid.test(value)
}

const checkForRequiredFields = (fields) => {
  const newErrorMessages = Object.entries(fields).reduce((curr, next) => {
    if (next.length > 1 && !next[1].length) {
      return mergeToNewObject(curr, { [next[0]]: BW_ERROR_TEXT.FIELD_REQUIRED })
    }
    return curr
  }, {})

  return newErrorMessages
}

const checkValidations = (fields) => {
  const newErrorMessages = Object.entries(fields).reduce((curr, next) => {
    const { value, rules } = next[1]
    const invalid = checkRulesAgainstValue(rules, value)?.[0]
    if (invalid) {
      return mergeToNewObject(curr, { [next[0]]: invalid })
    }
    return curr
  }, {})

  return newErrorMessages
}

const positiveNumberRule = (number) => {
  return !isValidPositiveNumber(number)
    ? BW_ERROR_TEXT.INVALID_NUMBER_FORMAT
    : ''
}

const checkRulesAgainstValue = (rules, value) => {
  if (rules && rules.length > 0) {
    return rules.map((rule) => rule(value)).filter((msg) => msg !== '')
  }
  return []
}

const isValidRewardCode = (rewardCode, min = 9, max = 9) => {
  if (!rewardCode) {
    return false
  }
  const cleanCode = rewardCode.replace(/[^a-zA-Z0-9]/g, '')
  const valid = new RegExp(`^[a-zA-Z0-9]{${min},${max}}$`)
  return valid.test(cleanCode) ? cleanCode : false
}

export {
  requiredRule,
  emailRule,
  passwordRule,
  checkForRequiredFields,
  checkValidations,
  positiveNumberRule,
  checkRulesAgainstValue,
  isValidRewardCode,
  isValidRequired,
  isValidEmail,
  isValidPassword,
  isValidPositiveNumber
}
