import { combineReducers } from 'redux'

import { authReducer } from './auth'
import { mainReducer } from './main'
import { accountReducer } from './account'
import { transactionsReducer } from './transactions'
import { rewardsReducer } from './rewards'
import { surveyReducer } from './survey'
import { exchangeReducer } from './exchange'
import { redeemReducer } from './redeem'
import { nftReducer } from './nft'
import { cashbackReducer } from './cashback'
import { engravingsReducer } from './engravings'
import { stakingReducer } from './staking'
import { earnReducer } from './earn'

const rootReducer = combineReducers({
  authReducer,
  mainReducer,
  accountReducer,
  transactionsReducer,
  rewardsReducer,
  surveyReducer,
  exchangeReducer,
  redeemReducer,
  nftReducer,
  cashbackReducer,
  engravingsReducer,
  stakingReducer,
  earnReducer
})

const reducers = (state, action) => {
  return rootReducer(state, action)
}

export { reducers }
