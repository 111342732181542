import { BW_LANGUAGE_EN, requiredRule } from 'src/libs/qb-brand-web-components'

import { commonParser } from './apiParser.helpers'
import { isRedemptionFulfillmentEnabled } from './feature.helpers'

const formatSurveysToObject = (surveysData) => {
  if (surveysData && surveysData.length) {
    return surveysData.reduce((obj, data) => {
      const survey = commonParser(data)
      return survey && survey.id
        ? {
            ...obj,
            [survey.id]: survey
          }
        : {}
    }, {})
  }
  return {}
}

const extractSurveyQuestions = (survey, language) => {
  return survey?.questions[language] || survey?.questions[BW_LANGUAGE_EN]
}

const buildSurveyRequestDataFormat = (surveyId, response) => {
  return {
    user_survey: {
      response,
      survey_id: surveyId
    }
  }
}

const buildCheckValidationRules = (surveyFields, formData) => {
  const validations = {}
  if (surveyFields && surveyFields.length > 0) {
    surveyFields.forEach(({ id, required }) => {
      const value = formData?.[id] || ''
      validations[id] = {
        value,
        rules: [required && requiredRule]
      }
    })
  }
  return validations
}

const extractSurveyReducer = (state) => state.value.surveyReducer

const extractRedemptionSurveyState = (state) => {
  return extractSurveyReducer(state).redemptionProfile
}

const extractSelectedSurveyIdFromState = (state) => {
  return extractSurveyReducer(state).surveyProfile.selectedSurveyId
}

const checkIsDirectRedemption = (state) => {
  const { formData } = extractRedemptionSurveyState(state)
  return !isRedemptionFulfillmentEnabled() || Object.keys(formData).length <= 0
}

export {
  formatSurveysToObject,
  extractSurveyQuestions,
  buildCheckValidationRules,
  buildSurveyRequestDataFormat,
  extractRedemptionSurveyState,
  extractSelectedSurveyIdFromState,
  checkIsDirectRedemption
}
