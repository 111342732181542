import {
  extractInputValueAndError,
  mergeToNewObject
} from 'src/libs/qb-brand-web-components'

import { SURVEY_ACTION } from '../../../constants/actions'

export const surveyProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SURVEY_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case SURVEY_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case SURVEY_ACTION.ON_SUBMIT_FAVORITE_EXCHANGE_SUCCESS:
      return mergeToNewObject(state, {
        favoriteOption: '',
        surveySubmissions: [...state.surveySubmissions, payload]
      })

    case SURVEY_ACTION.ON_GET_SURVEYS_SUCCESS:
      return mergeToNewObject(state, { surveysData: payload })

    case SURVEY_ACTION.ON_GET_USER_SURVEY_SUBMISSIONS_SUCCESS:
      return mergeToNewObject(state, {
        surveySubmissions: payload
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  favoriteOption: '',
  selectedSurveyId: '',
  surveysData: {},
  surveySubmissions: [],
  error: {}
}

export const SURVEYS_PROFILE_DISPATCH = {
  FAVORITE_OPTION: 'favoriteOption',
  SELECTED_SURVEY_ID: 'selectedSurveyId',
  ERROR: 'error'
}
