import { getAppVersion } from '../../../util/app/app.helpers'
import { isProduction } from './app.helpers'

export function logger(value) {
  if (isProduction()) return
  console.log(value)
}

export function logAppVersion() {
  logger(`App Version is ${getAppVersion()}`)
}
