export const NUMBER_OF_TX_PER_PAGE = 10
export const DISABLE_CAMPAIGN_CHECK_NUMBER_OF_TX = 100

export const LOYALTY_EVENT_TYPES = {
  CODE_TO_POINTS: 'code_to_points',
  CODE_TO_NFT: 'code_to_nft',
  CODE_TO_EXTERNAL_REWARDS: 'code_to_external_rewards',
  CREDIT_POINTS: 'credit_points',
  DEBIT_POINTS: 'debit_points',
  POINTS_TO_REWARDS: 'points_to_rewards',
  ON_DEMAND_CODE_CREATION: 'on_demand_code_creation',
  NFT_PERSONALISATION: 'nft_personalisation',
  SIGNUP_BONUS: 'signup_bonus',
  EXCHANGE: 'exchange',
  REVERT_EXCHANGE: 'revert_exchange',
  POINTS_TO_NFT_EXCHANGE: 'points_to_nft_exchange',
  CREDIT_NFT: 'credit_nft',
  NFT_TRANSFER: 'nft_transfer',
  NFT_SALE: 'nft_sale'
}

export const TRANSACTION_STATUS_LABEL = {
  FUTURE: 'future'
}
