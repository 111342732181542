import React from 'react'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { BW_COLOR_SUCCESS } from 'src/libs/qb-brand-web-components'

import Popup from '../shared/popup/Popup'
import customConfig from 'customConfig'

const ResetSuccess = ({ onSubmit, t }) => (
  <Popup
    title={t(customConfig.youDidItLabel)}
    description={t(customConfig.passwordResetSuccessLabel)}
    icon={faCheckCircle}
    color={BW_COLOR_SUCCESS}
    submitLabel={t(customConfig.goToLoginLabel)}
    onFormSubmit={onSubmit}
  />
)

export { ResetSuccess }
