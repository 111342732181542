import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { MAIN_PROFILE_ACTION } from '../../../constants/actions'

export const tokenProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      return mergeToNewObject(state, extractTokenInfo(payload))

    default:
      return state
  }
}

const INITIAL_STATE = {
  id: null,
  name: '',
  symbol: '',
  offramp: 0,
  onramp: 0,
  enablePurchasePoints: false,
  enablePurchasePointsWithCreditCard: false
}

const extractTokenInfo = (payload) => {
  const tokens = payload?.userBrandTokens?.points
  if (tokens && tokens.length > 0) {
    const incomingPartnerships = payload.exchangeWhitelist.incoming
    const {
      id,
      name,
      symbol,
      offramp,
      onramp,
      enablePurchasePoints: enablePurchasePointsWithCreditCard
    } = tokens[0].token
    return {
      id,
      name,
      symbol,
      offramp: parseFloat(offramp),
      onramp: parseFloat(onramp),
      enablePurchasePointsWithCreditCard,
      enablePurchasePoints:
        enablePurchasePointsWithCreditCard ||
        incomingPartnerships.length ||
        false
    }
  }
  return {}
}
