import React, { memo } from 'react'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import { BW_COLOR_ERROR } from 'src/libs/qb-brand-web-components'

import FailedStatus from '../common/FailedStatus'

const AccountCreationFailed = ({ label, onSubmit }) => {
  const { t } = useTranslation()
  return (
    <FailedStatus
      title={t('common.something-went-wrong')}
      description={t(label)}
      icon={faTimesCircle}
      color={BW_COLOR_ERROR}
      onSubmit={onSubmit}
      t={t}
    />
  )
}

export default memo(AccountCreationFailed)
