import React, { memo } from 'react'
import { BWOutsideLink, BWText } from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import customConfig from 'customConfig'

const CustomerHelpDesk = ({ t, customFaq }) => {
  const { faqEmail, faqUrl } = brandConfig
  const label = faqEmail ? faqEmail : 'FAQ'
  const to = faqEmail ? faqEmail : customFaq || faqUrl
  return (
    <BWText
      alignCenter
      label={
        <>
          <BWText
            label={`${t('user-account.if-you-have-any-questions')} ${t(
              !faqEmail
                ? 'user-account.please-visit-our'
                : customConfig.pleaseContactUsLabel
            )}`}
          />
          <BWOutsideLink isEmail={faqEmail} label={label} to={to} />
        </>
      }
    />
  )
}

export default memo(CustomerHelpDesk)
