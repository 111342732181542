import {
  extractInputValueAndError,
  mergeToNewObject
} from 'src/libs/qb-brand-web-components'

import { SELL_NFT_ACTION } from '../../../constants/actions'

export const sellNftProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SELL_NFT_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case SELL_NFT_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case SELL_NFT_ACTION.LIST_NFT_FOR_SALE_SUCCESS:
      return mergeToNewObject(state, {
        stopSaleDisabled: true
      })

    case SELL_NFT_ACTION.LIST_NFT_FOR_SALE_FAILED:
      return mergeToNewObject(state, {
        numberOfPoints: '',
        stopSaleDisabled: false
      })

    case SELL_NFT_ACTION.RESET_STATE:
      return mergeToNewObject(state, {
        numberOfPoints: '',
        error: {}
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  numberOfPoints: '',
  stopSaleDisabled: false,
  error: {}
}

export const SELL_NFTS_PROFILE_DISPATCH = {
  NUMBER_OF_POINTS: 'numberOfPoints',
  STOP_SALE_DISABLED: 'stopSaleDisabled',
  ERROR: 'error'
}
