import { mergeMap, catchError, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import {
  extractAccountProfile,
  formatTransactions
} from '../../util/epics.helpers'

import {
  MAIN_PROFILE_ACTION,
  TRANSACTIONS_HISTORY
} from '../../constants/actions'

import { wrapUserAccessToken } from '../../util/auth.helpers'
import { NUMBER_OF_TX_PER_PAGE } from '../../constants/transactions'

const handleGetTransactions = (action$, state$) =>
  action$.pipe(
    ofType(
      TRANSACTIONS_HISTORY.ON_GET_TRANSACTIONS,
      MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS_SUCCESS
    ),
    mergeMap(({ payload: { offset } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTransactions(
            {
              limit: NUMBER_OF_TX_PER_PAGE,
              offset: offset || 0
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: TRANSACTIONS_HISTORY.ON_GET_TRANSACTIONS_SUCCESS,
              payload: formatTransactions(
                response.data,
                extractAccountProfile(state$)
              )
            })),
            catchError((err) =>
              of({
                type: TRANSACTIONS_HISTORY.ON_GET_TRANSACTIONS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

export const transactionsEpic = combineEpics(handleGetTransactions)
