import React, { lazy } from 'react'

import { isMultiLanguage } from '../../util/translation.helpers'

const MultiLanguageLink = lazy(
  () => import('../../components/navHeader/headerLinks/MultiLanguageLink')
)

const buildMultiLanguageLink = (
  selectedLanguage,
  openLanguagePopup,
  renderLanguagePopup,
  isNotMenu = false
) => {
  return (
    isMultiLanguage() && {
      languageLink: (
        <MultiLanguageLink
          selectedLanguage={selectedLanguage}
          onClick={openLanguagePopup}
          isNotMenu={isNotMenu}
        />
      ),
      renderLanguagePopup,
      selectedLanguage
    }
  )
}

export { buildMultiLanguageLink }
