import React, { memo } from 'react'
import { BWPageNotFound } from 'src/libs/qb-brand-web-components'
import { useTranslation } from 'react-i18next'

import brandConfig from 'brandConfig'
import customConfig from 'customConfig'

const PageNotFound = ({ auth, onBackToHome, onCancelLink }) => {
  const { prefColor, prefIconColor, prefTextColor } = brandConfig
  const { t } = useTranslation()
  return (
    <BWPageNotFound
      title={t('page-not-found.not-found')}
      description={t(customConfig.couldNotFindPageLabel)}
      submitLabel={t('page-not-found.back-to-home')}
      cancelLabel={auth ? t(customConfig.myAccountLabel) : t('login.log-in')}
      onBackToHome={onBackToHome}
      onCancelLink={onCancelLink}
      iconColor={prefIconColor}
      btnColor={prefColor}
      btnTextColor={prefTextColor}
    />
  )
}

export default memo(PageNotFound)
