import ReactGA from 'react-ga4'

import { APP_CONFIG } from '../constants/appConfig'
import {
  ACCOUNT_URL,
  EXCHANGE_POINTS_URL,
  HOME_URL,
  LOG_IN_URL,
  MANAGE_ACCOUNTS_URL,
  NFTS_URL,
  REDEEM_POINTS_URL,
  REWARD_URL,
  TRANSACTIONS_URL,
  WELCOME_URL
} from '../constants/navigation'

const eventTrack = (category, action, label) => {
  if (APP_CONFIG.GA_TRACKING_ID) {
    ReactGA.event({
      category,
      action,
      label
    })
  }
}

const getPageNameByUrl = (url) => {
  switch (url) {
    case HOME_URL:
      return 'overview'
    case REWARD_URL:
      return 'reward'
    case REDEEM_POINTS_URL:
      return 'redeem'
    case EXCHANGE_POINTS_URL:
      return 'exchange'
    case TRANSACTIONS_URL:
      return 'transactions'
    case NFTS_URL:
      return 'nfts'
    case ACCOUNT_URL:
      return 'account'
    case MANAGE_ACCOUNTS_URL:
      return 'manage'
    case WELCOME_URL:
      return 'welcome'
    case LOG_IN_URL:
      return 'login'
    default:
      return ''
  }
}

const getPageTypeByUrl = (url) => {
  switch (url) {
    case HOME_URL:
      return 'home'
    case REWARD_URL:
    case REDEEM_POINTS_URL:
    case EXCHANGE_POINTS_URL:
    case NFTS_URL:
      return 'product'
    case TRANSACTIONS_URL:
      return 'confirmation'
    case WELCOME_URL:
    case ACCOUNT_URL:
      return 'content'
    case LOG_IN_URL:
    case MANAGE_ACCOUNTS_URL:
      return 'forms'
    default:
      return ''
  }
}

export { eventTrack, getPageNameByUrl, getPageTypeByUrl }
