export const EVENT_TRACK_LABEL_BUTTON = 'Button'

export const EVENT_TRACK_REWARD_CODE = {
  CATEGORY: 'Home Screen',
  ACTION: 'Enter Reward Code Button'
}

export const EVENT_TRACK_RECEIVE_REWARD = {
  CATEGORY: 'Reward Popup Screen',
  ACTION: 'Receive Reward Button'
}

export const EVENT_TRACK_LOGIN = {
  CATEGORY: 'Login Screen',
  ACTION: 'Login Button'
}

export const EVENT_TRACK_LOGIN_SOCIAL = {
  CATEGORY: 'Login Screen',
  ACTION: 'Social Login Button'
}

export const EVENT_TRACK_RECEIVE_POINTS = {
  CATEGORY: 'Receive Reward Popup Screen',
  ACTION: 'Receive Points Button'
}

export const EVENT_TRACK_RECEIVE_MILES = {
  CATEGORY: 'Receive Reward Popup Screen',
  ACTION: 'Receive Miles Button'
}

export const EVENT_TRACK_RECEIVE_REWARD_NOT_LOGGED = {
  CATEGORY: 'Not Logged In Reward Screen',
  ACTION: 'Receive Reward Button'
}
