import React, { memo } from 'react'
import { BWText, BWLink } from 'src/libs/qb-brand-web-components'
import { Link } from 'react-router-dom'

import { LOG_IN_URL } from '../../../constants/navigation'

const LoginLinkText = ({ t, hideLogInAction = false, ...restProps }) => {
  return (
    <BWText
      data-testid='login-link-text'
      alignCenter
      label={
        <>
          <BWText label={t('common.already-have-an-account')} />
          {!hideLogInAction && (
            <BWLink label={t('login.log-in')} to={LOG_IN_URL} as={Link} />
          )}
        </>
      }
      {...restProps}
    />
  )
}

export default memo(LoginLinkText)
