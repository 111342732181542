export const INPUT_PASSWORD = 'password'
export const INPUT_TEXT = 'text'
export const INPUT_EMAIL = 'email'
export const INPUT_NUMBER = 'number'
export const INPUT_SEARCH = 'search'

export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info'
}

export const INPUT_BOX_HEIGHT = 50
