import {
  AUTH_ACTION,
  AUTH_FORGOT_PASSWORD_ACTION,
  AUTH_LOGIN_ACTION,
  AUTH_RESET_PASSWORD_ACTION,
  AUTH_SIGNUP_ACTION
} from '../../constants/actions'

// SIGN UP
const changeSignupState = (property, value) => ({
  type: AUTH_SIGNUP_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeSignupInputAndErrorState = (property, value) => ({
  type: AUTH_SIGNUP_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const cleanSignupState = () => ({
  type: AUTH_SIGNUP_ACTION.RESET_STATE
})

const resendSignupEmail = (email) => ({
  type: AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL,
  payload: {
    email
  }
})

const confirmSignup = (email, code) => ({
  type: AUTH_SIGNUP_ACTION.ON_CONFIRM,
  payload: {
    email,
    code
  }
})

// LOGIN
const changeLoginState = (property, value) => ({
  type: AUTH_LOGIN_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeLoginInputAndErrorState = (property, value) => ({
  type: AUTH_LOGIN_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const cleanLoginState = () => ({
  type: AUTH_LOGIN_ACTION.RESET_STATE
})

// FORGOT PASSWORD
const changeForgotPasswordState = (property, value) => ({
  type: AUTH_FORGOT_PASSWORD_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeForgotPasswordInputAndErrorState = (property, value) => ({
  type: AUTH_FORGOT_PASSWORD_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const cleanForgotPasswordState = () => ({
  type: AUTH_FORGOT_PASSWORD_ACTION.RESET_STATE
})

// RESET PASSWORD
const changeResetPasswordState = (property, value) => ({
  type: AUTH_RESET_PASSWORD_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeResetPasswordInputAndErrorState = (property, value) => ({
  type: AUTH_RESET_PASSWORD_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const cleanResetPasswordState = () => ({
  type: AUTH_RESET_PASSWORD_ACTION.RESET_STATE
})

// AUTH
const logIn = (email, password) => ({
  type: AUTH_LOGIN_ACTION.ON_LOG_IN,
  payload: {
    email,
    password
  }
})

const requestPasswordChange = (email) => ({
  type: AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE,
  payload: {
    email
  }
})

const resetPassword = (email, code, newPassword) => ({
  type: AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD,
  payload: {
    email,
    code,
    newPassword
  }
})

const getCurrentUser = () => ({
  type: AUTH_ACTION.ON_GET_CURRENT_USER
})

const signOut = () => ({
  type: AUTH_ACTION.ON_SIGN_OUT
})

const socialMediaLogIn = (provider) => ({
  type: AUTH_LOGIN_ACTION.ON_SOCIAL_MEDIA_LOG_IN,
  payload: { provider }
})

const closeEmailMissing = () => ({
  type: AUTH_ACTION.ON_CLOSE_EMAIL_MISSING
})

// attributes: {
//   [AUTH_LAST_NAME_ATTR]: '',
//   [AUTH_FIRST_NAME_ATTR]: '',
//   [AUTH_REWARD_CODE_ATTR]: ''
// }
const signUp = (email, password, attributes) => ({
  type: AUTH_SIGNUP_ACTION.ON_SIGN_UP,
  payload: {
    email,
    password,
    attributes
  }
})

const updateUserAttributes = (attributes) => ({
  type: AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES,
  payload: {
    attributes
  }
})

const acceptTerms = () => ({
  type: AUTH_ACTION.ON_ACCEPT_TERMS
})

const changePassword = (oldPassword, newPassword) => ({
  type: AUTH_ACTION.ON_CHANGE_PASSWORD,
  payload: {
    oldPassword,
    newPassword
  }
})

export {
  changeSignupState,
  changeSignupInputAndErrorState,
  cleanSignupState,
  resendSignupEmail,
  confirmSignup,
  changeLoginState,
  changeLoginInputAndErrorState,
  cleanLoginState,
  changeForgotPasswordState,
  changeForgotPasswordInputAndErrorState,
  cleanForgotPasswordState,
  changeResetPasswordState,
  changeResetPasswordInputAndErrorState,
  cleanResetPasswordState,
  logIn,
  requestPasswordChange,
  resetPassword,
  getCurrentUser,
  signOut,
  signUp,
  updateUserAttributes,
  changePassword,
  socialMediaLogIn,
  closeEmailMissing,
  acceptTerms
}
