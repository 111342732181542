import React, { memo } from 'react'

import RedeemHeading from '../../../components/redeem/RedeemHeading'
import customConfig from 'customConfig'

const RedeemHeadingWrapper = ({ t }) => {
  return (
    <RedeemHeading
      label={t(customConfig.redeemYourPointsLabel, {
        pointsLabel: t(customConfig.pointsLabel)
      })}
      icon={customConfig.giftCardIcon}
    />
  )
}

export default memo(RedeemHeadingWrapper)
