import { mergeMap, catchError, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import { POINTS_REWARD_ACTION } from '../../constants/actions'

import { extractTokenId, wrapUserAccessToken } from '../../util/auth.helpers'
import recaptchaService from '../../services/recaptcha'
import { checkIsDirectRedemption } from '../../util/survey.helpers'
import { LOYALTY_EVENT_TYPES } from '../../constants/transactions'

const handleReceiveRewardFromBrandPoints = (action$, state$) =>
  action$.pipe(
    ofType(POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS),
    mergeMap(({ payload: { redeemOption } }) =>
      wrapUserAccessToken((accessToken) =>
        recaptchaService.execute$().pipe(
          mergeMap((recaptchaToken) =>
            apiService
              .receiveRewardFromBrandPoints(
                buildParamsForRewardFromPoints(state$, redeemOption),
                accessToken,
                recaptchaToken
              )
              .pipe(
                tap((data) => logger(data)),
                map(() => ({
                  type: POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS_SUCCESS,
                  payload: {
                    isRedeemed: checkIsDirectRedemption(state$),
                    redeemOption
                  }
                })),
                catchError((err) =>
                  of({
                    type: POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS_FAILED,
                    payload: { error: err, redeemOption }
                  })
                )
              )
          )
        )
      )
    )
  )

export const pointsEpic = combineEpics(handleReceiveRewardFromBrandPoints)

const buildParamsForRewardFromPoints = (state, { isNft, id }) => {
  const tokenId = extractTokenId(state)
  const type = isNft
    ? LOYALTY_EVENT_TYPES.POINTS_TO_NFT_EXCHANGE
    : LOYALTY_EVENT_TYPES.POINTS_TO_REWARDS
  return isNft
    ? { toTokenId: id, tokenId, type }
    : { rewardTypeId: id, tokenId, type }
}
