export const BW_COLOR_MAIN = 'main'
export const BW_COLOR_ACCENT = 'accent'
export const BW_COLOR_SUCCESS = 'success'
export const BW_COLOR_ERROR = 'error'
export const BW_COLOR_LITE_BLACK = 'liteBlack'
export const BW_COLOR_LITE_WHITE = 'liteWhite'
export const BW_COLOR_GRAY = 'gray'
export const BW_COLOR_ZAMBEZI = 'zambezi'
export const BW_COLOR_WHITE = 'white'
export const BW_COLOR_BLACK = 'black'
export const BW_COLOR_FACEBOOK = 'facebook'
