import { Amplify, Auth } from 'aws-amplify'
import { from } from 'rxjs'

import { APP_CONFIG } from '../constants/appConfig'

/**
 * This is a hack to fix the issue with the Amplify library treating all incoming urls as OAuth responses.
 * https://github.com/aws-amplify/amplify-js/issues/9208#issuecomment-1309890756*
 */
/* eslint-disable no-underscore-dangle */
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth)

Auth._handleAuthResponse = (url) => {
  const configuration = Auth.configure()
  if (!url.includes(configuration.oauth.redirectSignIn)) return
  return _handleAuthResponse(url)
}

class AuthService {
  constructor() {
    Amplify.configure({
      Auth: APP_CONFIG.COGNITO_CONFIG
    })
  }

  /// Observable<ISignUpResult>
  signup$(email, password, attributes) {
    return from(
      Auth.signUp({
        username: email,
        password,
        attributes
      })
    )
  }

  /// Observable<any>
  confirmSignUp$(email, signupCode) {
    return from(Auth.confirmSignUp(email, signupCode))
  }

  /// Observable<CognitoUser>
  getCurrentUser$() {
    return from(Auth.currentAuthenticatedUser())
  }

  /// Observable<CognitoUserSession>
  getUserSession$() {
    return from(Auth.currentSession())
  }

  /// Observable<CognitoUser>
  logIn$(email, password) {
    return from(
      Auth.signIn({
        username: email,
        password
      })
    )
  }

  /// Observable<ICredentials>
  socialMediaLogIn$(provider) {
    return from(
      Auth.federatedSignIn({
        provider
      })
    )
  }

  /// Observable<any>
  requestPasswordChange$(email) {
    return from(Auth.forgotPassword(email))
  }

  /// Observable<()>
  resetPassword$(email, code, newPassword) {
    return from(Auth.forgotPasswordSubmit(email, code, newPassword))
  }

  /// Observable<string>
  resendSignUpEmail$(email) {
    return from(Auth.resendSignUp(email))
  }

  /// Observable<any>
  signOut$() {
    return from(Auth.signOut())
  }

  /// Observable<string>
  updateUserAttributes$(cognitoUser, attributes) {
    return from(Auth.updateUserAttributes(cognitoUser, attributes))
  }

  /// Observable<"SUCCESS">
  changePassword$(cognitoUser, oldPassword, newPassword) {
    return from(Auth.changePassword(cognitoUser, oldPassword, newPassword))
  }
}

const authService = new AuthService()

export default authService
