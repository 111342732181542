import {
  extractInputValueAndError,
  mergeToNewObject
} from 'src/libs/qb-brand-web-components'

import {
  REDEMPTION_FULFILLMENT_ACTION,
  SURVEY_ACTION
} from '../../../constants/actions'

export const redemptionProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REDEMPTION_FULFILLMENT_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, {
        [payload.property]: payload.value
      })

    case REDEMPTION_FULFILLMENT_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      const { error, ...restAttrs } = extractInputValueAndError(
        payload,
        state.error
      )
      return mergeToNewObject(state, {
        error,
        formData: {
          ...state.formData,
          ...restAttrs
        }
      })

    case SURVEY_ACTION.ON_SUBMIT_REDEMPTION_FULFILLMENT_SUCCESS:
    case REDEMPTION_FULFILLMENT_ACTION.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  formData: {},
  error: {}
}

export const REDEMPTION_PROFILE_DISPATCH = {
  FORM_DATA: 'formData',
  ERROR: 'error'
}
