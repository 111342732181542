import {
  mergeToNewObject,
  extractInputValueAndError,
  BW_ERROR_TEXT,
  BW_COGNITO_ERRORS
} from 'src/libs/qb-brand-web-components'

import { AUTH_SIGNUP_ACTION } from './../../../constants/actions'
import {
  DEFAULT_CONTAINER_STATE,
  SIGNUP_PROCESS_STEP
} from '../../../constants/containerStates'
import customConfig from 'customConfig'
import { logToAppSignal } from '../../../util/app/appSignal.helpers'

export const signupProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_SIGNUP_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case AUTH_SIGNUP_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case AUTH_SIGNUP_ACTION.ON_SIGN_UP:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case AUTH_SIGNUP_ACTION.ON_SIGN_UP_SUCCESS:
      return mergeToNewObject(state, {
        signupState: SIGNUP_PROCESS_STEP.SIGNUP_SUCCESS,
        isProcessing: false
      })

    case AUTH_SIGNUP_ACTION.ON_SIGN_UP_FAILED:
      return mergeToNewObject(state, {
        error: extractSignupErrors(payload, state),
        isProcessing: false
      })

    case AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL_SUCCESS:
      return mergeToNewObject(state, {
        signupState: SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_SUCCESS
      })

    case AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL_FAILED:
      return mergeToNewObject(state, {
        signupState: SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_FAILED
      })

    case AUTH_SIGNUP_ACTION.ON_CONFIRM_SUCCESS:
      return mergeToNewObject(state, {
        signupState: SIGNUP_PROCESS_STEP.SIGNUP_VERIFIED
      })

    case AUTH_SIGNUP_ACTION.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  termsAccepted: false,
  productsAccepted: false,
  privacyPolicyAccepted: false,
  signupState: DEFAULT_CONTAINER_STATE.NONE,
  error: {},

  optionalCheckbox: false,
  mandatoryCheckbox: false,

  isProcessing: false
}

const extractSignupErrors = (cognito, { firstName, lastName, email }) => {
  const code = cognito?.code
  switch (code) {
    case BW_COGNITO_ERRORS.USERNAME_EXISTS_EXCEPTION:
      return {
        email: customConfig.emailAlreadyExistsLabel
      }
    default:
      logToAppSignal(code, cognito?.message, {
        firstName,
        lastName,
        email
      })
      return {
        email: BW_ERROR_TEXT.SOMETHING_WENT_WRONG
      }
  }
}

export const SIGNUP_PROFILE_DISPATCH = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PASSWORD: 'password',
  TERMS_ACCEPTED: 'termsAccepted',
  PRODUCTS_ACCEPTED: 'productsAccepted',
  PRIVACY_POLICY_ACCEPTED: 'privacyPolicyAccepted',
  SIGNUP_STATE: 'signupState',
  ERROR: 'error',

  OPTIONAL_CHECKBOX: 'optionalCheckbox',
  MANDATORY_CHECKBOX: 'mandatoryCheckbox'
}
