export const EXCHANGE_PROVIDER_PROCESS_STEP = {
  WRONG_ACCOUNT_NUMBER: 'wrongAccountNumber',
  CONNECTED_ACCOUNT: 'connectedAccount',
  CHANGED_ACCOUNT: 'changedAccount',
  VIEW_SHOP: 'viewShop'
}

export const REWARD_POINTS_PROCESS_STEP = {
  ENTER_CODE: 'enterCode',
  CODE_EXPIRED: 'codeExpired',
  WRONG_REWARD_CODE: 'wrongRewardCode',
  TOO_MANY_TRIES: 'tooManyTries',
  REWARD_FROM_CODE_FAILED: 'rewardFromCodeFailed',
  POINTS_RECEIVED: 'pointsReceived',
  CODE_ALREADY_USED: 'codeAlreadyUsed',
  HOW_TO_EARN_REWARDS: 'howToEarnRewards'
}

export const REDEEM_POINTS_PROCESS_STEP = {
  POINTS_REDEEMED: 'pointsRedeemed',
  REDEEM_FAILED: 'redeemFailed',
  REDEEM_POINTS: 'redeemPoints',
  NO_ENOUGH_POINTS: 'noEnoughPoints',
  REDEEM_EXTERNAL_REWARDS: 'redeemExternalRewards',
  EXTERNAL_REWARDS_REDEEMED: 'externalRewardsRedeemed'
}

export const SIGNUP_PROCESS_STEP = {
  SIGNUP_SUCCESS: 'signUpSuccess',
  RESEND_VERIFICATION_SUCCESS: 'resendVerificationSuccess',
  RESEND_VERIFICATION_FAILED: 'resendVerificationFailed',
  SIGNUP_VERIFIED: 'signUpVerified'
}

export const QR_CODE_PROCESS_STEP = {
  SCAN_CODE: 'scanCode',
  CAMERA_BLOCKED: 'cameraBlocked'
}

export const REWARD_NFT_PROCESS_STEP = {
  NFT_RECEIVED: 'nftReceived'
}

export const DEFAULT_CONTAINER_STATE = {
  NONE: '',
  SOMETHING_WENT_WRONG: 'somethingWentWrong'
}

export const ACCOUNT_INIT_PROCESS_STEP = {
  CREATE_ACCOUNT_FAILED: 'createAccountFailed'
}

export const CASHBACK_PROCESS_STEP = {
  OPEN_FILTER_POPUP: 'openFilterPopup'
}

export const BUY_POINTS_PROCESS_STEP = {
  PURCHASED_POINTS_CONFIRMATION: 'purchasedPointsConfirmation'
}

export const TRANSACTIONS_PROCESS_STEP = {
  FUTURE_POINTS_INFO: 'futurePointsInfo'
}
