import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'

import {
  cssFontSize,
  cssFontWeight,
  CSS_TEXT_ALIGN_CENTER
} from '../../styles/shared'
import { fontSizes, SIZE_UNIT } from '../../themes'
import { PanelContentContainer } from '../shared/styled/common'
import { BW_COLOR_ACCENT } from '../../constants'
import BWIcon from '../BWIcon'

const BWPanelHeader = ({
  title,
  description,
  icon,
  color = BW_COLOR_ACCENT,
  customCss,
  titleCss,
  additionalAction,
  pushIconToMiddle = false
}) => {
  const theme = useTheme()
  const iconContainer = icon && (
    <BWIcon
      faIconCss={faIconCss(theme, color)}
      moonIconCss={moonIconCss(theme, color)}
      icon={icon}
      size={fontSizes[SIZE_UNIT.XL6]}
    />
  )
  return (
    (icon || title || description) && (
      <PanelContentContainer css={customCss}>
        {!pushIconToMiddle && iconContainer}
        {title && (
          <PanelHeadContainer css={titleCss}>{title}</PanelHeadContainer>
        )}
        {pushIconToMiddle && iconContainer}
        {description && <PanelDescContainer>{description}</PanelDescContainer>}
        {additionalAction}
      </PanelContentContainer>
    )
  )
}

export default memo(BWPanelHeader)

const faIconCss = ({ colors }, color) => css`
  color: ${colors?.[color]};
  ${cssFontSize(SIZE_UNIT.XL4)};
  margin-bottom: 5px;
`

// @TODO find a solution to remove the gap between svg and path without negative margins
const moonIconCss = ({ colors }, color) => css`
  color: ${colors?.[color]};
  margin: -30px -30px -25px -30px;
`

export const PanelHeadContainer = styled.div`
  ${cssFontSize(SIZE_UNIT.LG)};
  ${cssFontWeight(SIZE_UNIT.LG)};
  ${CSS_TEXT_ALIGN_CENTER};
`

export const PanelDescContainer = styled.div`
  ${CSS_TEXT_ALIGN_CENTER};
`
