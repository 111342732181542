import React from 'react'
import {
  faTimesCircle,
  faExclamationCircle,
  faIcons
} from '@fortawesome/pro-light-svg-icons'
import {
  BWText,
  BWAnimateRewards,
  BW_COLOR_ACCENT,
  BW_COLOR_ERROR
} from 'src/libs/qb-brand-web-components'

import Button from '../../shared/button/Button'
import Popup from '../../shared/popup/Popup'
import { getNonNegativeNumber } from '../../../util/brand.helpers'
import { LABEL_TEXT } from '../../../constants/messages'
import RedeemAsset from '../../redeem/RedeemAsset'
import customConfig from 'customConfig'
import { formatTicks } from '../../../util/string.helpers'

const RedeemPointsSuccess = ({ email, onSubmit, t, redeemOption }) => {
  const isNft = redeemOption?.isNft
  const extraParams = customConfig?.hideRedemptionSuccessSubmit
    ? { cancelLabel: t('common.close'), onCancel: onSubmit }
    : {}
  return (
    <Popup
      title={
        !customConfig?.hideRedemptionSuccessTitle &&
        t(customConfig.youGotItLabel)
      }
      description={t(
        isNft
          ? customConfig.nftRedeemedConfirmationLabel
          : customConfig.sentEmailForCouponCode,
        { email }
      )}
      icon={isNft ? faIcons : customConfig.giftCardIcon}
      {...extraParams}
    >
      <>
        <RedeemAsset {...redeemOption} />
        <BWAnimateRewards />
        {!customConfig?.hideRedemptionSuccessSubmit && (
          <Button
            label={t(customConfig.receiveGiftsLabel)}
            onClick={onSubmit}
          />
        )}
      </>
    </Popup>
  )
}

const RedeemPointsNoBalance = ({
  redeemOption: { name, value },
  currentUserPoints,
  tokenProfile,
  onSubmit,
  onCancel,
  t
}) => {
  return (
    <Popup
      title={t('redeem.not-enough-points', {
        pointsLabel: t(customConfig.pointsLabel)
      })}
      description={t(customConfig.needPointsForGiftCard, {
        giftCard: name,
        tokenName: tokenProfile.name
      })}
      icon={faTimesCircle}
      color={BW_COLOR_ERROR}
      submitLabel={t(LABEL_TEXT.ENTER_CODE)}
      onFormSubmit={onSubmit}
      cancelLabel={t(customConfig.goBackLabel)}
      onCancel={onCancel}
    >
      <BWText
        label={formatTicks(getNonNegativeNumber(value - currentUserPoints))}
        size={50}
        weight={800}
      />
    </Popup>
  )
}

const RedeemPointsFailed = ({ description, onSubmit, t }) => {
  return (
    <Popup
      title={t(customConfig.oopsLabel)}
      description={description}
      icon={faExclamationCircle}
      color={BW_COLOR_ACCENT}
      submitLabel={t('common.try-again')}
      onFormSubmit={onSubmit}
    />
  )
}

export { RedeemPointsSuccess, RedeemPointsNoBalance, RedeemPointsFailed }
