import { mergeMap, catchError, map, tap, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { BW_AUTH_LANGUAGE_ATTR, logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import {
  extractAccountProfile,
  hasUserAccountStateChanged
} from '../../util/epics.helpers'
import {
  AUTH_ACTION,
  ACCOUNT_ACTION,
  MAIN_PROFILE_ACTION
} from '../../constants/actions'
import {
  extractCognitoUserUsername,
  formatUserFieldsForCreation,
  extractCognitoUserAttributes,
  extractEmailMissing,
  wrapUserAccessToken,
  extractUserId
} from '../../util/auth.helpers'
import i18n from '../../i18n'
import {
  checkIsAlreadyAuthenticated,
  formatUserDeviceDetailsForCreation
} from '../../util/account.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import { API_RESPONSE } from '../../constants/api'

// USER ACCOUNT
const handleLoadUserAccountOnMainLoad = (action$, state$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA),
    filter((_) => !extractEmailMissing(state$)),
    mergeMap((_) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getUserByClientId(extractCognitoUserUsername(state$), accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((err) =>
              of({
                type: ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleFetchUserCountryForCreateUser = (action$) =>
  action$.pipe(
    ofType(ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_FAILED),
    tap((data) => logger(data)),
    filter(({ payload }) => payload.status === API_RESPONSE.NOT_FOUND),
    mergeMap((_) =>
      apiService.getUserCountry().pipe(
        map(({ response }) => ({
          type: ACCOUNT_ACTION.ON_GET_USER_COUNTRY_SUCCESS,
          payload: response
        })),
        catchError((err) =>
          of({
            type: ACCOUNT_ACTION.ON_GET_USER_COUNTRY_FAILED,
            payload: err
          })
        )
      )
    )
  )

const handleCreateUserAccountIfDoesNotExistAndFetchCountry = (
  action$,
  state$
) =>
  action$.pipe(
    ofType(ACCOUNT_ACTION.ON_GET_USER_COUNTRY_SUCCESS),
    tap((data) => logger(data)),
    mergeMap(({ payload: { countryCode } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createUser(
            formatUserFieldsForCreation(state$, countryCode),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((err) =>
              of({
                type: ACCOUNT_ACTION.ON_CREATE_ACCOUNT_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

// UPDATE USER ACCOUNT ON COGNITO ATTRIBUTES UPDATE
const handleUpdateUserAccountAfterCognitoUserUpdate = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS),
    map(({ payload }) => ({
      payload,
      accountProfile: extractAccountProfile(state$)
    })),
    filter(({ payload, accountProfile }) => {
      return (
        accountProfile.userId &&
        hasUserAccountStateChanged(payload, accountProfile)
      )
    }),
    map(({ payload, accountProfile }) => {
      const {
        sub,
        email,
        family_name,
        given_name,
        [BW_AUTH_LANGUAGE_ATTR]: language
      } = extractCognitoUserAttributes(payload)
      return {
        userId: sub,
        user: {
          email,
          first_name: given_name,
          second_name: family_name,
          language: language || i18n.language,
          country_ISO: accountProfile.country
        }
      }
    }),
    mergeMap((updatedUserData) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateUser(updatedUserData.userId, updatedUserData.user, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((err) =>
              of({
                type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

// CAPTURE USER DEVICE DETAILS
const handleCaptureUserDeviceDetails = (action$) =>
  action$.pipe(
    ofType(
      ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_SUCCESS,
      ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS
    ),
    filter(({ type }) =>
      type === ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS
        ? true
        : !checkIsAlreadyAuthenticated()
    ),
    mergeMap((_) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .captureDeviceDetails(
            formatUserDeviceDetailsForCreation(),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(() => ({
              type: ACCOUNT_ACTION.ON_CAPTURE_DEVICE_DETAILS_SUCCESS
            })),
            catchError((err) =>
              of({
                type: ACCOUNT_ACTION.ON_CAPTURE_DEVICE_DETAILS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

// UPDATE USER INFO
const handleUpdateUserAccount = (action$, state$) =>
  action$.pipe(
    ofType(ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.updateUser(extractUserId(state$), payload, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_SUCCESS,
            payload: commonParser(response.data)
          })),
          catchError((err) =>
            of({
              type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

export const accountEpic = combineEpics(
  handleLoadUserAccountOnMainLoad,
  handleFetchUserCountryForCreateUser,
  handleCreateUserAccountIfDoesNotExistAndFetchCountry,
  handleUpdateUserAccountAfterCognitoUserUpdate,

  // CAPTURE USER DEVICE DETAILS
  handleCaptureUserDeviceDetails,

  handleUpdateUserAccount
)
