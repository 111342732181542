import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { STAKING_ACTION } from '../../../constants/actions'

export const walletProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case STAKING_ACTION.RESET_STATE:
      return INITIAL_STATE

    case STAKING_ACTION.ON_GET_WALLET:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case STAKING_ACTION.ON_GET_WALLET_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        nfts: payload?.nfts
      })

    case STAKING_ACTION.ON_GET_WALLET_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  nfts: [],
  isProcessing: false
}
