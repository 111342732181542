import React, { memo } from 'react'
import { BW_COLOR_LITE_WHITE } from 'src/libs/qb-brand-web-components'

import FacebookIconSvg from './FacebookIconSvg'
import Button from '../shared/button/Button'

const Facebook = ({ disabled, customLabel, label, onClick }) => {
  return (
    <Button
      color='facebook'
      textColor={BW_COLOR_LITE_WHITE}
      invert={false}
      disabled={disabled}
      onClick={!disabled && onClick}
      label={
        <>
          <FacebookIconSvg /> {customLabel ? customLabel : 'Facebook ' + label}
        </>
      }
    />
  )
}

export default memo(Facebook)
