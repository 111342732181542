import React, { memo } from 'react'
import { BWPanelContent, BWText } from 'src/libs/qb-brand-web-components'

import NavButton from '../../shared/button/NavButton'
import { BUY_POINTS_URL } from '../../../constants/navigation'
import customConfig from 'customConfig'

const BuyPointsLink = ({ t }) => {
  return (
    <BWPanelContent>
      <BWText label={t(customConfig.buyPointsDesc)} alignCenter />
      <NavButton label={t('buy-points.buy-points')} to={BUY_POINTS_URL} />
    </BWPanelContent>
  )
}

export default memo(BuyPointsLink)
