export const BW_LANGUAGE_EN = 'en'
export const BW_LANGUAGE_IT = 'it'
export const BW_LANGUAGE_DE = 'de'
export const BW_LANGUAGE_FR = 'fr'
export const BW_LANGUAGE_NL = 'nl'
export const BW_LANGUAGE_ES = 'es'
export const BW_LANGUAGE_HU = 'hu'

export const BW_LANGUAGE_LABELS = {
  [BW_LANGUAGE_EN]: 'English',
  [BW_LANGUAGE_IT]: 'Italiano',
  [BW_LANGUAGE_DE]: 'Deutsch',
  [BW_LANGUAGE_FR]: 'Français',
  [BW_LANGUAGE_NL]: 'Nederlands',
  [BW_LANGUAGE_ES]: 'Español',
  [BW_LANGUAGE_HU]: 'Magyar'
}
