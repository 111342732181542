import { BW_PRODUCTION } from '../constants/app'

const reloadWindow = () => {
  window.location.reload()
}

const prependHttpsIfNotExists = (link) => {
  return link && link.indexOf('://') === -1 ? `https://${link}` : link
}

const openExternalUrl = (url, isSelf = false) => {
  window.open(
    isSelf ? url : prependHttpsIfNotExists(url),
    isSelf ? '_self' : '_blank'
  )
}

const isProduction = () => {
  return process.env.REACT_APP_ENV === BW_PRODUCTION
}

export { reloadWindow, openExternalUrl, isProduction }
