import React from 'react'
import {
  BWUnauthorizedContainer,
  BWUnauthorizedContent
} from 'src/libs/qb-brand-web-components'

import requireLanguage from '../../hoc/requireLanguage'
import FooterWrapper from 'wrappers/FooterWrapper'
import HeaderWrapper from 'wrappers/HeaderWrapper'
import { buildMultiLanguageLink } from '../shared/buildMultiLanguageLink'

const UnauthorizedLayout = ({
  children,
  selectedLanguage,
  openLanguagePopup,
  renderLanguagePopup,
  customCss
}) => {
  return (
    <BWUnauthorizedContainer css={customCss}>
      <HeaderWrapper />
      <BWUnauthorizedContent>{children}</BWUnauthorizedContent>
      <div id='main-footer'>
        <FooterWrapper
          multiLanguage={buildMultiLanguageLink(
            selectedLanguage,
            openLanguagePopup,
            renderLanguagePopup,
            true
          )}
        />
      </div>
    </BWUnauthorizedContainer>
  )
}

export default requireLanguage(UnauthorizedLayout)
