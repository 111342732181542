import React, { memo } from 'react'
import {
  BWAuthorizedContainer,
  BWAuthorizedContent
} from 'src/libs/qb-brand-web-components'

import Loading from '../shared/loading/Loading'
import NavHeaderWrapper from 'wrappers/NavHeaderWrapper'

const AuthorizedLayout = ({
  children,
  loading,
  customCss,
  moreInformation,
  poweredBy,
  footerLinks,
  ...restProps
}) => {
  return (
    <BWAuthorizedContainer>
      <NavHeaderWrapper {...restProps} />
      {loading ? (
        <Loading />
      ) : (
        <BWAuthorizedContent css={customCss}>
          {children}
          {moreInformation}
        </BWAuthorizedContent>
      )}
      {footerLinks}
      {poweredBy}
    </BWAuthorizedContainer>
  )
}

export default memo(AuthorizedLayout)
