export const TOKEN_WHITELIST_TYPE = {
  ERC_20: 'erc20',
  EXTERNAL: 'external',
  ERC_721: 'erc721',
  MILES: 'miles',
  CRYPTO: 'crypto',
  FIAT: 'fiat',
  DONATION: 'donation',
  GIFT_CARD: 'gift_card',
  MERCHANDISE: 'merchandise'
}
