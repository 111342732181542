import { REDEEM_ACTION } from '../../constants/actions'

const getRewardTypes = () => ({
  type: REDEEM_ACTION.ON_GET_REWARD_TYPES
})

const getSignedUrl = (path) => ({
  type: REDEEM_ACTION.ON_GET_SIGNED_URL,
  payload: { path }
})

export { getRewardTypes, getSignedUrl }
