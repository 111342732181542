import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'

import {
  cssGap,
  CSS_ALIGN_ITEMS_CENTER,
  CSS_GRID,
  CSS_JUSTIFY_CONTENT_CENTER,
  CSS_HEIGHT_AUTO,
  FULL_WIDTH_HEIGHT,
  CSS_FULL_WIDTH,
  cssPadding,
  CSS_JUSTIFY_CONTENT_STRETCH,
  CSS_POSITION_ABSOLUTE
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes/units'
import { PanelMainContainer, OverlayContainer } from '../shared/styled/common'
import BWButton from '../BWButton'
import BWLink from '../BWLink'
import BWPanelHeader from '../BWPanel/BWPanelHeader'
import { mq } from '../../util/theme.helpers'
import { isColorIsLight } from '../../util/style.helpers'
import { BW_COLOR_MAIN, BW_COLOR_ACCENT } from '../../constants/theme'
import { useSubmitAction } from '../../hooks/useSubmitAction'
import { DEFAULT_RESUBMIT_TIME } from '../../constants/app'

const BWPopup = ({
  show,
  title,
  description,
  color = BW_COLOR_MAIN,
  icon,
  children,
  disabledSubmit,
  onFormSubmit,
  submitLabel,
  cancelLabel,
  onCancel,
  status,
  rootCss,
  headerCss,
  contentCss,
  btnTextColor,
  resubmitTime = DEFAULT_RESUBMIT_TIME,
  btnColor,
  isRectangular,
  textTransform,
  isCustom
}) => {
  const theme = useTheme()
  const handleFormSubmit = useSubmitAction(resubmitTime, (e) => {
    if (onFormSubmit) {
      onFormSubmit(e)
    }
  })
  return (
    show && (
      <PopupContainer data-testid='bw-popup' show={show}>
        <OverlayContainer />
        <PopupBodyContainer
          onSubmit={handleFormSubmit}
          as='form'
          rootCss={rootCss}
        >
          <BWPanelHeader
            title={title}
            description={description}
            icon={icon}
            color={isCustom ? color : buildPopupHeadingIconColor(color, theme)}
            customCss={headerCss}
          />
          {children && (
            <PopupContent contentCss={contentCss}>{children}</PopupContent>
          )}
          {onFormSubmit && submitLabel && (
            <BWButton
              type='submit'
              label={submitLabel}
              color={btnColor}
              textColor={btnTextColor}
              disabled={disabledSubmit}
              isRectangular={isRectangular}
              textTransform={textTransform}
            />
          )}
          {(onCancel || status) && (
            <PopupContent>
              {onCancel && cancelLabel && (
                <BWLink label={cancelLabel} onClick={onCancel} as='a' />
              )}
              {status && status}
            </PopupContent>
          )}
        </PopupBodyContainer>
      </PopupContainer>
    )
  )
}

export default memo(BWPopup)

const PopupContainer = styled.div`
  z-index: 500;
  transition: all 0.3s ease-out;
  ${CSS_POSITION_ABSOLUTE};
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${FULL_WIDTH_HEIGHT};
  left: 0;
  top: 0;
  ${cssPadding(SIZE_UNIT.XL)};
  ${mq(SIZE_UNIT.SM)} {
    ${CSS_JUSTIFY_CONTENT_STRETCH};
  }
  overflow-y: scroll;
  ${mq(SIZE_UNIT.MD)} {
    ${cssGap(SIZE_UNIT.SM)};
    ${CSS_JUSTIFY_CONTENT_CENTER};
    grid-template-areas: 'popup-left popup-left popup-left popup-main popup-main popup-main popup-main popup-main popup-main popup-right popup-right popup-right';
    grid-template-columns: repeat(12, 60px);
  }
`

const PopupBodyContainer = styled(PanelMainContainer)`
  ${({ theme, rootCss }) => popupDefaultStyles(theme.colors, rootCss)};
  z-index: 500;
  ${CSS_FULL_WIDTH};
  ${CSS_GRID};
  ${mq(SIZE_UNIT.MD)} {
    grid-area: popup-main;
  }
  ${CSS_HEIGHT_AUTO};
`

const PopupContent = styled.div`
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${cssGap(SIZE_UNIT.SM)};
  ${CSS_FULL_WIDTH};
  ${({ contentCss }) => contentCss && contentCss};
`

const popupDefaultStyles = (colors, rootCss) => css`
  background-color: ${colors?.white};
  color: ${colors?.liteBlack};
  ${rootCss && rootCss};
`

const buildPopupHeadingIconColor = (color, { colors }) => {
  switch (color) {
    case BW_COLOR_MAIN:
      return isColorIsLight(colors[color]) ? BW_COLOR_ACCENT : color
    case BW_COLOR_ACCENT:
      return isColorIsLight(colors[color]) ? BW_COLOR_MAIN : color
    default:
      return color
  }
}
