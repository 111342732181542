import React, { memo } from 'react'
import {
  BWIcon,
  BW_COLOR_ACCENT,
  cssCustomFontSize,
  getColorByLuminosity
} from 'src/libs/qb-brand-web-components'
import { faMusic, faVideo } from '@fortawesome/pro-light-svg-icons'
import { css, useTheme } from '@emotion/react'

import { isMediaTypeAudio } from '../../../util/media.helpers'

const NftIcon = ({ metadata, color = BW_COLOR_ACCENT }) => {
  const theme = useTheme()
  const mediaType = metadata?.media?.type
  if (!mediaType) {
    return null
  }
  return (
    <BWIcon
      icon={isMediaTypeAudio(mediaType) ? faMusic : faVideo}
      faIconCss={mediaIconCss(theme, color)}
    />
  )
}

export default memo(NftIcon)

const mediaIconCss = (theme, color) => css`
  ${cssCustomFontSize(90)};
  position: absolute;
  opacity: 0.5;
  color: ${getColorByLuminosity(theme.colors[color], theme.colors)};
`
