import React, { useState, useEffect, lazy } from 'react'
import {
  extractCodeOrInputText,
  containsNoValues,
  checkValidations,
  requiredRule,
  usePreviousState
} from 'src/libs/qb-brand-web-components'
import { useDispatch } from 'react-redux'

import {
  DEFAULT_CONTAINER_STATE,
  QR_CODE_PROCESS_STEP,
  REWARD_NFT_PROCESS_STEP,
  REWARD_POINTS_PROCESS_STEP
} from '../constants/containerStates'
import brandConfig from 'brandConfig'

import {
  changeRewardsState,
  changeRewardsInputAndErrorState,
  validateRewardCode
} from '../store/actions/rewards'
import { REWARDS_PROFILE_DISPATCH } from '../store/reducers/rewards/rewards'
import EnterRewardCode from '../components/popups/reward/EnterRewardCode'
import { validRewardCodeRule } from '../util/validation.helpers'
import { eventTrack } from '../util/gaTracking.helpers'
import {
  EVENT_TRACK_LABEL_BUTTON,
  EVENT_TRACK_RECEIVE_REWARD,
  EVENT_TRACK_REWARD_CODE
} from '../constants/eventTrack'

const CodeNotExist = lazy(
  () => import('../components/popups/reward/CodeNotExist')
)
const CodeTooManyTries = lazy(
  () => import('../components/popups/reward/CodeTooManyTries')
)
const CodeExpired = lazy(
  () => import('../components/popups/reward/CodeExpired')
)
const CodeUsed = lazy(() => import('../components/popups/reward/CodeUsed'))
const NftCodeSuccess = lazy(
  () => import('../components/popups/nft/NftCodeSuccess')
)
const CameraBlocked = lazy(
  () => import('../components/popups/qrCode/CameraBlocked')
)
const ReceiveRewardByQrCode = lazy(
  () => import('../components/popups/qrCode/ReceiveRewardByQrCode')
)

const useReceiveReward = (t, rewardsProfile) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (rewardsProfile.codeValidityState) {
      setPopupState(rewardsProfile.codeValidityState)
    }
  }, [rewardsProfile.codeValidityState])

  useEffect(() => {
    resetCodeState()
    return () => {
      resetCodeState()
    }
  }, [])

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)
  const prevPopupState = usePreviousState(popupState)

  const handleEnterRewardCodeClick = () => {
    setPopupState(REWARD_POINTS_PROCESS_STEP.ENTER_CODE)
    eventTrack(
      EVENT_TRACK_REWARD_CODE.CATEGORY,
      EVENT_TRACK_REWARD_CODE.ACTION,
      EVENT_TRACK_LABEL_BUTTON
    )
  }

  const resetRewardCode = () => {
    dispatch(changeRewardsState(REWARDS_PROFILE_DISPATCH.REWARD_CODE, ''))
  }

  const handleDispatchAction = (property, value) => {
    dispatch(changeRewardsInputAndErrorState(property, value))
  }

  const handleNoPopup = () => {
    resetRewardCode()
    dispatch(changeRewardsState(REWARDS_PROFILE_DISPATCH.ERROR, {}))
    dispatch(
      changeRewardsState(REWARDS_PROFILE_DISPATCH.CODE_VALIDITY_STATE, '')
    )
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  const validateOrConfirmCode = (rewardCode) => {
    const validRewardCode = validRewardCodeRule(rewardCode)
    if (validRewardCode) {
      resetCodeState()
      dispatch(validateRewardCode(validRewardCode))
    } else {
      setPopupState(REWARD_POINTS_PROCESS_STEP.WRONG_REWARD_CODE)
    }
  }

  const handlePopupSubmit = () => {
    const { rewardCode } = rewardsProfile
    switch (popupState) {
      case REWARD_POINTS_PROCESS_STEP.ENTER_CODE:
        dispatch(changeRewardsState(REWARDS_PROFILE_DISPATCH.ERROR, {}))
        const errors = checkValidations({
          rewardCode: { value: rewardCode, rules: [requiredRule] }
        })
        if (containsNoValues(errors)) {
          validateOrConfirmCode(rewardCode)
          eventTrack(
            EVENT_TRACK_RECEIVE_REWARD.CATEGORY,
            EVENT_TRACK_RECEIVE_REWARD.ACTION,
            EVENT_TRACK_LABEL_BUTTON
          )
        } else {
          dispatch(changeRewardsState(REWARDS_PROFILE_DISPATCH.ERROR, errors))
        }
        break

      case REWARD_NFT_PROCESS_STEP.NFT_RECEIVED:
      case REWARD_POINTS_PROCESS_STEP.TOO_MANY_TRIES:
        handleNoPopup()
        break

      case REWARD_POINTS_PROCESS_STEP.WRONG_REWARD_CODE:
        resetRewardCode()
        const changePopupState =
          prevPopupState === QR_CODE_PROCESS_STEP.SCAN_CODE
            ? QR_CODE_PROCESS_STEP.SCAN_CODE
            : REWARD_POINTS_PROCESS_STEP.ENTER_CODE
        dispatch(
          changeRewardsState(
            REWARDS_PROFILE_DISPATCH.CODE_VALIDITY_STATE,
            changePopupState
          )
        )
        setPopupState(changePopupState)

        break
      case REWARD_POINTS_PROCESS_STEP.CODE_ALREADY_USED:
      case REWARD_POINTS_PROCESS_STEP.CODE_EXPIRED:
        resetRewardCode()
        handleEnterRewardCodeClick()
        break

      default:
        break
    }
  }

  const handlePopupCancel = () => {
    switch (popupState) {
      case REWARD_POINTS_PROCESS_STEP.ENTER_CODE:
        handleNoPopup()
        break

      default:
        break
    }
  }

  const handleQrCodeClick = () => {
    setPopupState(QR_CODE_PROCESS_STEP.SCAN_CODE)
  }

  const handleCancelQrCode = () => {
    setPopupState(REWARD_POINTS_PROCESS_STEP.ENTER_CODE)
  }

  const handleQrCodeError = () => {
    setPopupState(QR_CODE_PROCESS_STEP.CAMERA_BLOCKED)
  }

  const resetCodeState = () => {
    dispatch(
      changeRewardsState(
        REWARDS_PROFILE_DISPATCH.CODE_VALIDITY_STATE,
        DEFAULT_CONTAINER_STATE.NONE
      )
    )
  }

  const handleQrCodeScan = (text) => {
    if (text && !rewardsProfile.loading) {
      const rewardCode = extractCodeOrInputText(text)
      if (rewardCode) {
        validateOrConfirmCode(rewardCode)
      } else {
        setPopupState(REWARD_POINTS_PROCESS_STEP.WRONG_REWARD_CODE)
      }
    }
  }

  const renderPopup = () => {
    switch (popupState) {
      case REWARD_NFT_PROCESS_STEP.NFT_RECEIVED:
        if (brandConfig.features.disablePoints) {
          const { reward } = rewardsProfile
          return (
            <NftCodeSuccess
              onAwesomeClick={handlePopupSubmit}
              reward={reward}
              t={t}
            />
          )
        }
        return null

      case REWARD_POINTS_PROCESS_STEP.WRONG_REWARD_CODE:
        return <CodeNotExist onSubmit={handlePopupSubmit} t={t} />

      case REWARD_POINTS_PROCESS_STEP.TOO_MANY_TRIES:
        return <CodeTooManyTries onSubmit={handlePopupSubmit} t={t} />

      case REWARD_POINTS_PROCESS_STEP.CODE_EXPIRED:
        return <CodeExpired onSubmit={handlePopupSubmit} t={t} />

      case REWARD_POINTS_PROCESS_STEP.CODE_ALREADY_USED:
        return <CodeUsed onSubmit={handlePopupSubmit} t={t} />

      case REWARD_POINTS_PROCESS_STEP.ENTER_CODE:
        return (
          <EnterRewardCode
            dispatchAction={handleDispatchAction}
            rewardsProfile={rewardsProfile}
            onSubmit={handlePopupSubmit}
            onCancel={handlePopupCancel}
            onQrCodeClick={handleQrCodeClick}
            t={t}
            qrCodeEnabled={!brandConfig.features.disableQRCode}
          />
        )

      case QR_CODE_PROCESS_STEP.SCAN_CODE:
        return (
          <ReceiveRewardByQrCode
            onCancel={handleCancelQrCode}
            onError={handleQrCodeError}
            onScan={handleQrCodeScan}
            t={t}
          />
        )

      case QR_CODE_PROCESS_STEP.CAMERA_BLOCKED:
        return <CameraBlocked onCancel={handleCancelQrCode} t={t} />

      default:
        break
    }
  }
  return [handleEnterRewardCodeClick, renderPopup, popupState, setPopupState]
}

export { useReceiveReward }
