import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import customConfig from 'customConfig'
import { exchangePointsParams } from '../utils/link.helpers'

const overviewExchangeLink = (t, disabled) => {
  return mergeToNewObject(exchangePointsParams(), {
    label: t(customConfig.exchangeYourText, {
      pointsLabel: t(customConfig.pointsLabel)
    }),
    disabled
  })
}

export default overviewExchangeLink
