import React, { memo } from 'react'
import styled from '@emotion/styled'

import { inputDefaultStyles } from '../BWInput'
import { CSS_FULL_WIDTH } from '../../styles/shared'

const BWDropdown = ({ label, options, value, ...restProps }) => {
  return (
    <DropdownFieldContainer {...restProps} defaultValue={value}>
      {label && <option>{label}</option>}
      {options &&
        options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
    </DropdownFieldContainer>
  )
}

export default memo(BWDropdown)

const DropdownFieldContainer = styled.select`
  ${(props) => inputDefaultStyles(props)};
  ${CSS_FULL_WIDTH};
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
`
