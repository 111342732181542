import React, { memo } from 'react'
import styled from '@emotion/styled'

import { SOCIAL_MEDIA_ICON_STYLES } from '../../styles/shared'

const FacebookIconSvg = () => {
  return (
    <FacebookIconContainer
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 475.03 472.16'
    >
      <g data-name='Layer 2'>
        <g data-name='Layer 1'>
          <path d='M274.62,472.16v-166H330l10.54-68.66H274.62V192.93c0-18.78,9.21-37.09,38.71-37.09h29.95V97.42s-27.18-4.64-53.17-4.64c-54.27,0-89.71,32.88-89.71,92.41v52.32H140.07v68.66H200.4v166C86.86,454.32,0,356.06,0,237.51,0,106.31,106.31,0,237.51,0S475,106.31,475,237.51C475,356.06,388.17,454.32,274.62,472.16Z' />
        </g>
      </g>
    </FacebookIconContainer>
  )
}

export default memo(FacebookIconSvg)

const FacebookIconContainer = styled.svg`
  fill: ${({ theme: { colors } }) => colors.liteWhite};
  ${SOCIAL_MEDIA_ICON_STYLES};
`
