import React, { memo, lazy } from 'react'
import {
  BWOutsideLink,
  BWText,
  removeProtocolFromURL,
  checkValidations,
  containsNoValues,
  CSS_TEXT_ALIGN_CENTER,
  CSS_WORD_BREAK_ALL,
  BW_LABEL_TEXT,
  BW_COLOR_ACCENT,
  BWIcon,
  CSS_FLEX_ITEMS_CENTER,
  cssCustomGap
} from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'
import { faIcons } from '@fortawesome/pro-light-svg-icons'

import {
  buildMapLink,
  formatLocationInfo
} from '../../../util/component.helpers'
import Popup from '../../shared/popup/Popup'
import RedeemAsset from '../../redeem/RedeemAsset'
import { isRedemptionFulfillmentEnabled } from '../../../util/feature.helpers'
import { REDEMPTION_PROFILE_DISPATCH } from '../../../store/reducers/survey/redemption'
import {
  buildCheckValidationRules,
  extractSurveyQuestions
} from '../../../util/survey.helpers'
import i18n from '../../../i18n'
import { SURVEYS_PROFILE_DISPATCH } from '../../../store/reducers/survey/survey'
import customConfig from 'customConfig'
import Button from '../../shared/button/Button'
import { buildStatusLabelIcon } from '../../../util/nft.helpers'
import RichText from '../../shared/text/RichText'

const RedemptionFulfillmentForm = lazy(
  () => import('./RedemptionFulfillmentForm')
)

const RedeemPointsConfirm = ({
  title,
  confirmTitle,
  redeemOption,
  rewardsProfile: { loading },
  tokenProfile,
  onCancel,
  onSubmit,
  surveysData,
  redemptionProfile,
  changeRedemptionSurveyState,
  dispatchAction,
  changeSurveyState,
  t,
  isDisabledPointsBurn
}) => {
  const { name, website, locations, validUntil, metadata, isNft } = redeemOption
  const locationsLength = locations && locations.length
  const redemptionFulfillmentEnabled = isRedemptionFulfillmentEnabled()
  const surveyId = metadata?.surveyId
  const description = metadata?.description
  const exclusiveContent = metadata?.exclusiveContent
  const surveyFields = surveysData[surveyId]
  const surveyQuestions = extractSurveyQuestions(
    surveyFields,
    i18n.language
  )?.questions
  const { label, icon } = buildStatusLabelIcon(
    exclusiveContent,
    redeemOption.claimCount
  )
  const handleSubmit = () => {
    if (
      redemptionFulfillmentEnabled &&
      surveyQuestions &&
      surveyQuestions.length > 0
    ) {
      const errors = checkValidations(
        buildCheckValidationRules(surveyQuestions, redemptionProfile.formData)
      )
      if (containsNoValues(errors)) {
        changeSurveyState(SURVEYS_PROFILE_DISPATCH.SELECTED_SURVEY_ID, surveyId)
        onSubmit()
      } else {
        changeRedemptionSurveyState(REDEMPTION_PROFILE_DISPATCH.ERROR, errors)
      }
    } else {
      onSubmit()
    }
  }
  return (
    <Popup
      title={title}
      description={t(customConfig.redeemPointsDescLabel, {
        tokenName: tokenProfile.name
      })}
      icon={isNft ? faIcons : customConfig.giftCardIcon}
      color={BW_COLOR_ACCENT}
      cancelLabel={t(customConfig.goBackLabel)}
      onCancel={onCancel}
    >
      <RedeemAsset metadata={metadata} isNft={isNft} name={name} />

      {description && (
        <RichText
          content={description}
          customCss={{ '.nft-custom-actions': { display: 'none' } }}
        />
      )}
      {validUntil && (
        <BoldCenterText
          label={`${t('redeem.valid-until')}: ${t(BW_LABEL_TEXT.DATE_TIME, {
            date: validUntil
          })}`}
        />
      )}
      {website && (
        <BoldCenterText
          label={
            <>
              <BWText label={`${t('redeem.website')}: `} />
              <BWOutsideLink
                label={removeProtocolFromURL(website)}
                to={customConfig.buildRedeemWebsiteUrl(website)}
              />
            </>
          }
        />
      )}
      {locationsLength === 1 && (
        <BoldCenterText
          label={
            <>
              <BWText label={`${t('redeem.location')}: `} />
              <LocationLink location={locations[0]} />
            </>
          }
        />
      )}
      {locationsLength > 1 && (
        <>
          <BoldCenterText label={`${t('redeem.locations')}: `} />
          <LocationsContainer>
            {locations.map((location) => (
              <li key={location.id}>
                <BoldCenterText
                  label={
                    <>
                      <LocationLink location={location} />
                    </>
                  }
                />
              </li>
            ))}
          </LocationsContainer>
        </>
      )}
      {redemptionFulfillmentEnabled && surveyId && (
        <RedemptionFulfillmentForm
          surveyQuestions={surveyQuestions}
          dispatchAction={dispatchAction}
          redemptionProfile={redemptionProfile}
          t={t}
        />
      )}
      {exclusiveContent && (
        <ExclusiveContentContainer>
          <BoldCenterText label={`${t('common.exclusive-content')}: `} />
          <BWText label={t(`nft.exclusive-content-status.${label}`)} />
          <BWIcon icon={icon} />
        </ExclusiveContentContainer>
      )}
      <RedeemActionContainer>
        <Button
          label={confirmTitle}
          disabled={loading || isDisabledPointsBurn}
          onClick={handleSubmit}
        />
      </RedeemActionContainer>
    </Popup>
  )
}

export default memo(RedeemPointsConfirm)

const LocationsContainer = styled.ul`
  ${CSS_TEXT_ALIGN_CENTER};
  list-style-position: inside;
  li {
    margin-bottom: 4px;
  }
`

const LocationLink = ({ location }) => {
  return (
    <BWOutsideLink
      label={formatLocationInfo(location)}
      to={buildMapLink(location)}
    />
  )
}

const BoldCenterText = ({ label }) => {
  return (
    <BWText
      label={label}
      size={16}
      weight={700}
      alignCenter
      customCss={CSS_WORD_BREAK_ALL}
    />
  )
}

const RedeemActionContainer = styled.div`
  margin-top: 20px;
`

const ExclusiveContentContainer = styled.div`
  ${CSS_FLEX_ITEMS_CENTER};
  ${cssCustomGap(5)};
`
