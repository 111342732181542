import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { logger } from 'src/libs/qb-brand-web-components'
import { catchError, map, mergeMap, tap, filter } from 'rxjs/operators'

import { POINTS_REWARD_ACTION, SURVEY_ACTION } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import {
  buildSurveyRequestDataFormat,
  extractRedemptionSurveyState,
  extractSelectedSurveyIdFromState,
  formatSurveysToObject
} from '../../util/survey.helpers'
import { isRedemptionFulfillmentEnabled } from '../../util/feature.helpers'

const handleSubmitFavoriteExchange = (action$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_SUBMIT_FAVORITE_EXCHANGE),
    mergeMap(({ payload: { surveyId, favoriteOption } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .submitSurvey(
            buildSurveyRequestDataFormat(surveyId, {
              selected: favoriteOption
            }),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: SURVEY_ACTION.ON_SUBMIT_FAVORITE_EXCHANGE_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: SURVEY_ACTION.ON_SUBMIT_FAVORITE_EXCHANGE_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleSubmitRedemptionFulfillment = (action$, state$) =>
  action$.pipe(
    ofType(POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS_SUCCESS),
    filter((_) => isRedemptionFulfillmentEnabled()),
    map(() => extractRedemptionSurveyState(state$)),
    filter(({ formData }) => Object.keys(formData).length > 0),
    mergeMap(({ formData }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .submitSurvey(
            buildSurveyRequestDataFormat(
              extractSelectedSurveyIdFromState(state$),
              formData
            ),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(() => ({
              type: SURVEY_ACTION.ON_SUBMIT_REDEMPTION_FULFILLMENT_SUCCESS,
              payload: { isRedeemed: true }
            })),
            catchError((err) =>
              of({
                type: SURVEY_ACTION.ON_SUBMIT_REDEMPTION_FULFILLMENT_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetSurveys = (action$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_GET_SURVEYS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService.getSurveys(accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: SURVEY_ACTION.ON_GET_SURVEYS_SUCCESS,
            payload: formatSurveysToObject(response.data)
          })),
          catchError((err) =>
            of({ type: SURVEY_ACTION.ON_GET_SURVEYS_FAILED, payload: err })
          )
        )
      )
    )
  )

const handleGetSubmissions = (action$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_GET_USER_SURVEY_SUBMISSIONS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService.getSubmissions(accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: SURVEY_ACTION.ON_GET_USER_SURVEY_SUBMISSIONS_SUCCESS,
            payload: response.data
          })),
          catchError((err) =>
            of({
              type: SURVEY_ACTION.ON_GET_USER_SURVEY_SUBMISSIONS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

export const surveyEpic = combineEpics(
  handleSubmitFavoriteExchange,
  handleGetSurveys,
  handleGetSubmissions,
  handleSubmitRedemptionFulfillment
)
