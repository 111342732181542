import React, { memo } from 'react'

import Overview from '../../../components/overview/Overview'
import customConfig from 'customConfig'

const OverviewWrapper = (props) => {
  return <Overview icon={customConfig.coinIcon} {...props} />
}

export default memo(OverviewWrapper)
