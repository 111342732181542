export const BW_MESSAGE_TEXT = {
  LOADING_LABEL: 'Just a moment...'
}

export const BW_ERROR_TEXT = {
  FIELD_REQUIRED: 'error.field-required',
  INVALID_EMAIL_ADDRESS: 'error.invalid-email-address',
  INVALID_PASSWORD: 'error.invalid-password',
  INVALID_NUMBER_FORMAT: 'error.invalid-number-format',
  SOMETHING_WENT_WRONG: 'error.something-went-wrong',
  ATTEMPT_LIMIT_EXCEEDED: 'error.attempt-limit-exceeded',
  ACCESS_DENIED: 'error.access-denied'
}

export const BW_LABEL_TEXT = {
  QR_SCANNER_REQUIRES_CAMERA: 'qr-code.requires-camera-access',
  CAMERA_BLOCKED: 'qr-code.camera-blocked-common',
  CAMERA_BLOCKED_SAFARI: 'qr-code.camera-blocked-safari',
  CAMERA_BLOCKED_ANDROID_CHROME: 'qr-code.camera-blocked-android-chrome',
  QR_TO_ACCESS_CAMERA: 'qr-code.to-access-camera',
  QR_CAMERA_BLOCKED: 'qr-code.camera-is-blocked',
  DISMISS: 'common.dismiss',
  PLEASE: 'common.please',
  AND_THEN: 'common.and-then',
  REFRESH: 'common.refresh',
  ALL_YOUR_TRANSACTIONS: 'transactions.all-your-transactions',
  NO_TRANSACTIONS_YET: 'transactions.no-transactions-yet',
  ALL_TRANSACTIONS: 'nav-header.all-transactions',
  LOG_OUT: 'nav-header.log-out',
  TERMS: 'common.terms',
  PRIVACY: 'common.privacy',
  COOKIES: 'common.cookies',
  TERMS_CONDITIONS: 'common.terms-conditions',
  PRIVACY_POLICY: 'common.privacy-policy',
  COOKIES_POLICY: 'common.cookies-policy',
  ACCEPT_TERMS_POLICY: 'brand:common.i-hereby-accept-terms-policy',
  ACCEPT_PRODUCT_UPDATES: 'brand:common.stay-updated-on-products',
  VIEW_TERMS_POLICY: 'brand:common.view-the-terms-policy',
  CLOSE: 'common.close',
  CHOOSE_YOUR_LANGUAGE: 'common.choose-your-language',
  DATE_TIME: 'common.date-time'
}
