import React, { Fragment, memo, useEffect, useState } from 'react'
import Reward from 'react-rewards'

const BWAnimateRewards = () => {
  const [rewardAnimation, setRewardAnimation] = useState()

  useEffect(() => {
    if (rewardAnimation) {
      rewardAnimation.rewardMe()
    }
  }, [rewardAnimation])

  return (
    <Reward
      data-testid='bw-animated-rewards'
      ref={(ref) => setRewardAnimation(ref)}
      type='confetti'
      config={{
        lifetime: 270,
        decay: 0.9,
        startVelocity: 45,
        elementCount: 90,
        elementSize: 7
      }}
    >
      <Fragment />
    </Reward>
  )
}

export default memo(BWAnimateRewards)
