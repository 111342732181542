import styled from '@emotion/styled'

import {
  CSS_FLEX,
  CSS_FLEX_COLUMN,
  CSS_GRID,
  CSS_MAIN_GRID_CONTAINER,
  CSS_JUSTIFY_CONTENT_CENTER,
  CSS_PADDING_TOP_10,
  CSS_PLACE_ITEMS_START,
  CSS_ALIGN_CONTENT_START,
  cssGap,
  FULL_WIDTH_HEIGHT,
  CSS_TEXT_ALIGN_CENTER,
  CSS_GRID_COLUMN_FULL,
  CSS_FULL_WIDTH,
  CSS_ALIGN_ITEMS_CENTER,
  disabledStyles,
  CSS_FLEX_ITEMS_CENTER
} from '../../../styles/shared'
import { SIZE_UNIT } from '../../../themes/units'
import { mq } from '../../../util/theme.helpers'

export const BWAuthorizedContainer = styled.div`
  ${cssGap(SIZE_UNIT.SM)};
  ${CSS_MAIN_GRID_CONTAINER};
`

export const BWAuthorizedContent = styled.div`
  ${CSS_FLEX};
  ${CSS_FLEX_COLUMN};
  padding-bottom: 5px;
`

export const BWUnauthorizedContainer = styled.div`
  ${CSS_JUSTIFY_CONTENT_CENTER};
  ${cssGap(SIZE_UNIT.XL2)};
  ${CSS_MAIN_GRID_CONTAINER};
`

export const BWUnauthorizedContent = styled.div`
  ${CSS_GRID};
  ${CSS_JUSTIFY_CONTENT_CENTER};
  align-self: start;
  grid-template-columns: 1fr;
`

export const BWPageContent = styled.div`
  ${CSS_GRID};
  ${CSS_PLACE_ITEMS_START};
  ${CSS_ALIGN_CONTENT_START};
  ${cssGap(SIZE_UNIT.SM)};
  ${CSS_PADDING_TOP_10};
`

export const BWFlexiblePanelContainer = styled.div`
  ${FULL_WIDTH_HEIGHT};
  text-decoration: none;
  ${CSS_TEXT_ALIGN_CENTER};
  ${({ full }) => full && CSS_GRID_COLUMN_FULL};
  ${({ disabled, theme: { colors } }) => disabled && disabledStyles(colors)};
`

export const BWPanelContent = styled.div`
  ${cssGap(SIZE_UNIT.SM)};
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${CSS_FULL_WIDTH};
`

export const BrandHeader = styled.div`
  ${CSS_GRID};
  ${CSS_JUSTIFY_CONTENT_CENTER};
  padding: 20px 20px 0px 20px;
  ${mq(SIZE_UNIT.MD)} {
    ${({ noCss }) => noCss && 'padding-top: 50px'};
  }
`

export const BrandLogoContainer = styled.img`
  width: 200px;
`

export const FrontCenterContent = styled.div`
  z-index: 5;
  gap: 10px;
  flex-direction: column;
  ${CSS_FLEX_ITEMS_CENTER};
`
