import { combineReducers } from 'redux'

import { exchangeProviderProfile } from './exchangeProviders'
import { brandRewardTypesProfile } from './brandRewardTypes'
import { cryptoExchangeProfile } from './cryptoExchange'
import { buyPointsProfile } from './buyPoints'

export const exchangeReducer = combineReducers({
  exchangeProviderProfile,
  brandRewardTypesProfile,
  cryptoExchangeProfile,
  buyPointsProfile
})
