import React, { memo } from 'react'
import { BWButton } from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import customConfig from 'customConfig'

const Button = (props) => {
  return <BWButton {...props} />
}

const { prefColor, prefTextColor } = brandConfig
const { isRectangular, textTransform } = customConfig.customCss

Button.defaultProps = {
  textColor: prefTextColor,
  color: prefColor,
  invert: false,
  isRectangular: isRectangular,
  textTransform: textTransform
}

export default memo(Button)
