import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  cssBorderRadius,
  cssFontSize,
  CSS_FLEX,
  CSS_CURSOR_POINTER,
  CSS_ALIGN_ITEMS_CENTER,
  CSS_GRID,
  CSS_BOX_SIZING_BORDER_BOX,
  CSS_FULL_WIDTH,
  CSS_ITEMS_CENTER,
  CSS_POSITION_ABSOLUTE
} from '../../styles/shared'
import { MESSAGE_TYPE } from '../../constants/constants'
import { SIZE_UNIT } from '../../themes/units'
import { buildFieldIcon } from '../shared/styled/common'

const BWCheckbox = ({ label, onChange, checked = false, success, error }) => {
  const messageType =
    ((success || checked) && MESSAGE_TYPE.SUCCESS) ||
    (error && MESSAGE_TYPE.ERROR)
  return (
    <CheckboxContent data-testid='bw-checkbox'>
      <CheckboxContainer
        checked={checked}
        messageType={messageType}
        onClick={() => onChange && onChange()}
      >
        <InputFieldContainer
          type='checkbox'
          checked={checked}
          onChange={() => {}}
        />
        <DisplayIcon icon={buildFieldIcon(messageType)} />
      </CheckboxContainer>
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxContent>
  )
}

export default memo(BWCheckbox)

const CheckboxContainer = styled.div`
  ${CSS_FLEX};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${cssFontSize(SIZE_UNIT.MD)};
  ${cssBorderRadius(SIZE_UNIT.SM)};
  ${CSS_CURSOR_POINTER};
  padding: 7px;
  height: 30px;
  width: 30px;
  ${({ theme, messageType, checked }) =>
    inputDefaultStyles(theme.colors, messageType, checked)};
`

const InputFieldContainer = styled.input`
  ${CSS_CURSOR_POINTER};
  ${CSS_POSITION_ABSOLUTE};
  opacity: 0;
  height: 0;
  width: 0;
`

const inputDefaultStyles = (colors, type, checked) => css`
  color: ${checked ? colors?.success : type ? colors[type] : colors?.gray};
  background-color: ${colors?.liteWhite};
  ${CSS_BOX_SIZING_BORDER_BOX};
`

const DisplayIcon = styled(FontAwesomeIcon)`
  ${cssFontSize(SIZE_UNIT.MD)};
`

const CheckboxContent = styled.div`
  ${CSS_GRID};
  ${CSS_ITEMS_CENTER};
  ${CSS_FULL_WIDTH};
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`

const CheckboxLabel = styled.label``
