import React, { memo } from 'react'
import {
  BWPageContent,
  BWPanel,
  BWText,
  BWNoRecordsFound
} from 'src/libs/qb-brand-web-components'
import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons'

import PageHeaderWrapper from 'wrappers/PageHeaderWrapper'

const EngravingMessages = ({ engravings, t }) => {
  return (
    <>
      <PageHeaderWrapper
        label={t('brand:nft.engraving-messages')}
        icon={faCommentsAlt}
        header
      />
      {engravings.length <= 0 ? (
        <BWNoRecordsFound
          icon={faCommentsAlt}
          description={t('brand:nft.no-public-engravings-found')}
        />
      ) : (
        <BWPageContent>
          {engravings.map((engraving, index) => (
            <EngravingMessageCard key={index} engraving={engraving} />
          ))}
        </BWPageContent>
      )}
    </>
  )
}

export default memo(EngravingMessages)

const EngravingMessageCard = ({ engraving: { message } }) => {
  return (
    <BWPanel gap={10}>
      <BWText label={message} alignCenter />
    </BWPanel>
  )
}
