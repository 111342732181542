import React, { memo, useEffect } from 'react'
import ShakaPlayer from 'shaka-player-react'
import 'shaka-player/dist/controls.css'
import styled from '@emotion/styled'
import {
  BW_COLOR_MAIN,
  CSS_FULL_WIDTH,
  CSS_POSITION_RELATIVE
} from 'src/libs/qb-brand-web-components'

import NftChip from '../nft/NftChip'
import { isMediaTypeAudio } from '../../../util/media.helpers'

const MediaPlayer = ({ noChip, mediaType, noBorder, ...restProps }) => {
  useEffect(() => {
    if (isMediaTypeAudio(mediaType)) {
      const fullScreenButton = document.querySelector(
        '.shaka-video-container .shaka-fullscreen-button'
      )
      if (fullScreenButton) {
        fullScreenButton.remove()
      }
    }
    const settingsMenu = document.querySelector(
      '.shaka-video-container .shaka-overflow-menu-button'
    )
    if (settingsMenu) {
      settingsMenu.remove()
    }
  }, [mediaType])
  return (
    <MediaPlayerContainer>
      {!noChip && <NftChip />}
      <ShakaPlayerContainer
        type={mediaType}
        {...restProps}
        border={+noBorder}
      />
    </MediaPlayerContainer>
  )
}

MediaPlayer.defaultProps = {
  color: BW_COLOR_MAIN,
  noBorder: false
}

export default memo(MediaPlayer)

const MediaPlayerContainer = styled.div`
  ${CSS_POSITION_RELATIVE};
  ${CSS_FULL_WIDTH};
`

const ShakaPlayerContainer = styled(ShakaPlayer)`
  ${({ theme: { colors }, type, color, border }) => [
    isMediaTypeAudio(type) &&
      `
      height: 295px;
      .shaka-server-side-ad-container {
        background-color: ${colors[color]};
      }
    `,
    `
    .shaka-server-side-ad-container {
      border-radius: 10px;
    }
    .shaka-scrim-container {
      border-radius: 10px;
    }
    .shaka-controls-container {
      ${!border && `border: 1px solid ${colors.white} !important`};
      border-radius: 10px;
    }
    video {
      border-radius: 10px;
    }
    `
  ]}
`
