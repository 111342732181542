import React, { memo } from 'react'
import { Trans } from 'react-i18next'
import {
  BW_COLOR_ACCENT,
  BWOutsideLink
} from 'src/libs/qb-brand-web-components'

import Popup from '../../shared/popup/Popup'
import HowToEarnRewardsWrapper from 'wrappers/HowToEarnRewardsWrapper'
import customConfig from 'customConfig'
import brandConfig from 'brandConfig'
import BuyPointsLink from './BuyPointsLink'
import CashbackLink from './CashbackLink'
import { EARN_REWARD_OPTION } from '../../../constants/pointsExchange'

const HowToEarnRewards = ({
  onCancel,
  t,
  desc,
  tokenProfile: { name, symbol, enablePurchasePoints },
  cashbackEnabled
}) => {
  const description = brandConfig.ladUrl ? (
    <Trans i18nKey={customConfig.howManyPointsYouEarnLabel} i18nIsDynamicList>
      <BWOutsideLink
        label={t('brand:reward.qiibee-lad')}
        to={brandConfig.ladUrl}
      />
    </Trans>
  ) : (
    t(customConfig.howManyPointsYouEarnLabel, {
      tokenName: name,
      tokenSymbol: symbol
    })
  )
  const renderRewardOptions = () => {
    return customConfig.rewardOptionsOrder.map((element, index) => {
      switch (element) {
        case EARN_REWARD_OPTION.BUY_POINTS:
          return enablePurchasePoints && <BuyPointsLink t={t} key={index} />
        case EARN_REWARD_OPTION.CASHBACK:
          return cashbackEnabled && <CashbackLink t={t} key={index} />
        default:
          return null
      }
    })
  }
  return (
    <Popup
      icon={customConfig.coinIcon}
      title={t(customConfig.howToEarnLabel)}
      description={desc ? desc : description}
      color={BW_COLOR_ACCENT}
      cancelLabel={t('common.close')}
      onCancel={onCancel}
      contentCss={{ gap: 20 }}
    >
      <HowToEarnRewardsWrapper t={t} tokenSymbol={symbol} />
      {renderRewardOptions()}
    </Popup>
  )
}

export default memo(HowToEarnRewards)
