import {
  mergeToNewObject,
  extractInputValueAndError,
  BW_ERROR_TEXT,
  BW_COGNITO_ERRORS
} from 'src/libs/qb-brand-web-components'

import { ERROR_TEXT } from '../../../constants/messages'
import { AUTH_FORGOT_PASSWORD_ACTION } from './../../../constants/actions'
import customConfig from 'customConfig'

export const forgotPasswordProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case AUTH_FORGOT_PASSWORD_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case AUTH_FORGOT_PASSWORD_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case AUTH_FORGOT_PASSWORD_ACTION.RESET_STATE:
      return INITIAL_STATE

    case AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_SUCCESS:
      return mergeToNewObject(state, {
        success: { email: customConfig.forgotPasswordSuccessLabel }
      })

    case AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_FAILED:
      return mergeToNewObject(state, { error: extractErrors(payload) })

    default:
      return state
  }
}

const INITIAL_STATE = {
  email: '',
  error: {},
  success: {}
}

export const FORGOT_PASSWORD_PROFILE_DISPATCH = {
  EMAIL: 'email',
  ERROR: 'error'
}

const extractErrors = ({ code }) => {
  switch (code) {
    case BW_COGNITO_ERRORS.RESOURCE_NOT_FOUND_EXCEPTION:
      return {
        email: ERROR_TEXT.FORGOT_PASSWORD_ERROR
      }
    case BW_COGNITO_ERRORS.LIMIT_EXCEEDED_EXCEPTION:
      return {
        email: BW_ERROR_TEXT.ATTEMPT_LIMIT_EXCEEDED
      }
    default:
      return {
        email: BW_ERROR_TEXT.SOMETHING_WENT_WRONG
      }
  }
}
