import { BW_LABEL_TEXT } from 'src/libs/qb-brand-web-components'

import { LOG_IN_URL, REWARD_URL, WELCOME_URL } from '../constants/navigation'
import brandConfig from 'brandConfig'

const welcomeScreenLink = () => {
  const { disableWelcomeScreen, disableRewardScreen } = brandConfig.features
  return !disableWelcomeScreen
    ? WELCOME_URL
    : !disableRewardScreen
      ? REWARD_URL
      : LOG_IN_URL
}

const buildUserAccountSiteLinks = (t, extraLink) => {
  return buildSiteLinks(
    t(BW_LABEL_TEXT.TERMS_CONDITIONS),
    t(BW_LABEL_TEXT.PRIVACY_POLICY),
    t(BW_LABEL_TEXT.COOKIES_POLICY),
    extraLink
  )
}

const buildFooterSiteLinks = (t) => {
  return buildSiteLinks(
    t(BW_LABEL_TEXT.TERMS),
    t(BW_LABEL_TEXT.PRIVACY),
    t(BW_LABEL_TEXT.COOKIES)
  )
}

const buildSiteLinks = (
  termsLabel,
  privacyPolicyLabel,
  cookiePolicyLabel,
  extraLink = null
) => {
  const { termsUrl, privacyPolicyUrl, cookiePolicyUrl } = brandConfig
  const links = []
  if (extraLink) {
    links.push(extraLink)
  }
  if (termsUrl) {
    links.push({ label: termsLabel, to: termsUrl })
  }
  if (privacyPolicyUrl) {
    links.push({
      label: privacyPolicyLabel,
      to: privacyPolicyUrl
    })
  }
  if (cookiePolicyUrl) {
    links.push({
      label: cookiePolicyLabel,
      to: cookiePolicyUrl
    })
  }
  return links
}

export { welcomeScreenLink, buildUserAccountSiteLinks, buildFooterSiteLinks }
