import { ACCOUNT_ACTION, MAIN_PROFILE_ACTION } from '../../constants/actions'

const changeAccountState = (property, value) => ({
  type: ACCOUNT_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeAccountInputAndErrorState = (property, value) => ({
  type: ACCOUNT_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const getAccountDetails = () => ({
  type: ACCOUNT_ACTION.GET_ACCOUNT_DETAILS
})

const resetAccountState = () => ({
  type: ACCOUNT_ACTION.RESET_STATE
})

const updateUserAccount = (userAttributes) => ({
  type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT,
  payload: userAttributes
})

const resetPreviousUserPoints = () => ({
  type: MAIN_PROFILE_ACTION.RESET_PREVIOUS_USER_POINTS_STATE
})

export {
  changeAccountState,
  changeAccountInputAndErrorState,
  getAccountDetails,
  resetAccountState,
  updateUserAccount,
  resetPreviousUserPoints
}
