import customConfig from 'customConfig'
import {
  REDEEM_POINTS_URL,
  EXCHANGE_POINTS_URL,
  NFTS_URL,
  CASHBACK_URL
} from '../../../constants/navigation'
import { buildRedeemUrlParams } from '../../../util/navigation.helpers'

const redeemPointsParams = () => {
  return {
    icon: customConfig.giftCardIcon,
    ...buildRedeemUrlParams(REDEEM_POINTS_URL)
  }
}

const exchangePointsParams = () => {
  return {
    icon: customConfig.exchangeIcon,
    to: EXCHANGE_POINTS_URL
  }
}

const nftParams = () => {
  return {
    icon: customConfig.nftsIcon,
    to: NFTS_URL
  }
}

const cashbackParams = () => {
  return {
    icon: customConfig.cashbackIcon,
    to: CASHBACK_URL
  }
}

export { redeemPointsParams, exchangePointsParams, nftParams, cashbackParams }
