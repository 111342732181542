import { containsValues } from 'src/libs/qb-brand-web-components'

import { APP_CONFIG } from '../constants/appConfig'
import brandConfig from 'brandConfig'
import { LOYALTY_EVENT_TYPES } from '../constants/transactions'
import { toUrlQueryParams } from '../util/object.helpers'
import { HTTP_METHOD, NFT_MEMBERSHIPS_PATH } from '../constants/api'
import { withIntercept } from '../util/app/api.helpers'
import { FREE_IP_API_URL } from '../constants/externalUrls'
import { foundationApiUrl } from '../brands/qbx_vip/wrappers/utils/earn-vir.helpers'

const USERS = '/users'
const BRANDS = '/brands'
const CODES = '/codes'
const TRANSACTIONS = '/transactions'
const MEMBERSHIPS = '/memberships'
const REWARD_TYPES_URL = '/reward-types'
const BALANCES = '/balances'
const EXCHANGE_WHITELIST = '/token-exchange-whitelist'
const EXCHANGE_RATES = '/exchange-rates'
const CRYPTOS = '/cryptos'
const NFTS = '/nfts'
const TOKENS = '/tokens'
const SURVEYS = '/surveys'
const ORDERS = '/orders'
const USER_SURVEYS = '/user-surveys'
const SESSION_DATA = '/session-data'
const CLAIMS = '/claims'
const CASHBACK = '/earn-options/cashback'
const CASHBACK_TOP_STORES = '/get-top-stores'
const CASHBACK_SEARCH = '/search-store-by-term'
const CASHBACK_FILTER = '/list-categories'
const CASHBACK_DETAILS = '/search-specific-store'
const BRAND_CONFIG = '/brand-config'
const BUY_POINTS_CHECKOUT_SESSION = '/buy-points-checkout-session'
const PAYMENT_SESSION_DETAILS = '/payment-session-details'
const STRIPE = '/stripe'

class ApiService {
  constructor() {
    this.usersAPI = APP_CONFIG.API_URL + USERS
    this.codesAPI = APP_CONFIG.API_URL + CODES
    this.transactionsAPI = APP_CONFIG.API_URL + TRANSACTIONS
    this.exchangeWhitelist = APP_CONFIG.API_URL + EXCHANGE_WHITELIST
    this.nftsAPI = APP_CONFIG.API_URL + NFTS
    this.tokensAPI = APP_CONFIG.API_URL + TOKENS
    this.surveysAPI = APP_CONFIG.API_URL + SURVEYS
    this.userSurveysAPI = APP_CONFIG.API_URL + USER_SURVEYS
    this.sessionDataAPI = APP_CONFIG.API_URL + SESSION_DATA
    this.brandsAPI = APP_CONFIG.API_URL + BRANDS
    this.ordersAPI = APP_CONFIG.API_URL + ORDERS
    this.claimsAPI = APP_CONFIG.API_URL + CLAIMS
  }

  getBrandConfig = (accessToken) =>
    withIntercept(HTTP_METHOD.GET, APP_CONFIG.API_URL + BRAND_CONFIG, {
      accessToken
    })

  // TOKENS
  getUserBrandTokens = (authId, path, accessToken) =>
    withIntercept(
      HTTP_METHOD.GET,
      this.usersAPI + formatToParam(authId) + BALANCES + path,
      { accessToken }
    )

  // VALIDATE CODE
  validateCode = (codeValue, accessToken, recaptchaToken) =>
    withIntercept(HTTP_METHOD.GET, this.codesAPI + formatToParam(codeValue), {
      accessToken,
      recaptchaToken
    })

  receiveRewardFromCode = ({ code, rewardType }, accessToken, recaptchaToken) =>
    withIntercept(
      HTTP_METHOD.POST,
      this.transactionsAPI,
      { accessToken, recaptchaToken },
      getRewardBodyRequest(rewardType, { code }),
      true
    )

  receiveRewardFromBrandPoints = (
    { type, ...restParams },
    accessToken,
    recaptchaToken
  ) =>
    withIntercept(
      HTTP_METHOD.POST,
      this.transactionsAPI,
      { accessToken, recaptchaToken },
      getRewardBodyRequest(type, restParams),
      true
    )

  receiveExternalRewardFromBrandPoints = (
    type,
    params,
    accessToken,
    recaptchaToken
  ) =>
    withIntercept(
      HTTP_METHOD.POST,
      this.transactionsAPI,
      { accessToken, recaptchaToken },
      getRewardBodyRequest(type, params),
      true
    )

  getRewardTypes = (brandId) => {
    return withIntercept(
      HTTP_METHOD.GET,
      this.brandsAPI + formatToParam(brandId) + REWARD_TYPES_URL
    )
  }

  // USERS
  getUserByClientId = (id, accessToken) =>
    withIntercept(HTTP_METHOD.GET, this.usersAPI + formatToParam(id), {
      accessToken
    })

  getUserCountry = () => withIntercept(HTTP_METHOD.GET, FREE_IP_API_URL)

  createUser = (userData, accessToken) =>
    withIntercept(HTTP_METHOD.POST, this.usersAPI, { accessToken }, userData)

  updateUser = (id, updatedUserData, accessToken) =>
    withIntercept(
      HTTP_METHOD.PATCH,
      this.usersAPI + formatToParam(id),
      { accessToken },
      { user: updatedUserData }
    )

  captureDeviceDetails = (userData, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      this.sessionDataAPI,
      {
        accessToken
      },
      userData
    )
  }

  // TRANSACTIONS
  getTransactions = (params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      this.transactionsAPI + buildQueryParams(params, true),
      { accessToken }
    )
  }

  // TOKENS
  getTokens = (params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      this.tokensAPI + buildQueryParams(params, true),
      { accessToken }
    )
  }

  // CASHBACK
  getCashbackBrands = ({ categories, ...restParams }, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      getCashbackPath(CASHBACK_TOP_STORES) +
        buildQueryParams(restParams, true) +
        formatListToQuery(categories, 'categories'),
      { accessToken }
    )
  }

  getCashbackBrandsBySearch = (params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      getCashbackPath(CASHBACK_SEARCH) + buildQueryParams(params, true),
      { accessToken }
    )
  }

  getFilterCategories = (accessToken) => {
    return withIntercept(HTTP_METHOD.GET, getCashbackPath(CASHBACK_FILTER), {
      accessToken
    })
  }

  getCashbackBrandDetails = (params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      getCashbackPath(CASHBACK_DETAILS) + buildQueryParams(params, true),
      { accessToken }
    )
  }

  // SURVEYS
  submitSurvey = (data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      this.userSurveysAPI,
      {
        accessToken
      },
      data
    )
  }

  getSurveys = (accessToken) => {
    return withIntercept(HTTP_METHOD.GET, this.surveysAPI, { accessToken })
  }

  getSubmissions = (accessToken) => {
    return withIntercept(HTTP_METHOD.GET, this.userSurveysAPI, { accessToken })
  }

  // USERS - MEMBERSHIPS
  createUserMembership = (userId, membershipData, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      this.usersAPI + formatToParam(userId) + MEMBERSHIPS,
      { accessToken },
      formatMembershipData(membershipData)
    )
  }

  getUserMemberships = (userId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      this.usersAPI + formatToParam(userId) + MEMBERSHIPS,
      { accessToken }
    )
  }

  // EXCHANGE PAIRS
  getExchangeWhitelist = (accessToken) =>
    withIntercept(HTTP_METHOD.GET, this.exchangeWhitelist, { accessToken })

  // CRYPTO EXCHANGE RATES
  getCryptoExchangeRates = (accessToken) =>
    withIntercept(
      HTTP_METHOD.GET,
      APP_CONFIG.API_URL + CRYPTOS + EXCHANGE_RATES,
      {
        accessToken
      }
    )

  // GET ENGRAVINGS
  getEngravings = () => {
    return withIntercept(HTTP_METHOD.GET, brandConfig.engravingsUrl)
  }

  // SIGNED URL
  getSignedUrl = (path, accessToken) => {
    return withIntercept(HTTP_METHOD.GET, `${APP_CONFIG.API_URL}${path}`, {
      accessToken
    })
  }

  // NFT
  listNftForSale = (data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      this.ordersAPI,
      {
        accessToken
      },
      data,
      true
    )
  }

  getNftsListedForSale = (params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      this.ordersAPI + buildQueryParams(params, true),
      { accessToken }
    )
  }

  getNftClaims = (nftTokenId, nftId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      this.claimsAPI + formatToParam(nftTokenId) + formatToParam(nftId),
      { accessToken }
    )
  }

  nftAction = (data, accessToken, recaptchaToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      this.transactionsAPI,
      {
        accessToken,
        recaptchaToken
      },
      data,
      true
    )
  }

  cancelNftListing = (orderId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.DELETE,
      this.ordersAPI + formatToParam(orderId),
      { accessToken }
    )
  }

  getNftExclusiveContent = (url) => {
    return withIntercept(HTTP_METHOD.GET, url)
  }

  refreshMembership = (data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      APP_CONFIG.API_URL + NFT_MEMBERSHIPS_PATH,
      { accessToken },
      data,
      true
    )
  }

  // BUY POINTS
  generateBuyPointsCheckoutSession = (data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      `${paymentGatewayUrl()}${BUY_POINTS_CHECKOUT_SESSION}`,
      { accessToken },
      data,
      true
    )
  }

  getPaymentSession = (sessionId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${paymentGatewayUrl()}${PAYMENT_SESSION_DETAILS}${formatToParam(
        sessionId
      )}`,
      { accessToken }
    )
  }

  // STAKING
  getWallet = (address) => {
    // @TODO: remove hard coded address
    address = '0x3aA073f5C50D8c598520832053682cEa1A645a4d'
    return withIntercept(
      HTTP_METHOD.GET,
      `https://qiibeefoundation.org/api.php?wallet=${address}`
    )
  }

  // EARN VIA X
  getXProfile = (authId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      foundationApiUrl(`profile.php?account_number=${authId}`),
      {
        accessToken
      }
    )
  }

  disconnectXAccount = (authId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      foundationApiUrl(`disconnect.php?account_number=${authId}`),
      {
        accessToken
      }
    )
  }
}

const formatToParam = (value) => {
  return `/${value}`
}

const apiService = new ApiService()

export default apiService

function getRewardBodyRequest(
  type,
  { amount, accountNumber, code, rewardTypeId, tokenId, toTokenId }
) {
  const body = {
    reward_type: type
  }

  switch (type) {
    case LOYALTY_EVENT_TYPES.CODE_TO_POINTS:
    case LOYALTY_EVENT_TYPES.CODE_TO_NFT:
      body.code = code
      break

    case LOYALTY_EVENT_TYPES.POINTS_TO_REWARDS:
      body.reward_type_id = rewardTypeId
      body.token_id = tokenId
      break

    case LOYALTY_EVENT_TYPES.EXCHANGE:
      body.amount = Number(amount)
      body.brandA_token_id = tokenId
      body.membership_id = accountNumber
      body.brandB_token_id = toTokenId
      break

    case LOYALTY_EVENT_TYPES.POINTS_TO_NFT_EXCHANGE:
      body.from_token_id = tokenId
      body.to_token_id = toTokenId
      break

    default:
      break
  }

  return {
    transaction: body
  }
}

function formatMembershipData(data) {
  return {
    membership: {
      membership_number: data.connectAccountNumber,
      brand_id: data.brandId
    }
  }
}

const buildQueryParams = (params, isPositionStarting = false) => {
  if (containsValues(params)) {
    return `${isPositionStarting ? '?' : '&'}${toUrlQueryParams(params)}`
  }
  return ''
}

const formatListToQuery = (list, paramName = 'types', startDelimiter = '&') => {
  return list.length ? `${startDelimiter}${paramName}=${list.join(',')}` : ''
}

const paymentGatewayUrl = () => {
  return APP_CONFIG.SLS_URL + STRIPE
}

const getCashbackPath = (path = '') => {
  return `${APP_CONFIG.API_URL}${CASHBACK}${path}`
}
