import { DEFAULT_COUNTRY_VALUE } from '../constants/app'
import { countriesWithContinent } from '../data/countries'

const fetchCountriesByRegion = () => {
  let countries = []
  countries = getCountriesIsoArray().map((countryIso) => {
    const countryWithContent = countriesWithContinent[countryIso]
    return {
      label: countryWithContent.country,
      value: countryIso
    }
  })
  countries.unshift({
    label: '',
    value: DEFAULT_COUNTRY_VALUE
  })
  return countries
}

const getCountriesIsoArray = () => {
  return Object.keys(countriesWithContinent)
}

export { fetchCountriesByRegion }
