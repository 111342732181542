import React, { memo } from 'react'
import {
  BWCheckbox,
  BWHelpText,
  BWOutsideLink,
  BWPanelContent
} from 'src/libs/qb-brand-web-components'
import { Trans } from 'react-i18next'

import { SIGNUP_PROFILE_DISPATCH } from '../../../store/reducers/auth/signup'
import customConfig from 'customConfig'

const PrivacyPolicyCheckbox = ({
  dispatchAction,
  signupProfile: { error, privacyPolicyAccepted },
  onClickPrivacyPolicy,
  privacyPolicyClickError,
  t
}) => {
  const { acceptPrivacyPolicy, privacyPolicyLabel, privacyPolicyUrl } =
    customConfig
  return (
    <BWPanelContent>
      <BWCheckbox
        label={
          <Trans i18nKey={acceptPrivacyPolicy} i18nIsDynamicList>
            <BWOutsideLink
              label={t(privacyPolicyLabel)}
              to={privacyPolicyUrl}
              onClick={onClickPrivacyPolicy}
            />
          </Trans>
        }
        checked={privacyPolicyAccepted}
        onChange={() =>
          dispatchAction(
            SIGNUP_PROFILE_DISPATCH.PRIVACY_POLICY_ACCEPTED,
            !privacyPolicyAccepted
          )
        }
        error={error[SIGNUP_PROFILE_DISPATCH.PRIVACY_POLICY_ACCEPTED]}
      />
      <BWHelpText invertErrorColor error={privacyPolicyClickError} />
    </BWPanelContent>
  )
}

export default memo(PrivacyPolicyCheckbox)
