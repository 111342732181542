import React, { memo } from 'react'
import styled from '@emotion/styled'
import { BWText, CSS_POSITION_RELATIVE } from 'src/libs/qb-brand-web-components'

import NftChip from '../shared/nft/NftChip'
import { isMediaTypeImage } from '../../util/media.helpers'
import MediaPlayer from '../shared/media/MediaPlayer'

const RedeemAsset = ({ metadata, isNft, name }) => {
  const coverImage = metadata?.coverImage
  const mediaType = metadata?.media?.type
  return (
    <>
      {coverImage && (
        <RedeemAssetContainer>
          {isNft && <NftChip />}
          <RedeemCoverImage src={coverImage} />
        </RedeemAssetContainer>
      )}
      {isNft && mediaType && !isMediaTypeImage(mediaType) && (
        <MediaPlayer
          src={metadata?.media?.url}
          mediaType={mediaType}
          noBorder
        />
      )}
      {name && <BWText alignCenter label={name} size={24} weight={800} />}
    </>
  )
}

export default memo(RedeemAsset)

const RedeemCoverImage = styled.img`
  width: 215px;
  height: 215px;
  border-radius: 10px;
  object-fit: cover;
`

const RedeemAssetContainer = styled.div`
  ${CSS_POSITION_RELATIVE};
`
