import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ErrorBoundary } from '@appsignal/react'
import { logAppVersion } from 'src/libs/qb-brand-web-components'
import { BrowserRouter } from 'react-router-dom'

import { getAppSignalConfig } from './constants/appConfig'
import { store } from './store'
import RootWrapper from 'wrappers/RootWrapper'

logAppVersion()

const App = () => {
  const [userId, setUserId] = useState('')

  useEffect(() => {
    const storeUnsubscribe = store.subscribe(() => {
      const userId = store.getState().accountReducer.accountProfile.userId
      if (userId) {
        setUserId(userId)
      }
    })

    return () => {
      storeUnsubscribe()
    }
  }, [])

  return (
    <ErrorBoundary {...getAppSignalConfig(userId)}>
      <Provider store={store}>
        <BrowserRouter>
          <RootWrapper />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
