import React from 'react'
import {
  BWTopBar,
  BWMenuItem,
  BW_LABEL_TEXT
} from 'src/libs/qb-brand-web-components'
import { Link, useNavigate } from 'react-router-dom'
import { faSignOut, faUserCircle } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'

import authBrandLogo from 'wrappers/authBrandLogo'
import {
  HOME_URL,
  ACCOUNT_URL,
  TRANSACTIONS_URL
} from '../../constants/navigation'
import brandConfig from 'brandConfig'
import customConfig from 'customConfig'

const NavHeader = ({
  accountProfile: { firstName, lastName },
  signOut,
  pointsLink,
  exchangePointsLink,
  nftLink,
  cashbackLink,
  engravingMessagesLink,
  contactLink,
  impactReport,
  multiLanguage: { languageLink, selectedLanguage, renderLanguagePopup }
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleOnLogoClick = () => {
    navigate(HOME_URL)
  }

  return (
    <>
      <BWTopBar
        brandLogo={authBrandLogo()}
        onBrandLogoClick={handleOnLogoClick}
        invert={brandConfig.invertTopBar}
        invertBg={brandConfig.invertBg}
        name={brandConfig.brandSymbol}
      >
        <BWMenuItem
          label={`${firstName} ${lastName}`}
          to={ACCOUNT_URL}
          as={Link}
          icon={faUserCircle}
        />
        {pointsLink}
        {exchangePointsLink}
        {nftLink}
        {cashbackLink}
        {engravingMessagesLink}
        {impactReport}
        <BWMenuItem
          label={t(customConfig.allTransactionsLabel)}
          to={TRANSACTIONS_URL}
          as={Link}
          customCss={customConfig.allTransactionsLabelCss}
          icon={customConfig.transactionsIcon}
        />
        {languageLink}
        {contactLink}
        <BWMenuItem
          label={t(BW_LABEL_TEXT.LOG_OUT)}
          icon={faSignOut}
          customCss={customConfig.signOutLabelCss}
          onClick={signOut}
        />
      </BWTopBar>
      {renderLanguagePopup && renderLanguagePopup(t, selectedLanguage)}
    </>
  )
}

export default NavHeader
