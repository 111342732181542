import {
  mergeToNewObject,
  BW_AUTH_STATE
} from 'src/libs/qb-brand-web-components'

import { AUTH_ACTION, AUTH_LOGIN_ACTION } from './../../../constants/actions'

export const authProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_ACTION.ON_GET_CURRENT_USER:
      return mergeToNewObject(state, { authState: BW_AUTH_STATE.CHECKING })

    case AUTH_ACTION.ON_GET_CURRENT_USER_SUCCESS:
    case AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS:
      return mergeToNewObject(state, {
        authState: BW_AUTH_STATE.AUTHENTICATED,
        cognitoUser: payload
      })

    case AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS:
    case AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_TERMS_ACCEPTANCE_SUCCESS:
      return mergeToNewObject(state, { cognitoUser: payload })

    case AUTH_ACTION.ON_SIGN_OUT:
      return mergeToNewObject(state, {
        initSignOut: true
      })

    case AUTH_ACTION.ON_GET_CURRENT_USER_FAILED:
    case AUTH_ACTION.ON_SIGN_OUT_SUCCESS:
      return mergeToNewObject(state, {
        authState: BW_AUTH_STATE.NOT_AUTHENTICATED,
        cognitoUser: {}
      })

    case AUTH_ACTION.ON_UPDATE_EMAIL_MISSING:
      return mergeToNewObject(state, { emailMissing: payload })

    default:
      return state
  }
}

const INITIAL_STATE = {
  initSignOut: false,
  authState: BW_AUTH_STATE.NOT_AUTHENTICATED,
  cognitoUser: {},
  emailMissing: false,
  error: {}
}

export const AUTH_PROFILE_DISPATCH = {
  BW_AUTH_STATE: 'authState',
  COGNITO_USER: 'cognitoUser',
  ERROR: 'error'
}
