import {
  extractInputValueAndError,
  mergeToNewObject
} from 'src/libs/qb-brand-web-components'

import { CASHBACK_ACTION } from '../../../constants/actions'

export const cashbackProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS:
    case CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_BY_SEARCH:
      const { loading, loadingBrand, page } = payload
      return mergeToNewObject(state, {
        loading: loading,
        loadingBrand: loadingBrand,
        page
      })

    case CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_SUCCESS:
    case CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_BY_SEARCH_SUCCESS:
      const newCashbackBrands = payload.content || payload
      const cashbackBrands =
        state.page > 0
          ? [...state.cashbackBrands, ...newCashbackBrands]
          : newCashbackBrands
      return mergeToNewObject(state, {
        cashbackBrands,
        loading: false,
        loadingTx: false,
        content: newCashbackBrands
      })

    case CASHBACK_ACTION.ON_GET_CASHBACK_FILTER_CATEGORIES_SUCCESS:
      return mergeToNewObject(state, {
        filterCategories: payload
      })

    case CASHBACK_ACTION.ON_GET_CASHBACK_BRAND_DETAILS:
      return mergeToNewObject(state, {
        loadingDetails: true
      })

    case CASHBACK_ACTION.ON_GET_CASHBACK_BRAND_DETAILS_SUCCESS:
      return mergeToNewObject(state, {
        selectedCashbackBrand: payload,
        loadingDetails: false
      })

    case CASHBACK_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case CASHBACK_ACTION.CHANGE_INPUT_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case CASHBACK_ACTION.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  cashbackBrands: [],
  content: [],
  filterCategories: [],
  selectedCashbackBrand: '',
  searchTermTemp: '',
  searchTerm: '',
  loading: false,
  loadingBrand: false,
  loadingDetails: false,
  page: 0,
  totalPages: 0,
  error: {}
}

export const CASHBACK_PROFILE_DISPATCH = {
  SEARCH_TERM: 'searchTerm',
  SEARCH_TERM_TEMP: 'searchTermTemp',
  SELECTED_CASHBACK_BRAND: 'selectedCashbackBrand',
  LOADING_BRAND: 'loadingBrand'
}
