import React, { memo } from 'react'

import SignupForm from './SignupForm'

const Signup = ({
  signupProfile,
  dispatchAction,
  onSignupSubmit,
  onSocialMediaLogin,
  t
}) => {
  return (
    <SignupForm
      signupProfile={signupProfile}
      onFormSubmit={onSignupSubmit}
      dispatchAction={dispatchAction}
      onSocialMediaLogin={onSocialMediaLogin}
      t={t}
    />
  )
}

export default memo(Signup)
