import React, { memo } from 'react'
import { BWFooter } from 'src/libs/qb-brand-web-components'
import { useTranslation } from 'react-i18next'

import CopyrightTextWrapper from 'wrappers/CopyrightTextWrapper'
import PoweredByWrapper from 'wrappers/poweredBy/PoweredByWrapper'

const Footer = ({
  customCss,
  links,
  noPoweredBy,
  linkColor,
  multiLanguage: { languageLink, selectedLanguage, renderLanguagePopup }
}) => {
  const { t } = useTranslation()
  return (
    <BWFooter
      customCss={customCss}
      linkColor={linkColor}
      languageLink={languageLink}
      links={links}
    >
      <CopyrightTextWrapper fontSize={12} />
      {!noPoweredBy && <PoweredByWrapper />}
      {renderLanguagePopup && renderLanguagePopup(t, selectedLanguage)}
    </BWFooter>
  )
}

export default memo(Footer)
