import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { formatDate } from 'src/libs/qb-brand-web-components'
import Backend from 'i18next-http-backend'

import { getSupportedLanguages } from '../util/translation.helpers'

const DETECTION_OPTIONS = {
  order: ['cookie', 'localStorage', 'navigator'],
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',
  caches: ['localStorage', 'cookie'],
  checkWhitelist: true
}

const supportedLanguages = getSupportedLanguages()

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      requestOptions: {
        cache: 'no-store'
      }
    },
    detection: DETECTION_OPTIONS,
    fallbackLng: supportedLanguages[0],
    ns: ['default', 'brand'],
    defaultNS: 'default',
    supportedLngs: supportedLanguages,
    whitelist: supportedLanguages,
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: formatDate
    },
    react: {
      useSuspense: false
    }
  })

export default i18n
