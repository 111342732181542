import React, { memo } from 'react'
import {
  BWText,
  CSS_FLEX_ITEMS_CENTER,
  getColorByLuminosity
} from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'

const NftChip = ({ label = 'NFT', customCss }) => {
  return (
    <NftChipContainer css={customCss}>
      <BWText label={label} size={12} weight={800} alignCenter />
    </NftChipContainer>
  )
}

export default memo(NftChip)

const NftChipContainer = styled.div`
  ${({ theme: { colors } }) => `
    color: ${getColorByLuminosity(colors.main, colors)};
    background-color: ${colors.white};
  `};
  border-radius: 5px;
  padding: 8px 10px;
  height: 17px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
  ${CSS_FLEX_ITEMS_CENTER};
`
