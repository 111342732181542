export const FACEBOOK_APP_SETTINGS_URL =
  'https://www.facebook.com/settings?tab=applications&ref=settings'
export const GOOGLE_MAPS_URL = 'www.google.com/maps/search/?api=1'
export const UNSUBSCRIBE_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScjz79NNBzvpKK58uSSgiD6rTCkfOpUPuBZVmuoC4_rp0rEPA/viewform'
export const QIIBEE_PRIVACY_POLICY_URL =
  'https://www.qiibee.com/privacy-policy/'

export const NAVIGATION_TARGET_BLANK = '_blank'

export const DYNAMIC_PAGE_REDIRECT_PATHS = {
  REDEEM: 'redeem',
  VIEW_SHOP: 'view-shop',
  TRUTHBRUSH: 'truthbrush',
  Z_DENTAL: 'z-dental'
}

export const NFT_MEMBERSHIPS_BASE_URL = 'https://nft-memberships.qiibee.com/'

export const FREE_IP_API_URL = 'https://freeipapi.com/api/json'
