import { css } from '@emotion/react'
import {
  CSS_FULL_HEIGHT,
  CSS_GRID,
  CSS_JUSTIFY_CONTENT_CENTER,
  cssGap,
  mq,
  SIZE_UNIT
} from 'src/libs/qb-brand-web-components'

import customConfig from 'customConfig'

const { fontFace, fontFamily } = customConfig
export const globalStyles = (theme) => css`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  ${fontFace};
  html {
    box-sizing: border-box;
    ${CSS_FULL_HEIGHT};
  }
  body,
  input,
  textarea,
  button {
    font-family: ${fontFamily};
    color: ${theme.colors?.liteBlack};
  }
  body {
    box-sizing: border-box;
    ${CSS_FULL_HEIGHT};
    background-color: ${theme.colors?.liteWhite};
    overflow: hidden;
    word-break: break-word;
  }
  #root {
    ${CSS_FULL_HEIGHT};
    overflow: auto;
    margin: auto;
    ${CSS_GRID};
    ${cssGap(SIZE_UNIT.SM)};
    padding: 10px 10px 0 10px;
    ${mq(SIZE_UNIT.MD)} {
      ${CSS_JUSTIFY_CONTENT_CENTER};
      grid-template-areas: 'left left left main main main main main main right right right';
      width: auto;
      grid-template-columns: repeat(12, 60px);
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
