import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { formatMultipleNftsPerToken } from 'wrappers/formatMultipleNftsPerToken'
import {
  MAIN_PROFILE_ACTION,
  NFT_ACTION,
  SELL_NFT_ACTION,
  TOKENS_ACTION,
  TRANSFER_NFT_ACTION
} from '../../../constants/actions'
import { NFT_ACTIONS_STEPS } from '../../../constants/nft'
import { DEFAULT_CONTAINER_STATE } from '../../../constants/containerStates'

export const nftProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      return mergeToNewObject(state, {
        loading: false,
        ...extractMainData(payload)
      })

    case MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS_SUCCESS:
      const { nfts } = payload
      return mergeToNewObject(state, {
        nfts: formatMultipleNftsPerToken(nfts),
        loading: false
      })

    case TOKENS_ACTION.ON_GET_NFT_TOKENS_SUCCESS:
      return mergeToNewObject(state, { nftsForRedemption: payload.data })

    case SELL_NFT_ACTION.GET_LISTED_NFTS_SUCCESS:
      return mergeToNewObject(state, {
        nftsForSale: payload
      })

    case SELL_NFT_ACTION.GET_LISTED_NFT_BY_ID_SUCCESS:
      return mergeToNewObject(state, {
        nftForSale: payload,
        nftActionState: payload && NFT_ACTIONS_STEPS.CANCEL_LISTING
      })

    case SELL_NFT_ACTION.LIST_NFT_FOR_SALE_SUCCESS:
      return mergeToNewObject(state, {
        nftForSale: payload,
        nftActionState: NFT_ACTIONS_STEPS.CANCEL_LISTING,
        nftPopupState: NFT_ACTIONS_STEPS.NFT_LISTED,
        loading: true
      })

    case SELL_NFT_ACTION.LIST_NFT_FOR_SALE_FAILED:
      return mergeToNewObject(state, {
        nftForSale: {},
        nftActionState: '',
        nftPopupState: DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG,
        loading: false
      })

    case NFT_ACTION.CHANGE_NFT_ACTION_STATE:
      return mergeToNewObject(state, {
        nftActionState: payload
      })

    case NFT_ACTION.RESET_REFRESH_MEMBERSHIP_STATE:
      return mergeToNewObject(state, {
        isRefreshedMembership: false
      })

    case SELL_NFT_ACTION.CANCEL_NFT_LISTING_SUCCESS:
      return mergeToNewObject(state, {
        nftActionState: NFT_ACTIONS_STEPS.SELL
      })

    case TRANSFER_NFT_ACTION.TRANSFER_NFT_SUCCESS:
      return mergeToNewObject(state, {
        nftPopupState: NFT_ACTIONS_STEPS.TRANSFER_CONFIRMED,
        loading: true
      })

    case TRANSFER_NFT_ACTION.TRANSFER_NFT_FAILED:
      return mergeToNewObject(state, {
        nftPopupState: DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG
      })

    case NFT_ACTION.RESET_STATE:
      return mergeToNewObject(state, {
        nftPopupState: DEFAULT_CONTAINER_STATE.NONE
      })

    case NFT_ACTION.BUY_NFT_SUCCESS:
      return mergeToNewObject(state, {
        loading: true
      })

    case NFT_ACTION.GET_NFT_CLAIMS_SUCCESS:
      const { claim } = payload
      return mergeToNewObject(state, {
        nftClaimCount: claim?.count || 0
      })

    case NFT_ACTION.GET_NFT_CLAIMS_FAILED:
      return mergeToNewObject(state, {
        nftClaimCount: 0
      })

    case NFT_ACTION.ON_GET_EXCLUSIVE_CONTENT:
    case NFT_ACTION.ON_REFRESH_MEMBERSHIP:
      return mergeToNewObject(state, {
        exclusiveContentProcessing: true,
        isRefreshedMembership: false
      })

    case NFT_ACTION.ON_GET_EXCLUSIVE_CONTENT_SUCCESS:
      return mergeToNewObject(state, {
        dynamicExclusiveContent: payload,
        exclusiveContentProcessing: false
      })

    case NFT_ACTION.ON_GET_EXCLUSIVE_CONTENT_FAILED:
      return mergeToNewObject(state, {
        exclusiveContentProcessing: false
      })

    case NFT_ACTION.ON_REFRESH_MEMBERSHIP_SUCCESS:
      return mergeToNewObject(state, {
        exclusiveContentProcessing: false,
        isRefreshedMembership: true
      })

    case NFT_ACTION.ON_REFRESH_MEMBERSHIP_FAILED:
      return mergeToNewObject(state, {
        exclusiveContentProcessing: false,
        isRefreshedMembership: false
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  nfts: [],
  nftsForRedemption: [],
  nftsForSale: [],
  nftForSale: {},
  nftActionState: NFT_ACTIONS_STEPS.MORE_ACTIONS,
  nftPopupState: DEFAULT_CONTAINER_STATE.NONE,
  nftClaimCount: '',
  loading: false,
  dynamicExclusiveContent: null,
  exclusiveContentProcessing: false,
  isRefreshedMembership: false
}

const extractMainData = ({ userBrandTokens }) => {
  const { nfts } = userBrandTokens
  if (nfts && nfts.length > 0) {
    return {
      nfts: formatMultipleNftsPerToken(nfts)
    }
  } else {
    return {}
  }
}
