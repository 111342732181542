import React, { memo } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons'
import { css } from '@emotion/react'

import { buildFieldIcon, defaultBoxStyles } from '../shared/styled/common'
import {
  cssBorderRadius,
  CSS_GRID,
  CSS_ITEMS_CENTER,
  cssFontSize,
  cssPadding,
  CSS_FULL_WIDTH
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes/units'
import BWHelpText from '../BWHelpText'
import { MESSAGE_TYPE } from '../../constants/constants'

const BWDisplay = ({
  label,
  disabled,
  help,
  success,
  error,
  noIcon,
  customCss
}) => {
  const messageType =
    (success && MESSAGE_TYPE.SUCCESS) || (error && MESSAGE_TYPE.ERROR)
  const displayIcon = (disabled || messageType) && !noIcon
  return (
    <DisplayContainer data-testid='bw-display'>
      <DisplayContent displayIcon={displayIcon} css={customCss}>
        {label}
        {displayIcon && (
          <DisplayIcon
            icon={(disabled && faLockAlt) || buildFieldIcon(messageType)}
          />
        )}
      </DisplayContent>
      <BWHelpText help={help} success={success} error={error} />
    </DisplayContainer>
  )
}

export default memo(BWDisplay)

const DisplayContainer = styled.div`
  ${CSS_FULL_WIDTH};
`

const DisplayContent = styled.div`
  ${cssFontSize(SIZE_UNIT.MD)};
  ${cssBorderRadius(SIZE_UNIT.SM)};
  ${CSS_GRID};
  ${CSS_ITEMS_CENTER}
  ${cssPadding(SIZE_UNIT.MD1, SIZE_UNIT.MD)};
  ${({ theme, displayIcon }) => [
    defaultBoxStyles(theme.colors),
    gridColumns(displayIcon)
  ]};
`

const DisplayIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.gray};
  ${cssFontSize(SIZE_UNIT.MD)};
`

const gridColumns = (displayIcon) => css`
  grid-template-columns: 1fr ${displayIcon && '16px'};
`
