import { Auth } from 'aws-amplify'

import { appSignalInstance, APP_CONFIG } from '../../constants/appConfig'
import { formatUserDeviceDetails, stringifyJson } from './app.helpers'

const sendCustomError = (error) => {
  const instance = appSignalInstance()
  if (instance && error) {
    instance.sendError(error)
  }
}

function CustomException({ name, ...restAttrs }) {
  const errorMessage = stringifyJson({
    brand_id: APP_CONFIG.BRAND_ID,
    ...restAttrs,
    ...formatUserDeviceDetails()
  })
  const error = new Error(errorMessage)
  error.name = name
  return error
}

const logToAppSignal = async (name, message, attrs) => {
  try {
    const user = await Auth.currentUserInfo()
    const user_id = user?.attributes?.sub
    const email = user?.attributes?.email
    throw CustomException({
      name,
      message,
      ...(user && { user_id, email }),
      ...attrs
    })
  } catch (error) {
    sendCustomError(error)
  }
}

const logAuthErrors = (message, error, params) => {
  logToAppSignal(error?.code, message, { error, ...params })
}

export { logToAppSignal, logAuthErrors }
