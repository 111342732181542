export const POP = 'POP'

export const DEFAULT_COUNTRY_VALUE = 'AAA'

export const SOCIAL_MEDIA = {
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google'
}

export const MAX_PERSONAL_MESSAGE_INPUT_LENGTH = 240
export const MAX_MILES_EXCHANGE_ACCOUNT_NUMBER_INPUT_LENGTH = 32
export const MAX_POINTS_EXCHANGE_ACCOUNT_NUMBER_INPUT_LENGTH = 64
export const MIN_BRAND_ACCOUNT_NUMBER_LENGTH = 5

export const TRANSACTION_WAIT_TIME = 5000

export const STATIC_LOGO_URL = 'https://static.qiibee.com/logos/'
export const BUY_POINTS_CONVERSION_FEE = 1

export const ALLCOLIBRI_IMPACT_REPORT_URL =
  'https://www.allcolibri.com/impact/?p=qiibee'
