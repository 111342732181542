import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { AUTH_ACTION, EARN_VIR_ACTION } from '../../../constants/actions'

export const earnProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case EARN_VIR_ACTION.RESET_STATE:
      return INITIAL_STATE

    case EARN_VIR_ACTION.GET_PROFILE:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case EARN_VIR_ACTION.GET_PROFILE_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        ...payload
      })

    case EARN_VIR_ACTION.DISCONNECT_ACCOUNT:
      return mergeToNewObject(state, {
        isDisconnecting: true
      })

    case EARN_VIR_ACTION.DISCONNECT_ACCOUNT_SUCCESS:
      return INITIAL_STATE

    case EARN_VIR_ACTION.DISCONNECT_ACCOUNT_FAILED:
      return mergeToNewObject(state, {
        isDisconnecting: false
      })

    case EARN_VIR_ACTION.CONNECT_ACCOUNT:
      return mergeToNewObject(state, {
        refreshAuth: null,
        isDisconnecting: true
      })

    case AUTH_ACTION.ON_REFRESHED_AUTH:
      return mergeToNewObject(state, {
        refreshAuth: payload
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  isProcessing: false,
  isDisconnecting: false,
  refreshAuth: null,
  user: {
    screenName: '',
    xId: ''
  },
  message: '',
  virEarned: { views: 0, likes: 0, replies: 0, retweets: 0, quotes: 0 },
  virEarnedTotal: 0
}
