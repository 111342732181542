import React, { memo } from 'react'
import { BWPanelContent, cssCustomGap } from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'

import Button from '../shared/button/Button'
import customConfig from 'customConfig'

const OverviewEnterCode = ({
  howToEarnLink,
  howDoesItWorkButton,
  t,
  onEnterRewardCodeClick
}) => {
  return (
    <CustomGridContent>
      <Button
        label={t(customConfig.overviewEnterRewardCodeLabel)}
        onClick={onEnterRewardCodeClick}
      />
      {howToEarnLink}
      {howDoesItWorkButton}
    </CustomGridContent>
  )
}

export default memo(OverviewEnterCode)

const CustomGridContent = styled(BWPanelContent)`
  ${cssCustomGap(15)};
`
