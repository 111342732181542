import React, { memo } from 'react'
import { BWPanel } from 'src/libs/qb-brand-web-components'

const Panel = ({ children, ...restProps }) => {
  return (
    <BWPanel show {...restProps}>
      {children}
    </BWPanel>
  )
}

export default memo(Panel)
