import { mergeMap, catchError, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import { ENGRAVING_ACTION } from '../../constants/actions'

const handleGetEngravings = (action$) =>
  action$.pipe(
    ofType(ENGRAVING_ACTION.ON_GET_ENGRAVINGS),
    mergeMap((_) =>
      apiService.getEngravings().pipe(
        tap((data) => logger(data)),
        map(({ response }) => ({
          type: ENGRAVING_ACTION.ON_GET_ENGRAVINGS_SUCCESS,
          payload: response
        })),
        catchError((err) =>
          of({ type: ENGRAVING_ACTION.ON_GET_ENGRAVINGS_FAILED, payload: err })
        )
      )
    )
  )

export const engravingsEpic = combineEpics(handleGetEngravings)
