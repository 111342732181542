import React, { memo } from 'react'
import {
  BWPanelHeader,
  BWInput,
  requiredRule,
  BW_COLOR_ACCENT
} from 'src/libs/qb-brand-web-components'

import { REWARDS_PROFILE_DISPATCH } from '../../store/reducers/rewards/rewards'
import rewardIcon from 'wrappers/rewardIcon'
import customConfig from 'customConfig'

const ReceiveReward = ({
  dispatchAction,
  rewardsProfile: { error, rewardCode },
  onQrCodeClick,
  t,
  qrCodeEnabled,
  noPanelIcon,
  iconColor,
  noHeader
}) => {
  return (
    <>
      {!noHeader && (
        <BWPanelHeader
          title={t(customConfig.receiveYourRewardLabel)}
          description={t(customConfig.enterYourRewardCodeLabel)}
          icon={!noPanelIcon && rewardIcon()}
          color={iconColor || BW_COLOR_ACCENT}
        />
      )}
      <BWInput
        qrCodeEnabled={qrCodeEnabled}
        onQrCodeClick={onQrCodeClick}
        label={t(customConfig.enterRewardCodeLabel)}
        noStatusIcon
        alignCenter
        error={t(error[REWARDS_PROFILE_DISPATCH.REWARD_CODE])}
        value={rewardCode}
        validate={[requiredRule]}
        onChange={(value) =>
          dispatchAction(REWARDS_PROFILE_DISPATCH.REWARD_CODE, value)
        }
      />
    </>
  )
}

export default memo(ReceiveReward)
