import React from 'react'

import { SOCIAL_MEDIA } from '../../constants/app'
import Facebook from '../../components/socialMediaButtons/Facebook'

const FacebookContainer = ({ onSocialMediaLogin, ...restProps }) => {
  const handleSignIn = () => {
    onSocialMediaLogin(SOCIAL_MEDIA.FACEBOOK)
  }

  return <Facebook onClick={handleSignIn} {...restProps} />
}

export default FacebookContainer
