export const SIZE_UNIT = {
  NONE: 'none',
  XS2: 'xs2',
  XS: 'xs',
  SM1: 'sm1',
  SM: 'sm',
  MD1: 'md1',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XL2: 'xl2',
  XL3: 'xl3',
  XL4: 'xl4',
  XL6: 'xl5',
  XL5: 'xl6'
}

export const breakpoints = {
  [SIZE_UNIT.SM]: 600,
  [SIZE_UNIT.MD]: 768,
  [SIZE_UNIT.LG]: 1024
}

export const spacing = {
  [SIZE_UNIT.NONE]: '0px',
  [SIZE_UNIT.XS2]: '2px',
  [SIZE_UNIT.XS]: '6px',
  [SIZE_UNIT.SM1]: '9px',
  [SIZE_UNIT.SM]: '10px',
  [SIZE_UNIT.MD1]: '14px',
  [SIZE_UNIT.MD]: '15px',
  [SIZE_UNIT.LG]: '18px',
  [SIZE_UNIT.XL]: '20px',
  [SIZE_UNIT.XL2]: '30px',
  [SIZE_UNIT.XL4]: '100px'
}

export const fontSizes = {
  [SIZE_UNIT.XS2]: '10px',
  [SIZE_UNIT.XS]: '12px',
  [SIZE_UNIT.SM]: '14px',
  [SIZE_UNIT.MD]: '16px',
  [SIZE_UNIT.LG]: '18px',
  [SIZE_UNIT.XL]: '22px',
  [SIZE_UNIT.XL2]: '40px',
  [SIZE_UNIT.XL3]: '50px',
  [SIZE_UNIT.XL4]: '60px',
  [SIZE_UNIT.XL5]: '30px',
  [SIZE_UNIT.XL6]: '120px'
}

export const fontWeight = {
  [SIZE_UNIT.NONE]: 'normal',
  [SIZE_UNIT.SM]: 'lighter',
  [SIZE_UNIT.MD]: '600',
  [SIZE_UNIT.LG]: 'bold',
  [SIZE_UNIT.XL]: 'bolder'
}
