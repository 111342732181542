import React, { memo } from 'react'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { BW_COLOR_ERROR } from 'src/libs/qb-brand-web-components'

import Popup from '../../shared/popup/Popup'

const SomethingWentWrong = ({ onSubmit, t }) => (
  <Popup
    title={t('common.oops')}
    description={t('error.something-went-wrong')}
    icon={faTimesCircle}
    color={BW_COLOR_ERROR}
    submitLabel={t('common.try-again')}
    onFormSubmit={onSubmit}
  />
)

export default memo(SomethingWentWrong)
