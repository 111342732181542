import React from 'react'

import { SOCIAL_MEDIA } from '../../constants/app'
import Google from '../../components/socialMediaButtons/Google'

const GoogleContainer = ({ onSocialMediaLogin, ...restProps }) => {
  const handleSignIn = () => {
    onSocialMediaLogin(SOCIAL_MEDIA.GOOGLE)
  }

  return <Google onClick={handleSignIn} {...restProps} />
}

export default GoogleContainer
