import React, { memo } from 'react'
import { faQrcode } from '@fortawesome/pro-light-svg-icons'
import QrReader from 'react-qr-reader'
import styled from '@emotion/styled'

import {
  cssBorderRadius,
  CSS_FULL_HEIGHT,
  CSS_FULL_WIDTH
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes/units'
import BWPopup from '../BWPopup'
import { BW_COLOR_ACCENT } from '../../constants/theme'

const BWScanQrCode = ({
  onScan,
  onError,
  color = BW_COLOR_ACCENT,
  ...restProps
}) => {
  return (
    <BWPopup show icon={faQrcode} color={color} {...restProps}>
      <QrCodeContainer
        onError={onError}
        onScan={onScan}
        facingMode='environment'
        delay={300}
      />
    </BWPopup>
  )
}

export default memo(BWScanQrCode)

const QrCodeContainer = styled(QrReader)`
  height: 270px;
  width: 270px;
  div {
    border: none !important;
    box-shadow: none !important;
  }
  video {
    ${CSS_FULL_HEIGHT};
    ${CSS_FULL_WIDTH};
    object-fit: cover !important;
    border: 2px solid ${({ theme: { colors } }) => colors.accent};
    ${cssBorderRadius(SIZE_UNIT.SM)};
  }
`
