import React, { memo } from 'react'
import { BWPageContent } from 'src/libs/qb-brand-web-components'
import { faUserCircle } from '@fortawesome/pro-light-svg-icons'

import ChangePersonalInfo from './ChangePersonalInfo'
import ChangeEmailAddress from './ChangeEmailAddress'
import ChangePassword from './ChangePassword'
import AccountNumber from './AccountNumber'
import DisplayEmailAddress from './DisplayEmailAddress'
import { SOCIAL_MEDIA } from '../../constants/app'
import ShowMoreInformationWrapper from 'wrappers/ShowMoreInformationWrapper'
import PageHeaderWrapper from 'wrappers/PageHeaderWrapper'
import ChangeCountry from './ChangeCountry'
import customConfig from 'customConfig'

const UserAccount = ({
  accountProfile,
  dispatchAction,
  updatePersonalInfo,
  changeEmailAddress,
  changePassword,
  changeCountry,
  onCopyAccountNumber,
  onViewQRCode,
  signedUpVia,
  t,
  manageExchangeAccounts
}) => {
  return (
    <>
      <PageHeaderWrapper
        label={t(customConfig.myAccountLabel)}
        icon={faUserCircle}
        header
      />
      <BWPageContent>
        <AccountNumber
          accountProfile={accountProfile}
          onCopyAccountNumber={onCopyAccountNumber}
          onViewQRCode={onViewQRCode}
          t={t}
        />
        <ChangePersonalInfo
          accountProfile={accountProfile}
          dispatchAction={dispatchAction}
          onFormSubmit={updatePersonalInfo}
          t={t}
        />
        {signedUpVia === SOCIAL_MEDIA.GOOGLE ? (
          <DisplayEmailAddress
            email={accountProfile.email}
            signedUpVia={signedUpVia}
            t={t}
          />
        ) : (
          <ChangeEmailAddress
            accountProfile={accountProfile}
            dispatchAction={dispatchAction}
            onFormSubmit={changeEmailAddress}
            t={t}
          />
        )}
        {!signedUpVia && (
          <ChangePassword
            accountProfile={accountProfile}
            dispatchAction={dispatchAction}
            onFormSubmit={changePassword}
            t={t}
          />
        )}
        <ChangeCountry
          accountProfile={accountProfile}
          dispatchAction={dispatchAction}
          onFormSubmit={changeCountry}
          t={t}
        />
        {manageExchangeAccounts}
        <ShowMoreInformationWrapper t={t} />
      </BWPageContent>
    </>
  )
}

export default memo(UserAccount)
