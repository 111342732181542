import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  // HOME_URL,
  FORGOT_PASSWORD_URL,
  LOG_IN_URL,
  RESET_PASSWORD_URL,
  REWARD_URL,
  SIGNUP_URL,
  WELCOME_URL,
  PAGE_NOT_FOUND_URL
} from './constants/navigation'
import SignupContainer from './containers/SignupContainer'
import RewardContainer from './containers/rewardContainer'
import LoginContainer from './containers/LoginContainer'
import ForgotPasswordContainer from './containers/ForgotPasswordContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer'
import PageNotFoundContainer from './containers/PageNotFoundContainer'
import brandConfig from 'brandConfig'
import HomeContainerWrapper from 'wrappers/containers/HomeContainerWrapper'

const WelcomeContainer = lazy(() => import('./containers/WelcomeContainer'))

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={REWARD_URL} element={<RewardContainer />} />
      <Route path={SIGNUP_URL} element={<SignupContainer />} />
      <Route path={LOG_IN_URL} element={<LoginContainer />} />
      <Route path={FORGOT_PASSWORD_URL} element={<ForgotPasswordContainer />} />
      <Route path={RESET_PASSWORD_URL} element={<ResetPasswordContainer />} />
      {!brandConfig.features.disableWelcomeScreen && (
        <Route path={WELCOME_URL} element={<WelcomeContainer />} />
      )}
      <Route path={PAGE_NOT_FOUND_URL} element={<PageNotFoundContainer />} />
      <Route path={'*'} element={<HomeContainerWrapper />} />
    </Routes>
  )
}

export default AppRoutes
