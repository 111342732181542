import React, { memo } from 'react'
import {
  BWText,
  BWInput,
  BWPanelContent,
  passwordRule,
  requiredRule
} from 'src/libs/qb-brand-web-components'

import { ACCOUNT_PROFILE_DISPATCH } from '../../store/reducers/account/account'
import Button from '../shared/button/Button'
import Panel from '../shared/panel/Panel'

const ChangePassword = ({
  onFormSubmit,
  dispatchAction,
  accountProfile: { error, success, oldPassword, newPassword },
  t
}) => {
  return (
    <Panel onFormSubmit={onFormSubmit} as='form'>
      <BWPanelContent>
        <BWText
          label={t('user-account.change-password')}
          size={18}
          weight={700}
          alignCenter
        />
        <BWInput
          label={t('user-account.old-password')}
          eyePassword
          error={t(error[ACCOUNT_PROFILE_DISPATCH.OLD_PASSWORD])}
          success={t(success[ACCOUNT_PROFILE_DISPATCH.OLD_PASSWORD])}
          value={oldPassword}
          validate={[requiredRule, passwordRule]}
          onChange={(value) =>
            dispatchAction(ACCOUNT_PROFILE_DISPATCH.OLD_PASSWORD, value)
          }
        />
        <BWInput
          label={t('user-account.new-password')}
          eyePassword
          error={t(error[ACCOUNT_PROFILE_DISPATCH.NEW_PASSWORD])}
          success={t(success[ACCOUNT_PROFILE_DISPATCH.NEW_PASSWORD])}
          value={newPassword}
          validate={[requiredRule, passwordRule]}
          onChange={(value) =>
            dispatchAction(ACCOUNT_PROFILE_DISPATCH.NEW_PASSWORD, value)
          }
        />
      </BWPanelContent>
      <Button label={t('common.save')} type='submit' />
    </Panel>
  )
}

export default memo(ChangePassword)
