import React, { memo, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-regular-svg-icons'

import {
  cssBorderRadius,
  cssFontSize,
  cssPadding,
  cssBoxShadow,
  cssFontWeight,
  CSS_CURSOR_POINTER,
  CSS_ALIGN_ITEMS_CENTER,
  CSS_FLEX,
  CSS_HEIGHT_50,
  CSS_FULL_WIDTH,
  CSS_ITEMS_CENTER,
  CSS_BOX_SIZING_BORDER_BOX,
  CSS_TEXT_UPPERCASE,
  CSS_FULL_HEIGHT,
  CSS_POSITION_RELATIVE,
  CSS_POSITION_ABSOLUTE
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes'
import { getColorByLuminosity } from '../../util'
import BWText from '../BWText'

const BWTopBar = ({
  title,
  brandLogo,
  onBrandLogoClick,
  children,
  invert,
  invertBg,
  name
}) => {
  const menuRef = useRef(null)

  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleBrandLogoClick = () => {
    onBrandLogoClick()
    closeNavMenu()
  }

  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      closeNavMenu()
    }
  }

  const closeNavMenu = () => {
    setShowMenu(false)
  }

  return (
    <TopBarContainer
      data-testid='bw-top-bar'
      ref={menuRef}
      invert={invert}
      invertBg={invertBg}
    >
      <TopBarHeader open={showMenu} invertBg={invertBg}>
        <TopBarHeaderTitle>
          <BrandHeaderContainer onClick={handleBrandLogoClick}>
            {brandLogo ? <BrandLogoContainer src={brandLogo} /> : title}
          </BrandHeaderContainer>
        </TopBarHeaderTitle>
        {name && (
          <TopBarHeaderName>
            <BWText label={name} />
          </TopBarHeaderName>
        )}
        <DrawerMenuIcon onClick={() => setShowMenu(!showMenu)} icon={faBars} />
      </TopBarHeader>
      {showMenu && (
        <TopBarMenuContainer onClick={closeNavMenu} invertBg={invertBg}>
          {children}
        </TopBarMenuContainer>
      )}
    </TopBarContainer>
  )
}

export default memo(BWTopBar)

const TopBarContainer = styled.div`
  ${CSS_FULL_WIDTH};
  ${CSS_POSITION_RELATIVE};
  ${({ theme: { colors }, invert, invertBg }) =>
    colors && menuItemStyles(colors, invert, invertBg)};
`

const TopBarHeaderTitle = styled.div`
  ${cssFontSize(SIZE_UNIT.LG)};
  ${cssFontWeight(SIZE_UNIT.XL)};
  ${CSS_FLEX};
  ${CSS_TEXT_UPPERCASE};
  height: 35px;
`

const TopBarHeaderName = styled.div`
  width: 100%;
  text-align: center;
`

const TopBarHeader = styled.div`
  ${cssPadding(SIZE_UNIT.NONE, SIZE_UNIT.MD)};
  ${cssBorderRadius(SIZE_UNIT.SM)};
  ${CSS_FLEX};
  justify-content: space-between;
  ${CSS_ITEMS_CENTER};
  ${CSS_HEIGHT_50};
  ${({ theme: { colors }, invertBg }) => bgColorStyles(colors, invertBg)};
  ${({ open, theme }) => open && topBarOpenStyles(theme.colors)};
`

const TopBarMenuContainer = styled.ul`
  ${cssBorderRadius(SIZE_UNIT.SM)};
  ${CSS_FULL_WIDTH};
  ${cssPadding(SIZE_UNIT.NONE, SIZE_UNIT.MD)};
  ${CSS_BOX_SIZING_BORDER_BOX};
  ${CSS_POSITION_ABSOLUTE};
  ${({ theme: { colors }, invertBg }) => bgColorStyles(colors, invertBg)};
  ${({ theme }) => cssBoxShadow(theme.colors, 2)};
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: 20;
`

const DrawerMenuIcon = styled(FontAwesomeIcon)`
  ${CSS_CURSOR_POINTER};
  ${cssFontSize(SIZE_UNIT.XL)};
`

const BrandLogoContainer = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const BrandHeaderContainer = styled.div`
  ${CSS_CURSOR_POINTER};
  ${CSS_FLEX};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${CSS_FULL_HEIGHT};
`

const topBarOpenStyles = (colors) => css`
  ${cssBoxShadow(colors)};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`

const bgColorStyles = (colors, invertBg) => css`
  background-color: ${invertBg ? colors?.main : colors?.liteWhite};
`

const menuItemStyles = (colors, invert, invertBg) => {
  const color = invert
    ? colors.accent
    : invertBg
      ? colors.liteWhite
      : colors.main
  const hoverColor = getColorByLuminosity(
    !invert ? colors.accent : colors.main,
    colors
  )
  return css`
    color: ${color};
    ul > *:hover {
      color: ${hoverColor};
    }
  `
}
