import React, { memo } from 'react'

import HowManyPoints from '../../../components/welcome/HowManyPoints'

const HowToEarnRewardsWrapper = ({ tokenSymbol }) => {
  return (
    <HowManyPoints
      data={[{ i18nKey: 'brand:earn_rewards.label1', point: 1, tokenSymbol }]}
    />
  )
}

export default memo(HowToEarnRewardsWrapper)
