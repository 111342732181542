import { BW_LANGUAGE_LABELS } from '../constants/translation'

const getLanguageLabelsByPref = (languages) => {
  if (languages && languages.length > 0) {
    const filteredLanguages = {}
    languages.forEach((language) => {
      filteredLanguages[language] = BW_LANGUAGE_LABELS[language]
    })
    return filteredLanguages
  }
  return BW_LANGUAGE_LABELS
}

export { getLanguageLabelsByPref }
