import {
  catchError,
  map,
  tap,
  mapTo,
  switchMap,
  delay,
  repeat,
  mergeMap,
  filter
} from 'rxjs/operators'
import { of, combineLatest } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import { extractMainProfile } from '../../util/epics.helpers'
import brandConfig from 'brandConfig'

import {
  AUTH_ACTION,
  ACCOUNT_ACTION,
  MAIN_PROFILE_ACTION,
  EXCHANGE_ACTION,
  POINTS_REWARD_ACTION,
  AUTH_LOGIN_ACTION,
  TOKENS_ACTION,
  NFT_ACTION,
  TRANSFER_NFT_ACTION,
  SELL_NFT_ACTION
} from '../../constants/actions'

import {
  extractCognitoUserTermsAccepted,
  extractCognitoUserUsername,
  wrapUserAccessToken
} from '../../util/auth.helpers'
import i18n from '../../i18n'
import {
  formatExchangeWhitelist,
  formatMembershipsToObject
} from '../../util/pointsExchange.helpers'
import { DISABLE_CAMPAIGN_CHECK_NUMBER_OF_TX } from '../../constants/transactions'
import { commonParser } from '../../util/apiParser.helpers'
import { checkIsDisabledPointsBurn } from '../../util/account.helpers'
import { TOKEN_WHITELIST_TYPE } from '../../constants/token'
import { enforceShouldFetchNftUrlIfEligible } from '../../util/nft.helpers'
import { formatBrandTokens, formatTokens } from '../../util/token.helpers'

// MAIN - AUTH RELATED -> START MAIN LOADING
const handleStartMainLoad = (action$) =>
  action$.pipe(
    ofType(
      AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS,
      AUTH_ACTION.ON_GET_CURRENT_USER_SUCCESS
    ),
    filter(({ payload }) => extractCognitoUserTermsAccepted(payload)),
    mapTo({ type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA })
  )

// MAIN - AUTH RELATED -> START MAIN LOADING ON TERMS ACCEPTANCE
const handleStartMainLoadOnTermsAcceptance = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_ACCEPT_TERMS_SUCCESS),
    mapTo({ type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA })
  )

// MAIN PROFILE
const handleTokensDataAfterUserAccount = (action$) =>
  action$.pipe(
    ofType(
      ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_SUCCESS,
      ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS
    ),
    map(({ payload: { id, language } }) => ({
      userId: id,
      language
    })),
    tap((userAccountData) => logger(userAccountData)),
    mergeMap(({ userId, language }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTokens({ type: TOKEN_WHITELIST_TYPE.ERC_721 }, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              i18n.changeLanguage(language)
              return {
                type: TOKENS_ACTION.ON_GET_NFT_TOKENS_SUCCESS,
                payload: {
                  userId,
                  data: formatTokens(response.data)
                }
              }
            }),
            catchError((err) =>
              of({
                type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleMainLoadAfterTokensData = (action$, state$) =>
  action$.pipe(
    ofType(TOKENS_ACTION.ON_GET_NFT_TOKENS_SUCCESS),
    switchMap(({ userId }) =>
      wrapUserAccessToken((accessToken) =>
        combineLatest([
          apiService.getBrandConfig(accessToken),
          apiService.getUserBrandTokens(
            extractCognitoUserUsername(state$),
            enforceShouldFetchNftUrlIfEligible(state$),
            accessToken
          ),
          apiService.getUserMemberships(userId, accessToken),
          apiService.getExchangeWhitelist(accessToken)
        ]).pipe(
          tap((value) => logger(value)),
          map(
            ([
              brandConfigResponse,
              userBrandTokensResponse,
              exchangeMembershipsResponse,
              exchangeWhitelistResponse
            ]) => {
              const brandAppConfig = commonParser(
                brandConfigResponse.response.data
              )
              const userBrandTokens = formatBrandTokens(
                userBrandTokensResponse.response.data
              )
              const exchangeMemberships = formatMembershipsToObject(
                exchangeMembershipsResponse.response.data
              )
              const exchangeWhitelist = formatExchangeWhitelist(
                exchangeWhitelistResponse.response.data
              )
              const campaignIdsToEnablePointBurn =
                brandConfig.campaignIdsToEnablePointBurn
              if (campaignIdsToEnablePointBurn?.length) {
                return {
                  type: MAIN_PROFILE_ACTION.ON_CHECK_DISABLED_CAMPAIGN,
                  payload: {
                    brandAppConfig,
                    userBrandTokens, // -> GOES TO reducers/main/mainProfile.userBrandTokens
                    exchangeMemberships, // -> GOES TO reducers/main/mainProfile.exchangeMemberships
                    exchangeWhitelist,
                    campaignIdsToEnablePointBurn
                  }
                }
              }
              return {
                type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS,
                payload: {
                  brandAppConfig,
                  userBrandTokens, // -> GOES TO reducers/main/mainProfile.userBrandTokens
                  exchangeMemberships, // -> GOES TO reducers/main/mainProfile.exchangeMemberships
                  exchangeWhitelist,
                  isDisabledPointsBurn: false
                }
              }
            }
          ),
          catchError((err) =>
            of({
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleCheckForDisabledCampaigns = (action$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_CHECK_DISABLED_CAMPAIGN),
    mergeMap(({ payload: { campaignIdsToEnablePointBurn, ...restAttrs } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTransactions(
            {
              limit: DISABLE_CAMPAIGN_CHECK_NUMBER_OF_TX,
              offset: 0
            },
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS,
              payload: {
                ...restAttrs,
                isDisabledPointsBurn: checkIsDisabledPointsBurn(
                  campaignIdsToEnablePointBurn,
                  commonParser(response.data.transactions)
                )
              }
            })),
            catchError((err) =>
              of({
                type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetUserBrandTokensAfterPointsUpdate = (action$, state$) =>
  action$.pipe(
    ofType(
      POINTS_REWARD_ACTION.ON_RECEIVE_POINTS_FROM_CODE_SUCCESS,
      POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS_SUCCESS,
      EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_SUCCESS,
      NFT_ACTION.BUY_NFT_SUCCESS
    ),
    delay(2000),
    map((_) => extractMainProfile(state$).currentUserPoints),
    switchMap((initialUserPoints) => {
      return wrapUserAccessToken((accessToken) =>
        apiService
          .getUserBrandTokens(
            extractCognitoUserUsername(state$),
            enforceShouldFetchNftUrlIfEligible(state$),
            accessToken
          )
          .pipe(
            delay(3000),
            repeat(4),
            // If current points in the state are equal to the initialUserPoints when the querying started - keep querying the chain
            // if they are not equal, it means that we got the latest chain state and no need to repeat the querying
            filter(
              (_) =>
                initialUserPoints ===
                extractMainProfile(state$).currentUserPoints
            ),
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS_SUCCESS,
              payload: formatBrandTokens(response.data)
            })),
            catchError((err) =>
              of({
                type: MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS_FAILED,
                payload: err
              })
            )
          )
      )
    })
  )

const handleGetUserBrandTokensAfterNftEngravingSubmit = (action$, state$) =>
  action$.pipe(
    ofType(
      NFT_ACTION.ON_RECEIVE_NFT_FROM_CODE_SUCCESS,
      SELL_NFT_ACTION.LIST_NFT_FOR_SALE_SUCCESS,
      TRANSFER_NFT_ACTION.TRANSFER_NFT_SUCCESS
    ),
    delay(2000),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getUserBrandTokens(
            extractCognitoUserUsername(state$),
            enforceShouldFetchNftUrlIfEligible(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS_SUCCESS,
              payload: formatBrandTokens(response.data)
            })),
            catchError((err) =>
              of({
                type: MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

export const mainEpic = combineEpics(
  // MAIN APP LOAD
  handleStartMainLoad,
  handleStartMainLoadOnTermsAcceptance,

  // MAIN PROFILE
  handleTokensDataAfterUserAccount,
  handleMainLoadAfterTokensData,
  handleGetUserBrandTokensAfterPointsUpdate,

  // BRAND TOKENS
  handleGetUserBrandTokensAfterNftEngravingSubmit,

  // CHECK FOR DISABLED CAMPAIGNS
  handleCheckForDisabledCampaigns
)
