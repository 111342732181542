import {
  BW_LANGUAGE_EN,
  BW_LANGUAGE_IT,
  BW_COLOR_MAIN
} from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: '32vdb',
  name: '32 Via dei birrai',
  domain: '32viadeibirrai.it',
  fbAppName: '32viadeibirraireward',
  termsUrl: 'https://static.32viadeibirraireward.com/terms.pdf',
  privacyPolicyUrl: 'https://32viadeibirrai.it/en/pages/privacy-policy',
  cookiePolicyUrl:
    'https://www.iubenda.com/privacy-policy/gdpr/29186797/cookie-policy',
  faqUrl: 'https://static.32viadeibirraireward.com/faq.pdf',
  theme: {
    colors: {
      main: '#222222',
      accent: '#73C92D'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN, BW_LANGUAGE_IT],
  features: {},
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_P98pT3gT7',
    clientId: '3qd9erqhpq53nepdon9us76idc',
    poolDomain: '32viadeibirraireward.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://32viadeibirraireward.com/login'
  },
  gaTrackingId: 'UA-184596501-8',
  hotjarId: '2876148',
  prefTextColor: BW_COLOR_MAIN
}

export default brandConfig
