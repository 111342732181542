import brandConfig from 'brandConfig'

const getSupportedLanguages = () => {
  return brandConfig.supportedLanguages
}

const isMultiLanguage = () => {
  return getSupportedLanguages().length > 1
}

export { getSupportedLanguages, isMultiLanguage }
