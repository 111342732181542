import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { BWLoading } from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'

const Loading = ({ main, label }) => {
  const { t } = useTranslation()
  return (
    <BWLoading
      main={main}
      label={label ? label : t('loading.just-a-moment')}
      iconColor={brandConfig.prefIconColor}
    />
  )
}

export default memo(Loading)
