const testBrandConfig = {
  brandId: 'test_brand',
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_qO5eY0dnq',
    clientId: '7q4kehumvo1pf39e4gs915kb60',
    poolDomain: 'bwa-auth.qiibee.com',
    redirectUri: 'https://bwa.qiibee.com/login'
  },
  gaTrackingId: 'G-6PQFHXDDYW',
  googleTagManagerId: ''
}

export default testBrandConfig
