const MILES_AND_MORE_MIN_LENGTH = 9
const MILES_AND_MORE_PREFIXES = ['3330', '2220', '9922', '9920', '9999']

const isValidMileAndMoreNumber = (number) => {
  number = number && number.trim()
  if (!number) {
    return false
  }

  if (number && number.length < MILES_AND_MORE_MIN_LENGTH) {
    return false
  }
  const validPrefix = MILES_AND_MORE_PREFIXES.find(
    (prefix) => number.indexOf(prefix) === 0
  )
  if (!validPrefix) {
    return false
  }
  let sum = 0
  for (let i = number.length - 2; i >= 0; i -= 1) {
    let digit = parseInt(number[i], 10)
    if (i % 2 !== 0) {
      digit *= 2
      if (digit > 9) {
        digit = Math.floor(digit / 10) + (digit % 10)
      }
    }
    sum += digit
  }
  const mod = sum % 10
  return (mod === 0 ? 0 : 10 - mod) === parseInt(number[number.length - 1], 10)
}

const ETIHAD_CARD_NUMBER_MIN_LENGTH = 12

const isValidEtihadCardNumber = (number) => {
  number = number && number.trim()
  if (!number) {
    return false
  }

  if (number.length < ETIHAD_CARD_NUMBER_MIN_LENGTH || isNaN(number)) {
    return false
  }
  const firstDigit = number[0]
  if (!(firstDigit >= 1 && firstDigit <= 9)) {
    return false
  }
  const setOfDigits = parseInt(number.substr(0, 11))
  const calculateDigits = setOfDigits / 7
  if (!Number.isInteger(calculateDigits)) {
    const multiplyDigits = parseInt(calculateDigits) * 7
    const checkDigit = setOfDigits - multiplyDigits
    const lastDigit = number[11]
    if (checkDigit !== parseInt(lastDigit)) {
      return false
    }
  }
  return true
}

export { isValidMileAndMoreNumber, isValidEtihadCardNumber }
