import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { STAKING_ACTION } from '../../constants/actions'
import apiService from '../../services/api'
import { commonParser } from '../../util/apiParser.helpers'

const handleGetWallet = (action$) =>
  action$.pipe(
    ofType(STAKING_ACTION.ON_GET_WALLET),
    mergeMap(({ payload: { address } }) =>
      apiService.getWallet(address).pipe(
        map(({ response }) => ({
          type: STAKING_ACTION.ON_GET_WALLET_SUCCESS,
          payload: commonParser(response)
        })),
        catchError((err) =>
          of({
            type: STAKING_ACTION.ON_GET_WALLET_FAILED,
            payload: err
          })
        )
      )
    )
  )

export const stakingEpic = combineEpics(handleGetWallet)
