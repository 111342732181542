import { useState, useCallback } from 'react'

const useSubmitAction = (resubmitTime, action = null) => {
  const [startTime, setStartTime] = useState(currentTime())
  return useCallback(
    (e, ...rest) => {
      e.preventDefault()
      const elapsedTime = currentTime() - startTime
      setStartTime(currentTime())
      if (elapsedTime > resubmitTime && action) action(e, ...rest)
    },
    [startTime, action]
  )
}

const currentTime = () => {
  return new Date().getTime()
}

export { useSubmitAction }
