import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import customConfig from 'customConfig'
import { redeemPointsParams } from '../utils/link.helpers'

const overviewPointsLink = (t) => {
  return mergeToNewObject(redeemPointsParams(), {
    label: t(customConfig.redeemYourPointsLabel, {
      pointsLabel: t(customConfig.pointsLabel)
    })
  })
}
export default overviewPointsLink
