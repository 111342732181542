import { faCircleCheck, faCircleX } from '@fortawesome/pro-light-svg-icons'

import { HOME_URL, NFTS_URL } from '../constants/navigation'
import brandConfig from 'brandConfig'
import customConfig from 'customConfig'
import { isMediaTypeImage } from './media.helpers'
import { BALANCES_SHOULD_FETCH_NFT_URL_PARAM } from '../constants/api'

const nftLink = () => {
  return !brandConfig.features.disablePoints ? NFTS_URL : HOME_URL
}

const getNftCoverImage = (metadata) => {
  const mediaType = metadata?.media?.type
  return mediaType && isMediaTypeImage(mediaType)
    ? metadata?.media?.url
    : metadata?.legacy?.coverImage
}

const buildStatusLabelIcon = (exclusiveContent, claimCount) => {
  if (exclusiveContent) {
    if (claimCount > 0) {
      return {
        label: 'redeemed',
        icon: faCircleX
      }
    } else {
      return {
        label: 'available',
        icon: faCircleCheck
      }
    }
  }
  return {
    label: '',
    icon: ''
  }
}

const extractNftReducer = (state) => state.value.nftReducer

const enforceShouldFetchNftUrlIfEligible = (state) => {
  if (customConfig.balancesAPIParams) {
    return customConfig.balancesAPIParams
  }
  const nftTokens = extractNftReducer(state).nftProfile.nftsForRedemption
  return nftTokens.some((token) => token?.metadata?.links?.exclusiveContentFile)
    ? BALANCES_SHOULD_FETCH_NFT_URL_PARAM
    : ''
}

const buildListNftForSaleData = (tokenId, brandId, nftId, usdPrice) => {
  return {
    order: {
      token_id: tokenId,
      brand_id: brandId,
      usd_price: usdPrice,
      type: 'sell',
      nft_id: nftId
    }
  }
}

export {
  nftLink,
  getNftCoverImage,
  buildStatusLabelIcon,
  enforceShouldFetchNftUrlIfEligible,
  buildListNftForSaleData
}
