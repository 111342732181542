import { isIOSPlatform, titleCase } from 'src/libs/qb-brand-web-components'

import { GOOGLE_MAPS_URL } from '../constants/externalUrls'
import { formatCryptoValue } from './pointsExchange.helpers'

const formatLocationInfo = ({ street, city, country, zip }) => {
  return street || city || country || zip
    ? `${street}, ${zip} ${city}, ${country}`
    : ''
}

const buildMapLink = ({ geolocation, street, city, country, id }) => {
  const { coordinates } = geolocation
  const [lat, lon] = coordinates
  const placeName = encodeURIComponent(
    `${titleCase(id.replace(/[-_]/g, ' '))},${street},${city},${country}`
  )
  const queryLatLon = `${lat},${lon}`
  return isIOSPlatform()
    ? `maps://?t=s&q=${placeName}&ll=${queryLatLon}`
    : `https://${GOOGLE_MAPS_URL}&query=${placeName}&destination=${queryLatLon}`
}

const pointsConversionRate = (reward, provider) => {
  const rewardValue = reward?.value
  const exchangeRate = provider?.exchangeRate
  return rewardValue && exchangeRate
    ? Math.floor(rewardValue * exchangeRate)
    : 0
}

const pointsToCryptoConversion = (reward, offramp, cryptoExchange) => {
  const rewardValue = reward?.value
  return rewardValue && offramp && cryptoExchange
    ? formatCryptoValue(rewardValue, offramp, cryptoExchange)
    : 0
}

export {
  formatLocationInfo,
  buildMapLink,
  pointsConversionRate,
  pointsToCryptoConversion
}
