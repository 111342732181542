import React, { memo } from 'react'

import TermsPolicies from '../../../components/shared/termsPolicies/TermsPolicies'
import { buildUserAccountSiteLinks } from '../../../util/siteLink.helpers'

const TermsPoliciesWrapper = (props) => {
  const { t } = props
  return <TermsPolicies links={buildUserAccountSiteLinks(t)} {...props} />
}

export default memo(TermsPoliciesWrapper)
