import React, { useEffect, useState, lazy, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import {
  deepEqualObjects,
  containsNoValues,
  emailRule,
  checkValidations,
  requiredRule,
  passwordRule
} from 'src/libs/qb-brand-web-components'
import { useLocation } from 'react-router-dom'

import UserAccount from '../components/userAccount/UserAccount'
import { ACCOUNT_PROFILE_DISPATCH } from '../store/reducers/account/account'

import {
  changeAccountState,
  changeAccountInputAndErrorState,
  resetAccountState,
  updateUserAccount
} from '../store/actions/account'
import { changePassword, updateUserAttributes } from '../store/actions/auth'

import { AUTH_FIRST_NAME_ATTR, AUTH_LAST_NAME_ATTR } from '../constants/auth'
import Loading from '../components/shared/loading/Loading'
import { AccountNumberQRCode } from '../components/popups/qrCode'
import { ERROR_TEXT } from '../constants/messages'
import { extractCognitoUserSocialIdentity } from '../util/auth.helpers'
import { trackGTMDataLayer } from 'wrappers/trackGTMDataLayer'
import customConfig from 'customConfig'

const ConnectedAccounts = lazy(
  () => import('../components/userAccount/ConnectedAccounts')
)

const UserAccountContainer = ({
  accountProfile,
  changeAccountState,
  changeAccountInputAndErrorState,
  resetAccountState,
  updateUserAttributes,
  changePassword,
  authProfile,
  isExchangeEnabled,
  updateUserAccount
}) => {
  const { t } = useTranslation()
  const signedUpVia = useMemo(() => {
    return extractCognitoUserSocialIdentity(authProfile)
  }, [authProfile])

  const location = useLocation()
  useEffect(() => {
    trackGTMDataLayer(location.pathname)
  }, [])

  useEffect(() => {
    return () => {
      resetAccountState()
    }
  }, [resetAccountState])

  const [showQRCode, setShowQRCode] = useState(false)

  const handleChangePersonalInfo = () => {
    resetErrorSuccess()
    const { firstNameTemp, lastNameTemp, firstName, lastName } = accountProfile
    const errors = checkValidations({
      firstNameTemp: { value: firstNameTemp, rules: [requiredRule] },
      lastNameTemp: { value: lastNameTemp, rules: [requiredRule] }
    })
    if (containsNoValues(errors)) {
      const userInfoUpdated = !deepEqualObjects(
        { firstNameTemp, lastNameTemp },
        { firstNameTemp: firstName, lastNameTemp: lastName }
      )
      if (userInfoUpdated) {
        const attributes = {
          [AUTH_LAST_NAME_ATTR]: lastNameTemp,
          [AUTH_FIRST_NAME_ATTR]: firstNameTemp
        }
        updateUserAttributes(attributes)
      }
    } else {
      changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleChangeEmailAddress = () => {
    resetErrorSuccess()
    const { emailTemp, email } = accountProfile
    const errors = checkValidations({
      emailTemp: { value: emailTemp, rules: [requiredRule, emailRule] }
    })
    if (containsNoValues(errors)) {
      if (emailTemp !== email) {
        updateUserAttributes({ email: emailTemp })
      }
    } else {
      changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleChangePassword = () => {
    resetErrorSuccess()
    const { oldPassword, newPassword } = accountProfile
    const errors = checkValidations({
      oldPassword: {
        value: oldPassword,
        rules: [requiredRule, passwordRule]
      },
      newPassword: { value: newPassword, rules: [requiredRule, passwordRule] }
    })
    if (containsNoValues(errors)) {
      if (oldPassword !== newPassword) {
        changePassword(oldPassword, newPassword)
      } else {
        changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, {
          newPassword: ERROR_TEXT.OLD_NEW_PASSWORDS_SAME
        })
      }
    } else {
      changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleChangeCountry = () => {
    resetErrorSuccess()
    const { country, countryTemp } = accountProfile
    const errors = checkValidations({
      countryTemp: { value: countryTemp, rules: [requiredRule] }
    })
    if (containsNoValues(errors)) {
      const userInfoUpdated = !deepEqualObjects(
        { countryTemp },
        { countryTemp: country }
      )
      if (userInfoUpdated) {
        updateUserAccount({ country_ISO: countryTemp })
      }
    } else {
      changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const resetErrorSuccess = () => {
    changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, {})
    changeAccountState(ACCOUNT_PROFILE_DISPATCH.SUCCESS, {})
  }

  const handleCopyAccountNumber = () => {
    const authIdSection = document.createElement('textarea')
    authIdSection.value = accountProfile.authId
    document.body.appendChild(authIdSection)
    authIdSection.select()
    document.execCommand('copy')
    document.body.removeChild(authIdSection)
    changeAccountState(ACCOUNT_PROFILE_DISPATCH.SUCCESS, {
      [ACCOUNT_PROFILE_DISPATCH.AUTH_ID]: customConfig.accountNumberCopiedLabel
    })
  }

  const handleViewQRCode = () => {
    setShowQRCode(true)
  }

  const handleCloseQRCode = () => {
    setShowQRCode(false)
  }

  const renderPopup = () => {
    if (showQRCode) {
      return (
        <AccountNumberQRCode
          authId={accountProfile.authId}
          onCancel={handleCloseQRCode}
          t={t}
        />
      )
    }
  }

  return accountProfile.loading ? (
    <Loading />
  ) : (
    <>
      <UserAccount
        accountProfile={accountProfile}
        dispatchAction={changeAccountInputAndErrorState}
        updatePersonalInfo={handleChangePersonalInfo}
        changeEmailAddress={handleChangeEmailAddress}
        changePassword={handleChangePassword}
        changeCountry={handleChangeCountry}
        onCopyAccountNumber={handleCopyAccountNumber}
        onViewQRCode={handleViewQRCode}
        t={t}
        signedUpVia={signedUpVia}
        manageExchangeAccounts={
          isExchangeEnabled && <ConnectedAccounts t={t} />
        }
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({ accountReducer, authReducer }) => {
  const { accountProfile } = accountReducer
  const { authProfile } = authReducer
  return {
    accountProfile,
    authProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeAccountState,
      changeAccountInputAndErrorState,
      resetAccountState,
      updateUserAttributes,
      changePassword,
      updateUserAccount
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountContainer)
