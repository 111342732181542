import React, { memo } from 'react'
import {
  BWPanelHeader,
  BWInput,
  BWLink,
  BWPanelContent,
  emailRule,
  requiredRule,
  BWText
} from 'src/libs/qb-brand-web-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FORGOT_PASSWORD_PROFILE_DISPATCH } from '../../store/reducers/auth/forgotPassword'
import { LOG_IN_URL } from '../../constants/navigation'
import Button from '../shared/button/Button'
import Panel from '../shared/panel/Panel'
import customConfig from 'customConfig'

const ForgotPassword = ({
  onFormSubmit,
  dispatchAction,
  forgotPasswordProfile: { error, success, email }
}) => {
  const { t } = useTranslation()
  return (
    <Panel onFormSubmit={onFormSubmit} as='form' data-testid='forgot-password'>
      <BWPanelHeader
        title={t('forgot-password.forgot-password')}
        description={
          <BWPanelContent>
            <BWText alignCenter label={t(customConfig.forgotPasswordLabel)} />
            <BWText alignCenter label={t(customConfig.signWithSocialLabel)} />
          </BWPanelContent>
        }
      />
      <BWPanelContent>
        <BWInput
          type='email'
          label={t('common.email-address')}
          error={t(error[FORGOT_PASSWORD_PROFILE_DISPATCH.EMAIL])}
          success={t(success[FORGOT_PASSWORD_PROFILE_DISPATCH.EMAIL])}
          value={email}
          validate={[requiredRule, emailRule]}
          onChange={(value) =>
            dispatchAction(FORGOT_PASSWORD_PROFILE_DISPATCH.EMAIL, value)
          }
        />
      </BWPanelContent>
      <Button label={t('forgot-password.send-link')} type='submit' />
      <BWLink label={t('common.cancel')} to={LOG_IN_URL} as={Link} />
    </Panel>
  )
}

export default memo(ForgotPassword)
