import { prependHttpsIfNotExists } from '../../../util/navigation.helpers'

const exclusiveContentUrl = (path) => {
  return prependHttpsIfNotExists(path)
}

const exclusiveContentData = (response) => {
  return response
}

export { exclusiveContentUrl, exclusiveContentData }
