import React, { memo, Fragment } from 'react'
import { faCameraAlt } from '@fortawesome/pro-light-svg-icons'

import BWPopup from '../BWPopup'
import BWLink from '../BWLink'
import { BW_LABEL_TEXT } from '../../constants/messages'
import { reloadWindow } from '../../util/app.helpers'
import { isAndroid, isMobile, isSafari } from '../../util/media.helpers'
import { BW_COLOR_ACCENT } from '../../constants/theme'

const BWCameraBlocked = ({ t, onCancel }) => {
  return (
    <BWPopup
      show
      icon={faCameraAlt}
      color={BW_COLOR_ACCENT}
      title={t(BW_LABEL_TEXT.QR_CAMERA_BLOCKED)}
      description={buildCameraBlockedMessage(t)}
      cancelLabel={t(BW_LABEL_TEXT.DISMISS)}
      onCancel={onCancel}
    />
  )
}

export default memo(BWCameraBlocked)

const buildCameraBlockedMessage = (t) => {
  const qrScannerRequiresText = t(BW_LABEL_TEXT.QR_SCANNER_REQUIRES_CAMERA)
  if (isMobile()) {
    if (isAndroid()) {
      return (
        <Fragment>
          {qrScannerRequiresText}{' '}
          {t(BW_LABEL_TEXT.CAMERA_BLOCKED_ANDROID_CHROME)} {buildRefreshText(t)}
        </Fragment>
      )
    }
    return (
      <Fragment>
        {qrScannerRequiresText}{' '}
        {
          <Fragment>
            {t(BW_LABEL_TEXT.PLEASE)} {refreshLink(t)}{' '}
            {t(BW_LABEL_TEXT.QR_TO_ACCESS_CAMERA)}
          </Fragment>
        }
      </Fragment>
    )
  } else {
    if (isSafari()) {
      return (
        <Fragment>
          {qrScannerRequiresText} {t(BW_LABEL_TEXT.CAMERA_BLOCKED_SAFARI)}{' '}
          {buildRefreshText(t)}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {qrScannerRequiresText} {t(BW_LABEL_TEXT.CAMERA_BLOCKED)}
        </Fragment>
      )
    }
  }
}

const buildRefreshText = (t) => {
  return (
    <Fragment>
      {t(BW_LABEL_TEXT.AND_THEN)} {refreshLink(t)}
    </Fragment>
  )
}

const refreshLink = (t) => {
  return <BWLink onClick={reloadWindow} label={t(BW_LABEL_TEXT.REFRESH)} />
}
