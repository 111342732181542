import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'

import Loading from '../components/shared/loading/Loading'
import EngravingMessages from '../components/engravingMessages/EngravingMessages'
import { getEngravings } from '../store/actions/nft'

const EngravingMessagesContainer = ({
  getEngravings,
  engravingsProfile: { loading, engravings }
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    getEngravings()
  }, [getEngravings])

  return loading ? (
    <Loading />
  ) : (
    <EngravingMessages engravings={engravings || []} t={t} />
  )
}

const mapStateToProps = ({ engravingsReducer }) => {
  const { engravingsProfile } = engravingsReducer
  return {
    engravingsProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEngravings
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngravingMessagesContainer)
