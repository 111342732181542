import React, { memo } from 'react'
import {
  BWPanel,
  BWText,
  BWDisplay,
  BWLink,
  CSS_TEXT_ALIGN_CENTER,
  BWPanelContent
} from 'src/libs/qb-brand-web-components'
import { css } from '@emotion/react'

import { ACCOUNT_PROFILE_DISPATCH } from '../../store/reducers/account/account'
import Button from '../shared/button/Button'
import customConfig from 'customConfig'

const AccountNumber = ({
  accountProfile: { authId, success },
  onCopyAccountNumber,
  onViewQRCode,
  t
}) => {
  return (
    <BWPanel>
      <BWPanelContent>
        <BWText
          label={t(customConfig.accountNumberLabel)}
          size={18}
          weight={700}
          alignCenter
        />
        <BWDisplay
          label={authId}
          noIcon
          customCss={[CSS_TEXT_ALIGN_CENTER, CUSTOM_DISPLAY_HEIGHT]}
          success={t(success[ACCOUNT_PROFILE_DISPATCH.AUTH_ID])}
        />
      </BWPanelContent>
      <Button
        label={t('user-account.copy-number')}
        type='button'
        onClick={onCopyAccountNumber}
      />
      <BWLink label={t(customConfig.viewQrCodeLabel)} onClick={onViewQRCode} />
    </BWPanel>
  )
}

export default memo(AccountNumber)

const CUSTOM_DISPLAY_HEIGHT = css`
  height: auto;
`
