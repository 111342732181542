import React, { memo } from 'react'
import { faCheckCircle, faLanguage } from '@fortawesome/pro-light-svg-icons'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import BWPopup from '../BWPopup'
import BWMenuItem from '../BWTopBar/BWMenuItem'
import { cssCustomGap, CSS_FULL_WIDTH } from '../../styles/shared'
import { BW_LABEL_TEXT } from '../../constants/messages'
import { getColorByLuminosity } from '../../util/style.helpers'
import { BW_COLOR_ACCENT } from '../../constants/theme'

const BWChooseLanguage = ({
  languages,
  language,
  onSelect,
  t,
  invert,
  ...restProps
}) => {
  return (
    <BWPopup
      show
      icon={faLanguage}
      color={BW_COLOR_ACCENT}
      rootCss={CSS_ROOT_STYLE}
      contentCss={CSS_CONTENT_STYLE}
      title={t(BW_LABEL_TEXT.CHOOSE_YOUR_LANGUAGE)}
      cancelLabel={t(BW_LABEL_TEXT.CLOSE)}
      invert={invert}
      {...restProps}
    >
      <LanguageListContainer invert={invert}>
        {languages &&
          Object.keys(languages).map((languageKey) => (
            <BWMenuItem
              key={languageKey}
              label={languages[languageKey]}
              icon={language === languageKey && faCheckCircle}
              onClick={() => onSelect(languageKey)}
              direction='rtl'
            />
          ))}
      </LanguageListContainer>
    </BWPopup>
  )
}

export default memo(BWChooseLanguage)

const LanguageListContainer = styled.ul`
  ${CSS_FULL_WIDTH};
  margin-bottom: 15px;
  ${({ theme: { colors }, invert }) => listItemStyles(colors, invert)}
`

const listItemStyles = (colors, invert) => {
  const color = invert ? colors.accent : colors.main
  const hoverColor = getColorByLuminosity(
    !invert ? colors.accent : colors.main,
    colors
  )
  return css`
    color: ${color};
    > *:hover {
      color: ${hoverColor};
      & > svg {
        color: ${color};
      }
    }
    border-bottom: 1px solid ${colors.accent};
  `
}

const CSS_ROOT_STYLE = css`
  ${cssCustomGap(15)}
`

const CSS_CONTENT_STYLE = css`
  ${CSS_FULL_WIDTH}
`
