import React from 'react'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import FailedStatus from '../common/FailedStatus'
import customConfig from 'customConfig'

const RewardFromCodeFailed = ({ onSubmit, t, tokenName }) => (
  <FailedStatus
    title={t(customConfig.oopsLabel)}
    description={t(customConfig.rewardFromCodeFailedDesc, { tokenName })}
    icon={faExclamationCircle}
    onSubmit={onSubmit}
    t={t}
  />
)

export { RewardFromCodeFailed }
