import React, { memo } from 'react'
import { BWPanelContent, BWText } from 'src/libs/qb-brand-web-components'

import NavButton from '../../shared/button/NavButton'
import { CASHBACK_URL } from '../../../constants/navigation'
import customConfig from 'customConfig'

const CashbackLink = ({ t }) => {
  return (
    <BWPanelContent>
      <BWText label={t(customConfig.cashbackDesc)} alignCenter />
      <NavButton label={t('cashback.get-cashback')} to={CASHBACK_URL} />
    </BWPanelContent>
  )
}

export default memo(CashbackLink)
