import { filter, mergeMap, catchError, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import {
  extractRewardsProfile,
  formatRewardCodeResponse
} from '../../util/epics.helpers'
import {
  MAIN_PROFILE_ACTION,
  POINTS_REWARD_ACTION,
  REWARDS_ACTION,
  NFT_ACTION
} from '../../constants/actions'
import recaptchaService from '../../services/recaptcha'

import {
  extractCognitoUserRewardCode,
  extractCognitoUser,
  wrapUserAccessToken
} from '../../util/auth.helpers'
import { getRewardCode, removeRewardCode } from '../../util/local.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import { isNftToken } from '../../util/brand.helpers'
import { checkIsAlreadyAuthenticated } from '../../util/account.helpers'
import { LOYALTY_EVENT_TYPES } from '../../constants/transactions'

const handleValidateSignupRewardCode = (action$, state$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS),
    filter((_) => !extractRewardsProfile(state$).rewardCode),
    map((_) => extractCognitoUserRewardCode(extractCognitoUser(state$))),
    filter((rewardCode) => rewardCode),
    map((rewardCode) => ({
      type: REWARDS_ACTION.ON_VALIDATE_REWARD_CODE,
      payload: { rewardCode }
    }))
  )

const handleValidateSigninRewardCode = (action$, state$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS),
    filter(() => !checkIsAlreadyAuthenticated()),
    map((_) => extractRewardsProfile(state$).rewardCode),
    filter((rewardCode) => rewardCode),
    map((rewardCode) => ({
      type: REWARDS_ACTION.ON_VALIDATE_REWARD_CODE,
      payload: { rewardCode }
    }))
  )

const handleValidateRewardCodeOnSocialLogin = (action$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS),
    map((_) => getRewardCode()),
    filter((rewardCode) => rewardCode),
    tap(() => removeRewardCode()),
    map((rewardCode) => ({
      type: REWARDS_ACTION.ON_VALIDATE_REWARD_CODE,
      payload: { rewardCode }
    }))
  )

const handleValidateRewardCode = (action$) =>
  action$.pipe(
    ofType(REWARDS_ACTION.ON_VALIDATE_REWARD_CODE),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        recaptchaService.execute$().pipe(
          mergeMap((recaptchaToken) =>
            apiService
              .validateCode(payload.rewardCode, accessToken, recaptchaToken)
              .pipe(
                tap((data) => logger(data)),
                map(({ response }) => ({
                  type: REWARDS_ACTION.ON_REWARD_CODE_EXISTS,
                  payload: formatRewardCodeResponse(commonParser(response.data))
                })),
                catchError(() =>
                  of({
                    type: REWARDS_ACTION.ON_REWARD_CODE_DOES_NOT_EXIST,
                    payload: { code: payload.rewardCode }
                  })
                )
              )
          )
        )
      )
    )
  )

const handleCallReceiveRewardCode = (action$) =>
  action$.pipe(
    ofType(REWARDS_ACTION.ON_REWARD_CODE_EXISTS),
    filter(({ payload }) => !payload.used && !payload.invalid),
    map(({ payload }) => {
      const { campaign, code } = payload
      const isNft = isNftToken(campaign?.token)
      const rewardType = isNft
        ? LOYALTY_EVENT_TYPES.CODE_TO_NFT
        : LOYALTY_EVENT_TYPES.CODE_TO_POINTS
      return {
        type: REWARDS_ACTION.ON_RECEIVE_REWARD_FROM_CODE,
        payload: { code, rewardType }
      }
    })
  )

const handleReceiveRewardFromCode = (action$) =>
  action$.pipe(
    ofType(REWARDS_ACTION.ON_RECEIVE_REWARD_FROM_CODE),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        recaptchaService.execute$().pipe(
          mergeMap((recaptchaToken) =>
            apiService
              .receiveRewardFromCode(payload, accessToken, recaptchaToken)
              .pipe(
                tap((data) => logger(data)),
                map(({ response }) => commonParser(response.data)),
                map((reward) => ({
                  type: isNftToken(reward?.campaign?.token)
                    ? NFT_ACTION.ON_RECEIVE_NFT_FROM_CODE_SUCCESS
                    : POINTS_REWARD_ACTION.ON_RECEIVE_POINTS_FROM_CODE_SUCCESS,
                  payload: reward
                })),
                catchError((err) =>
                  of({
                    type: REWARDS_ACTION.ON_RECEIVE_REWARD_FROM_CODE_FAILED,
                    payload: err
                  })
                )
              )
          )
        )
      )
    )
  )

export const rewardsEpic = combineEpics(
  handleValidateSignupRewardCode,
  handleValidateSigninRewardCode,
  handleValidateRewardCode,
  handleCallReceiveRewardCode,
  handleValidateRewardCodeOnSocialLogin,
  handleReceiveRewardFromCode
)
