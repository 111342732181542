import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import Button from './Button'

const NavButton = ({ to, ...restProps }) => {
  return (
    <Link to={to}>
      <Button {...restProps} />
    </Link>
  )
}

export default memo(NavButton)
