import {
  extractInputValueAndError,
  mergeToNewObject
} from 'src/libs/qb-brand-web-components'

import { TRANSFER_NFT_ACTION } from '../../../constants/actions'

export const transferNftProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TRANSFER_NFT_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case TRANSFER_NFT_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case TRANSFER_NFT_ACTION.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  accountNumber: '',
  error: {}
}

export const TRANSFER_NFTS_PROFILE_DISPATCH = {
  ACCOUNT_NUMBER: 'accountNumber',
  ERROR: 'error'
}
