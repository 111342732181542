import {
  mergeToNewObject,
  extractInputValueAndError,
  BW_ERROR_TEXT
} from 'src/libs/qb-brand-web-components'

import {
  EXCHANGE_PROVIDER_ACTION,
  EXCHANGE_ACTION
} from '../../../constants/actions'
import {
  DEFAULT_CONTAINER_STATE,
  EXCHANGE_PROVIDER_PROCESS_STEP
} from '../../../constants/containerStates'
import { ERROR_TEXT } from '../../../constants/messages'
import { API_RESPONSE_ERROR_TEXT } from '../../../constants/errors'

export const exchangeProviderProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_PROVIDER_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case EXCHANGE_PROVIDER_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case EXCHANGE_PROVIDER_ACTION.RESET_STATE:
      return INITIAL_STATE

    case EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER:
    case EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER:
      return mergeToNewObject(state, {
        isProcessing: true,
        accountSaveState: DEFAULT_CONTAINER_STATE.NONE
      })

    case EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER_SUCCESS:
      return mergeToNewObject(state, {
        accountSaveState: EXCHANGE_PROVIDER_PROCESS_STEP.CONNECTED_ACCOUNT,
        isProcessing: false
      })

    case EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER_SUCCESS:
      return mergeToNewObject(state, {
        accountSaveState: EXCHANGE_PROVIDER_PROCESS_STEP.CHANGED_ACCOUNT,
        isProcessing: false
      })

    case EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER_FAILED:
    case EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER_FAILED:
      return mergeToNewObject(state, {
        accountSaveState: EXCHANGE_PROVIDER_PROCESS_STEP.WRONG_ACCOUNT_NUMBER,
        isProcessing: false
      })

    case EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_FAILED:
      return mergeToNewObject(state, {
        error: { pointsAmount: extractPointsError(payload) }
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  connectAccountNumber: '',
  pointsAmount: '',
  error: {},
  accountSaveState: DEFAULT_CONTAINER_STATE.NONE,
  isProcessing: false
}

export const EXCHANGE_PROVIDER_PROFILE_DISPATCH = {
  CONNECT_ACCOUNT_NUMBER: 'connectAccountNumber',
  POINTS_AMOUNT: 'pointsAmount',
  ERROR: 'error',
  ACCOUNT_SAVE_STATE: 'accountSaveState'
}

const extractPointsError = (payload) => {
  const errorMessage = payload?.response?.errors?.detail
  if (errorMessage === API_RESPONSE_ERROR_TEXT.INSUFFICIENT_BALANCE) {
    return ERROR_TEXT.NO_ENOUGH_POINTS
  }
  return BW_ERROR_TEXT.SOMETHING_WENT_WRONG
}
