import {
  REDEMPTION_FULFILLMENT_ACTION,
  SURVEY_ACTION
} from '../../constants/actions'

const changeSurveyState = (property, value) => ({
  type: SURVEY_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeSurveysInputAndErrorState = (property, value) => ({
  type: SURVEY_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const submitFavoriteExchangeOption = (surveyId, favoriteOption) => ({
  type: SURVEY_ACTION.ON_SUBMIT_FAVORITE_EXCHANGE,
  payload: { surveyId, favoriteOption }
})

const getSurveys = () => ({
  type: SURVEY_ACTION.ON_GET_SURVEYS
})

const getSubmissions = () => ({
  type: SURVEY_ACTION.ON_GET_USER_SURVEY_SUBMISSIONS
})

// REDEMPTION FULFILLMENT
const changeRedemptionSurveyState = (property, value) => ({
  type: REDEMPTION_FULFILLMENT_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeRedemptionSurveyInputAndErrorState = (property, value) => ({
  type: REDEMPTION_FULFILLMENT_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const resetRedemptionSurveyState = () => ({
  type: REDEMPTION_FULFILLMENT_ACTION.RESET_STATE
})

export {
  changeSurveyState,
  getSurveys,
  getSubmissions,
  submitFavoriteExchangeOption,
  changeSurveysInputAndErrorState,
  changeRedemptionSurveyState,
  changeRedemptionSurveyInputAndErrorState,
  resetRedemptionSurveyState
}
