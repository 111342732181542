import React, { memo } from 'react'
import { faSadTear } from '@fortawesome/pro-light-svg-icons'

import BWPanel from '../BWPanel'
import BWPanelHeader from '../BWPanel/BWPanelHeader'
import BWLink from '../BWLink'
import BWButton from '../BWButton'
import { BW_COLOR_ACCENT } from '../../constants/theme'

const BWPageNotFound = ({
  title,
  description,
  submitLabel,
  cancelLabel,
  iconColor = BW_COLOR_ACCENT,
  onBackToHome,
  onCancelLink,
  btnColor,
  btnTextColor
}) => {
  return (
    <div data-testid='bw-page-not-found'>
      <BWPanel onFormSubmit={onBackToHome} as='form'>
        <BWPanelHeader
          title={`404 ${title}`}
          description={description}
          icon={faSadTear}
          color={iconColor}
        />
        <BWButton
          label={submitLabel}
          type='submit'
          color={btnColor}
          textColor={btnTextColor}
        />
        <BWLink label={cancelLabel} onClick={onCancelLink} />
      </BWPanel>
    </div>
  )
}

export default memo(BWPageNotFound)
