export const formatMultipleNftsPerToken = (nftsResult) => {
  if (nftsResult && nftsResult.length) {
    const nfts = []
    nftsResult.forEach(({ nftIds, token }) => {
      nftIds.forEach(({ id, ...restProps }) => {
        nfts.push({ nftId: id, ...restProps, ...token })
      })
    })
    return nfts
  }
}
