import React, { memo, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  faQrcode,
  faChevronDown
} from '@fortawesome/pro-regular-svg-icons'

import {
  cssBorderRadius,
  cssPadding,
  cssFontSize,
  cssGap,
  CSS_FLEX,
  CSS_CURSOR_POINTER,
  CSS_FULL_WIDTH,
  CSS_TEXT_ALIGN_CENTER,
  CSS_OUTLINE_NONE,
  CSS_GRID,
  CSS_GRID_COLUMN,
  CSS_POSITION_RELATIVE,
  CSS_POSITION_ABSOLUTE,
  CSS_POINTER_EVENTS_NONE
} from '../../styles/shared'
import { buildFieldIcon, defaultBoxStyles } from '../shared/styled/common'
import {
  INPUT_PASSWORD,
  INPUT_TEXT,
  INPUT_NUMBER,
  MESSAGE_TYPE
} from '../../constants/constants'
import { SIZE_UNIT } from '../../themes/units'
import BWHelpText from '../BWHelpText'
import { mergeToNewObject } from '../../util/object.helpers'
import { checkRulesAgainstValue } from '../../util/validation.helpers'
import { BWDropdown } from '..'

const invalidNumberChars = ['-', 'e', '+', 'E', '.']

const BWInput = ({
  type,
  onChange,
  onKeyDown,
  value,
  label,
  help,
  success,
  validate,
  error,
  eyePassword,
  noStatusIcon,
  alignCenter,
  customCss,
  multiLine,
  maxLength,
  positiveNumber,
  qrCodeEnabled,
  onQrCodeClick,
  invertErrorColor,
  isDropdown,
  options,
  search,
  id
}) => {
  const textAreaRef = useRef(null)
  const [textAreaHeight, setTextAreaHeight] = useState('auto')

  useEffect(() => {
    checkValidateRules(true)
  }, [])

  useEffect(() => {
    if (validate && validate.length > 0) {
      checkValidateRules()
    }
  }, [value, error])

  useEffect(() => {
    if (multiLine && value && textAreaRef && textAreaRef.current) {
      setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`)
    }
  }, [value])

  const checkValidateRules = (checkSubmit = false) => {
    const isValid = checkRulesAgainstValue(validate, value).length === 0
    if (isValid) {
      setStatus(MESSAGE_TYPE.SUCCESS)
      if (checkSubmit) {
        setIsSubmitted(1)
      }
    } else {
      setStatus('')
      setIsSubmitted(0)
    }
  }

  useEffect(() => {
    const isError = error
    const isSuccess = success
    if (isError || isSuccess) {
      setIsSubmitted(!isError && isSuccess && success.length > 0 ? 1 : 0)
      setStatus(
        (isError && MESSAGE_TYPE.ERROR) || (isSuccess && MESSAGE_TYPE.SUCCESS)
      )
    }
  }, [error, success])

  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(0)
  const [status, setStatus] = useState('')

  const handleEyePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleOnInputChange = ({ target }) => {
    const value = target.value
    if (maxLength && value.length > maxLength) {
      return
    }
    onChange && onChange(value)
    multiLine && setTextAreaHeight('auto')
  }

  const handleOnKeyDown = (e) => {
    if (positiveNumber && invalidNumberChars.includes(e.key)) {
      e.preventDefault()
    }
    onKeyDown && onKeyDown(e)
  }

  const handlePasteDrop = (e) => {
    if (positiveNumber) {
      e.preventDefault()
    }
  }

  const getInputType = () => {
    if (type === INPUT_PASSWORD || eyePassword) {
      return showPassword ? INPUT_TEXT : INPUT_PASSWORD
    }
    if (positiveNumber) {
      return INPUT_NUMBER
    }
    return type || INPUT_TEXT
  }

  return (
    <InputContainer data-testid='bw-input'>
      <InputContent>
        {isDropdown ? (
          <BWDropdown
            options={options}
            value={value}
            onChange={handleOnInputChange}
            label={label}
            status={status}
            submitted={isSubmitted}
            noStatusIcon={noStatusIcon}
          />
        ) : multiLine ? (
          <TextAreaFieldContainer
            ref={textAreaRef}
            onChange={handleOnInputChange}
            value={value}
            placeholder={label}
            type={getInputType()}
            status={status}
            eyePassword={eyePassword}
            submitted={isSubmitted}
            noStatusIcon={noStatusIcon}
            alignCenter={alignCenter}
            qrCodeEnabled={qrCodeEnabled}
            customCss={mergeToNewObject(customCss, { height: textAreaHeight })}
            rows={1}
          />
        ) : (
          <InputFieldContainer
            id={id}
            onChange={handleOnInputChange}
            value={value}
            placeholder={label}
            type={getInputType()}
            status={status}
            eyePassword={eyePassword}
            submitted={isSubmitted}
            noStatusIcon={noStatusIcon}
            alignCenter={alignCenter}
            qrCodeEnabled={qrCodeEnabled}
            onKeyDown={handleOnKeyDown}
            onPaste={handlePasteDrop}
            onDrop={handlePasteDrop}
            customCss={customCss}
          />
        )}
        <InputIconContainer css={isDropdown && CSS_POINTER_EVENTS_NONE}>
          {isDropdown && <InputDefaultIcon icon={faChevronDown} />}
          {qrCodeEnabled && (
            <InputDefaultIcon onClick={onQrCodeClick} icon={faQrcode} />
          )}
          {eyePassword && (
            <PasswordShowIcon
              onClick={handleEyePassword}
              icon={showPassword ? faEyeSlash : faEye}
            />
          )}
          {search}
          {!noStatusIcon && (
            <DisplayIcon
              status={status}
              submitted={isSubmitted}
              icon={buildFieldIcon(status)}
            />
          )}
        </InputIconContainer>
      </InputContent>
      <BWHelpText
        help={help}
        success={!error && status !== '' && success}
        error={status === MESSAGE_TYPE.ERROR && error}
        invertErrorColor={invertErrorColor}
      />
    </InputContainer>
  )
}

export default memo(BWInput)

const InputContainer = styled.div`
  transition: all 0.4s ease-in-out;
  ${CSS_POSITION_RELATIVE};
  ${CSS_FULL_WIDTH};
`

const InputContent = styled.div`
  ${CSS_FLEX};
  flex-direction: column-reverse;
`

const InputFieldContainer = styled.input`
  ${(props) => inputDefaultStyles(props)};
`

const TextAreaFieldContainer = styled.textarea`
  ${(props) => inputDefaultStyles(props)};
  resize: none;
  overflow: hidden;
`

export const inputDefaultStyles = ({
  theme: { colors },
  status,
  eyePassword,
  submitted,
  noStatusIcon,
  alignCenter,
  qrCodeEnabled,
  customCss
}) => css`
  ${cssFontSize(SIZE_UNIT.MD)};
  ${cssBorderRadius(SIZE_UNIT.SM)};
  ${cssPadding(SIZE_UNIT.MD1, SIZE_UNIT.MD)};
  min-width: 200px;
  border: none;
  &:focus {
    ${CSS_OUTLINE_NONE};
  }
  &::placeholder {
    color: ${colors?.gray};
    opacity: 1;
  }
  &:focus + ${InputIconContainer} ${DisplayIcon} {
    color: ${submitted && !status
      ? colors?.gray
      : status
        ? colors[status]
        : colors?.accent};
  }
  ${alignCenter && CSS_TEXT_ALIGN_CENTER};
  ${defaultBoxStyles(colors)};
  ${customPaddingRight(noStatusIcon, eyePassword, qrCodeEnabled)};
  ${customCss && customCss};
`

const customPaddingRight = (noStatusIcon, eyePassword, qrCodeEnabled) => {
  let paddingRight
  const enabledIconsLength = [!noStatusIcon, eyePassword, qrCodeEnabled].filter(
    (status) => status
  ).length
  if (enabledIconsLength === 2) {
    paddingRight = 76
  } else if (enabledIconsLength === 1) {
    paddingRight = 46
  } else {
    paddingRight = 15
  }
  return css`
    padding-right: ${paddingRight}px;
  `
}

const DisplayIcon = styled(FontAwesomeIcon)`
  color: ${({ theme: { colors }, status, submitted }) =>
    !submitted && status ? colors[status] : colors?.gray};
  ${cssFontSize(SIZE_UNIT.MD)};
  ${CSS_POINTER_EVENTS_NONE};
`

const PasswordShowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme: { colors } }) => colors?.liteBlack};
  ${CSS_CURSOR_POINTER};
  pointer-events: auto;
  ${cssFontSize(SIZE_UNIT.MD)};
  width: 1.25em !important;
`

const InputIconContainer = styled.div`
  ${CSS_GRID};
  ${CSS_GRID_COLUMN};
  ${cssGap(SIZE_UNIT.MD1)};
  ${CSS_POSITION_ABSOLUTE};
  right: 15px;
  top: 18px;
  grid-template-columns: min-content;
`

const InputDefaultIcon = styled(FontAwesomeIcon)`
  color: ${({ theme: { colors } }) => colors.liteBlack};
  background-color: ${({ theme: { colors } }) => colors.liteWhite};
  ${cssFontSize(SIZE_UNIT.MD)};
  ${CSS_CURSOR_POINTER};
`
