import { combineReducers } from 'redux'

import { signupProfile } from './signup'
import { loginProfile } from './login'
import { forgotPasswordProfile } from './forgotPassword'
import { resetPasswordProfile } from './resetPassword'
import { authProfile } from './auth'

export const authReducer = combineReducers({
  signupProfile,
  loginProfile,
  forgotPasswordProfile,
  resetPasswordProfile,
  authProfile
})
