import brandConfig from 'brandConfig'
import { STATIC_LOGO_URL } from '../constants/app'
import { isTokenTypeNft } from './pointsExchange.helpers'
import { toLower } from './string.helpers'

const isNftToken = (token) => {
  return isTokenTypeNft(token?.type)
}

const getNonNegativeNumber = (value) => {
  return value > 0 ? value : 0
}

const getBrandName = () => {
  const { altName, name } = brandConfig
  return altName ? altName : name
}

const getStaticLogoPath = (symbol) => {
  return symbol ? `${STATIC_LOGO_URL}${toLower(symbol)}.png` : ''
}

export { isNftToken, getNonNegativeNumber, getBrandName, getStaticLogoPath }
