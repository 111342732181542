import React, { memo } from 'react'
import {
  BWText,
  BWInput,
  BWPanelContent,
  emailRule,
  requiredRule
} from 'src/libs/qb-brand-web-components'

import { ACCOUNT_PROFILE_DISPATCH } from '../../store/reducers/account/account'
import Button from '../shared/button/Button'
import Panel from '../shared/panel/Panel'

const ChangeEmailAddress = ({
  onFormSubmit,
  dispatchAction,
  accountProfile: { error, success, emailTemp, email },
  t
}) => {
  return (
    <Panel onFormSubmit={onFormSubmit} as='form'>
      <BWPanelContent>
        <BWText
          label={t('user-account.change-email-address')}
          size={18}
          weight={700}
          alignCenter
        />
        <BWInput
          label={t('common.email-address')}
          error={t(error[ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP])}
          success={t(success[ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP])}
          value={emailTemp}
          validate={[requiredRule, emailRule]}
          onChange={(value) =>
            dispatchAction(ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP, value)
          }
        />
      </BWPanelContent>
      <Button
        label={t('user-account.verify')}
        type='submit'
        disabled={emailTemp === email}
      />
    </Panel>
  )
}

export default memo(ChangeEmailAddress)
