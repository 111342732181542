import { BWText } from 'src/libs/qb-brand-web-components'
import { Trans } from 'react-i18next'
import React, { memo } from 'react'

const TranslationTextWithBold = ({ label, ...restProps }) => {
  return (
    <BWText
      label={
        <Trans {...restProps} i18nIsDynamicList>
          <BWText label={label} weight='bold' />
        </Trans>
      }
    />
  )
}

export default memo(TranslationTextWithBold)
