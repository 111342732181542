import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { EXCHANGE_PROVIDER_ACTION } from '../../../constants/actions'

export const brandRewardTypesProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_PROVIDER_ACTION.ON_GET_REWARD_TYPES_FOR_BRAND:
      return mergeToNewObject(state, { brandRewardTypes: [] })

    case EXCHANGE_PROVIDER_ACTION.ON_GET_REWARD_TYPES_FOR_BRAND_SUCCESS:
      return mergeToNewObject(state, { brandRewardTypes: payload })

    default:
      return state
  }
}

const INITIAL_STATE = {
  brandRewardTypes: []
}
