import { mergeMap, catchError, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import { formatRewardTypes } from '../../util/epics.helpers'

import { REDEEM_ACTION } from '../../constants/actions'
import { APP_CONFIG } from '../../constants/appConfig'
import { wrapUserAccessToken } from '../../util/auth.helpers'

const handleGetRewardTypes = (action$) =>
  action$.pipe(
    ofType(REDEEM_ACTION.ON_GET_REWARD_TYPES),
    mergeMap((_) =>
      apiService.getRewardTypes(APP_CONFIG.BRAND_ID).pipe(
        tap((data) => logger(data)),
        map(({ response }) => ({
          type: REDEEM_ACTION.ON_GET_REWARD_TYPES_SUCCESS,
          payload: formatRewardTypes(response.data)
        })),
        catchError((err) =>
          of({ type: REDEEM_ACTION.ON_GET_REWARD_TYPES_FAILED, payload: err })
        )
      )
    )
  )

const handleGetSignedUrl = (action$) =>
  action$.pipe(
    ofType(REDEEM_ACTION.ON_GET_SIGNED_URL),
    mergeMap(({ payload: { path } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getSignedUrl(path, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: REDEEM_ACTION.ON_GET_SIGNED_URL_SUCCESS,
            payload: response.data
          })),
          catchError((err) =>
            of({ type: REDEEM_ACTION.ON_GET_SIGNED_URL_FAILED, payload: err })
          )
        )
      )
    )
  )

export const redeemEpic = combineEpics(handleGetRewardTypes, handleGetSignedUrl)
