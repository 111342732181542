import React, { memo } from 'react'

import { BW_COLOR_ACCENT } from '../../constants/theme'
import BWOutsideLink from '../BWLink/BWOutsideLink'
import { css } from '@emotion/react'

const BWSiteLinks = ({ links, color = BW_COLOR_ACCENT, fontSize = 16 }) => {
  return links.map(({ label, to }, index) => (
    <BWOutsideLink
      key={index}
      to={to}
      label={label}
      size={fontSize}
      color={color}
      customCss={css`
        text-decoration: none;
      `}
    />
  ))
}

export default memo(BWSiteLinks)
