import { mergeMap, catchError, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import { formatCashbackCategories } from '../../util/epics.helpers'
import { CASHBACK_ACTION } from '../../constants/actions'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import { NUMBER_OF_BRANDS_PER_PAGE } from '../../constants/cashback'

const handleGetCashbackBrands = (action$) =>
  action$.pipe(
    ofType(CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS),
    mergeMap(({ payload: { categories, page } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getCashbackBrands(
            {
              page: page || 0,
              size: NUMBER_OF_BRANDS_PER_PAGE,
              categories: formatCashbackCategories(categories)
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetCashbackBrandsBySearch = (action$) =>
  action$.pipe(
    ofType(CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_BY_SEARCH),
    mergeMap(({ payload: { searchTerm, page } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getCashbackBrandsBySearch(
            {
              page: page || 0,
              size: NUMBER_OF_BRANDS_PER_PAGE,
              search_term: searchTerm
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_BY_SEARCH_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: CASHBACK_ACTION.ON_GET_CASHBACK_BRANDS_BY_SEARCH_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetFilterCategories = (action$) =>
  action$.pipe(
    ofType(CASHBACK_ACTION.ON_GET_CASHBACK_FILTER_CATEGORIES),
    mergeMap((_) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getFilterCategories(accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: CASHBACK_ACTION.ON_GET_CASHBACK_FILTER_CATEGORIES_SUCCESS,
            payload: response.data
          })),
          catchError((err) =>
            of({
              type: CASHBACK_ACTION.ON_GET_CASHBACK_FILTER_CATEGORIES_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleGetCashbackBrandDetails = (action$) =>
  action$.pipe(
    ofType(CASHBACK_ACTION.ON_GET_CASHBACK_BRAND_DETAILS),
    mergeMap(({ payload: { id } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getCashbackBrandDetails({ store_id: id }, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: CASHBACK_ACTION.ON_GET_CASHBACK_BRAND_DETAILS_SUCCESS,
            payload: response.data
          })),
          catchError((err) =>
            of({
              type: CASHBACK_ACTION.ON_GET_CASHBACK_BRAND_DETAILS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

export const cashbackEpic = combineEpics(
  handleGetCashbackBrands,
  handleGetCashbackBrandsBySearch,
  handleGetFilterCategories,
  handleGetCashbackBrandDetails
)
