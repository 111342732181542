import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { getCurrentBrandToken } from '../../../util/apiParser.helpers'
import {
  AUTH_ACTION,
  MAIN_PROFILE_ACTION,
  EXCHANGE_PROVIDER_ACTION,
  REDEEM_ACTION,
  POINTS_REWARD_ACTION
} from './../../../constants/actions'
import brandConfig from 'brandConfig'
import { RENAULT_BRAND_ID } from '../../../constants/brands'
import { isExistsElementFromList } from '../../../util/app/app.helpers'

export const mainProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MAIN_PROFILE_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA:
      return mergeToNewObject(state, { loading: true })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      return mergeToNewObject(state, {
        loading: false,
        ...extractMainData(payload)
      })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED:
      return mergeToNewObject(state, { loading: false })

    case MAIN_PROFILE_ACTION.RESET_PREVIOUS_USER_POINTS_STATE:
      return mergeToNewObject(state, {
        previousUserPoints: state.currentUserPoints
      })

    case MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS_SUCCESS:
      return syncCurrentUserPoints(state, payload)

    case AUTH_ACTION.ON_SIGN_OUT_SUCCESS:
      return INITIAL_STATE

    case REDEEM_ACTION.ON_GET_REWARD_TYPES_SUCCESS:
      return mergeToNewObject(state, { redemptionOptions: payload })

    case EXCHANGE_PROVIDER_ACTION.ON_GET_USER_MEMBERSHIPS_SUCCESS:
      return mergeToNewObject(state, { exchangeMemberships: payload })

    case POINTS_REWARD_ACTION.ON_RECEIVE_POINTS_FROM_CODE_SUCCESS:
      return mergeToNewObject(
        state,
        brandConfig.brandId === RENAULT_BRAND_ID &&
          isExistsElementFromList(
            brandConfig.campaignIdsToEnablePointBurn,
            payload?.campaign?.id
          )
          ? {
              isDisabledPointsBurn: false
            }
          : {}
      )
    default:
      return state
  }
}

const INITIAL_STATE = {
  brandAppConfig: {},
  currentUserPoints: 0,
  currentBrandToken: {},
  previousUserPoints: 0,
  userBrandTokens: {},
  redemptionOptions: [],
  exchangeMemberships: {},
  exchangeWhitelist: {
    outgoing: [],
    incoming: []
  },
  loading: true,
  success: {},
  error: {},
  isDisabledPointsBurn: false
}

const extractMainData = (payload) => {
  const { userBrandTokens } = payload
  const currentBrandToken = getCurrentBrandToken(userBrandTokens.points)
  return {
    ...payload,
    userBrandTokens,
    currentUserPoints: currentBrandToken?.balance || 0,
    currentBrandToken
  }
}

const syncCurrentUserPoints = (state, userBrandTokens) => {
  const currentBrandToken = getCurrentBrandToken(userBrandTokens.points)
  const updatedState = {
    currentUserPoints: currentBrandToken?.balance || 0,
    userBrandTokens,
    previousUserPoints: state.currentUserPoints
  }
  return mergeToNewObject(state, updatedState)
}
