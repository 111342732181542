import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { AUTH_ACTION, TRANSACTIONS_HISTORY } from './../../../constants/actions'

export const transactionsProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TRANSACTIONS_HISTORY.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case TRANSACTIONS_HISTORY.ON_GET_TRANSACTIONS:
      const { loading, loadingTx, offset } = payload
      return mergeToNewObject(state, {
        loading: loading,
        loadingTx: loadingTx,
        offset: offset
      })

    case TRANSACTIONS_HISTORY.ON_GET_TRANSACTIONS_SUCCESS:
      const { transactions: newTransactions, totalTxCount } = payload
      const transactions =
        state.offset > 0
          ? [...state.transactions, ...newTransactions]
          : newTransactions
      return mergeToNewObject(state, {
        transactions,
        loading: false,
        loadingTx: false,
        totalTx: totalTxCount
      })

    case TRANSACTIONS_HISTORY.ON_GET_TRANSACTIONS_FAILED:
      return mergeToNewObject(state, { loading: false })

    case AUTH_ACTION.ON_SIGN_OUT_SUCCESS:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  transactions: [],
  offset: 0,
  totalTx: 0,
  loading: false,
  loadingTx: false,
  error: {}
}

export const TRANSACTIONS_PROFILE_DISPATCH = {
  TRANSACTIONS: 'transactions',
  ERROR: 'error',
  LOADING: 'loading',
  OFFSET: 'offset'
}
