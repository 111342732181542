import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  containsNoValues,
  emailRule,
  checkValidations,
  requiredRule
} from 'src/libs/qb-brand-web-components'

import ForgotPassword from '../components/forgotPassword/ForgotPassword'
import requireUnauth from '../hoc/requireUnauth'
import {
  changeForgotPasswordState,
  changeForgotPasswordInputAndErrorState,
  cleanForgotPasswordState,
  requestPasswordChange
} from '../store/actions/auth'
import { FORGOT_PASSWORD_PROFILE_DISPATCH } from '../store/reducers/auth/forgotPassword'

const ForgotPasswordContainer = ({
  forgotPasswordProfile,
  changeForgotPasswordState,
  changeForgotPasswordInputAndErrorState,
  cleanForgotPasswordState,
  requestPasswordChange
}) => {
  useEffect(() => {
    return () => {
      cleanForgotPasswordState()
    }
  }, [cleanForgotPasswordState])

  const onFormSubmit = () => {
    changeForgotPasswordState(FORGOT_PASSWORD_PROFILE_DISPATCH.ERROR, {})
    const { email } = forgotPasswordProfile
    const errors = checkValidations({
      email: { value: email, rules: [requiredRule, emailRule] }
    })
    if (containsNoValues(errors)) {
      requestPasswordChange(email)
    } else {
      changeForgotPasswordState(FORGOT_PASSWORD_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  return (
    <ForgotPassword
      forgotPasswordProfile={forgotPasswordProfile}
      onFormSubmit={onFormSubmit}
      dispatchAction={changeForgotPasswordInputAndErrorState}
    />
  )
}

const mapStateToProps = ({ authReducer }) => {
  const { forgotPasswordProfile } = authReducer
  return {
    forgotPasswordProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeForgotPasswordState,
      changeForgotPasswordInputAndErrorState,
      cleanForgotPasswordState,
      requestPasswordChange
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer)
)
