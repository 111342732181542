import {
  mergeToNewObject,
  extractInputValueAndError,
  BW_ERROR_TEXT,
  BW_COGNITO_ERRORS
} from 'src/libs/qb-brand-web-components'

import { AUTH_ACTION, ACCOUNT_ACTION } from '../../../constants/actions'
import { ERROR_TEXT, MESSAGE_TEXT } from '../../../constants/messages'
import {
  DEFAULT_CONTAINER_STATE,
  ACCOUNT_INIT_PROCESS_STEP
} from '../../../constants/containerStates'
import customConfig from 'customConfig'
import { DEFAULT_COUNTRY_VALUE } from '../../../constants/app'

export const accountProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACCOUNT_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case ACCOUNT_ACTION.GET_ACCOUNT_DETAILS:
      return mergeToNewObject(state, { loading: true })

    case ACCOUNT_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_SUCCESS:
    case ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS:
      return mergeToNewObject(state, handleGetAccountDetailsResponse(payload), {
        loading: false
      })

    case ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_SUCCESS:
      return mergeToNewObject(state, handleUserUpdateResponse(payload, state), {
        loading: false
      })

    case AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_FAILED:
      return mergeToNewObject(
        state,
        { error: handleUpdateUserErrors(payload) },
        { loading: false }
      )

    case AUTH_ACTION.ON_CHANGE_PASSWORD_SUCCESS:
      return mergeToNewObject(
        state,
        { success: { newPassword: MESSAGE_TEXT.PASSWORD_CHANGED } },
        { loading: false }
      )

    case AUTH_ACTION.ON_CHANGE_PASSWORD_FAILED:
      return mergeToNewObject(
        state,
        { error: handleChangePasswordErrors(payload) },
        { loading: false }
      )

    case ACCOUNT_ACTION.RESET_STATE:
      return mergeToNewObject(state, handleResetState(state))

    case ACCOUNT_ACTION.ON_CREATE_ACCOUNT_FAILED:
    case ACCOUNT_ACTION.ON_GET_USER_COUNTRY_FAILED:
      return mergeToNewObject(state, {
        accountInitState: ACCOUNT_INIT_PROCESS_STEP.CREATE_ACCOUNT_FAILED
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  userId: '',
  authId: '',

  firstName: '',
  firstNameTemp: '',

  lastName: '',
  lastNameTemp: '',

  email: '',
  emailTemp: '',

  oldPassword: '',
  newPassword: '',

  language: '',

  country: DEFAULT_COUNTRY_VALUE,
  countryTemp: DEFAULT_COUNTRY_VALUE,

  accountInitState: DEFAULT_CONTAINER_STATE.NONE,

  error: {},
  success: {},

  loading: false
}

export const ACCOUNT_PROFILE_DISPATCH = {
  AUTH_ID: 'authId',

  FIRST_NAME: 'firstName',
  FIRST_NAME_TEMP: 'firstNameTemp',

  LAST_NAME: 'lastName',
  LAST_NAME_TEMP: 'lastNameTemp',

  EMAIL: 'email',
  EMAIL_TEMP: 'emailTemp',

  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',

  LANGUAGE: 'language',

  ACCOUNT_INIT_STATE: 'accountInitState',

  COUNTRY: 'country',
  COUNTRY_TEMP: 'countryTemp',

  ERROR: 'error',
  SUCCESS: 'success',
  LOADING: 'loading'
}

function handleGetAccountDetailsResponse(payload) {
  const { firstName, secondName, email, id, language, authId, countryISO } =
    payload
  return {
    userId: id,

    authId,

    firstName,
    firstNameTemp: firstName,

    lastName: secondName,
    lastNameTemp: secondName,

    email,
    emailTemp: email,

    language,
    country: countryISO,
    countryTemp: countryISO,

    error: {},
    success: {}
  }
}

function handleResetState(state) {
  const { firstName, lastName, email, country } = state

  return {
    firstNameTemp: firstName,
    lastNameTemp: lastName,
    emailTemp: email,
    oldPassword: '',
    newPassword: '',
    countryTemp: country,
    error: {},
    success: {}
  }
}

const handleUserUpdateResponse = (payload, state) => {
  const { firstName, secondName, email, language, countryISO } = payload
  const {
    firstName: firstNameTemp,
    lastName: lastNameTemp,
    email: emailTemp,
    country: countryTemp
  } = state
  const oldUser = { firstNameTemp, lastNameTemp, emailTemp, countryTemp }
  const updatedUser = {
    firstNameTemp: firstName,
    lastNameTemp: secondName,
    emailTemp: email,
    countryTemp: countryISO
  }
  const success = Object.getOwnPropertyNames(updatedUser).reduce(
    (curr, nextProperty) => {
      if (updatedUser[nextProperty] !== oldUser[nextProperty]) {
        return { ...curr, [nextProperty]: buildSuccessText(nextProperty) }
      } else {
        return curr
      }
    },
    {}
  )
  return {
    firstName,
    lastName: secondName,
    email,
    language,
    country: countryISO,
    success,
    error: {}
  }
}

const buildSuccessText = (field) => {
  switch (field) {
    case ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP:
      return MESSAGE_TEXT.FIRST_NAME_CHANGED
    case ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP:
      return MESSAGE_TEXT.LAST_NAME_CHANGED
    case ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP:
      return MESSAGE_TEXT.VERIFICATION_EMAIL_SENT
    case ACCOUNT_PROFILE_DISPATCH.COUNTRY_TEMP:
      return MESSAGE_TEXT.COUNTRY_CHANGED
    default:
      return ''
  }
}

const handleUpdateUserErrors = ({ code }) => {
  switch (code) {
    case BW_COGNITO_ERRORS.ALIAS_EXISTS_EXCEPTION:
      return {
        emailTemp: customConfig.emailAlreadyExistsLabel
      }
    default:
      return {
        emailTemp: BW_ERROR_TEXT.SOMETHING_WENT_WRONG
      }
  }
}

const handleChangePasswordErrors = ({ code }) => {
  switch (code) {
    case BW_COGNITO_ERRORS.NOT_AUTHORIZED_EXCEPTION:
      return {
        oldPassword: ERROR_TEXT.INCORRECT_OLD_PASSWORD
      }
    case BW_COGNITO_ERRORS.LIMIT_EXCEEDED_EXCEPTION:
      return {
        newPassword: BW_ERROR_TEXT.ATTEMPT_LIMIT_EXCEEDED
      }
    default:
      return {
        newPassword: BW_ERROR_TEXT.SOMETHING_WENT_WRONG
      }
  }
}
