import React, { memo } from 'react'

import RedeemOption from '../../../components/redeem/RedeemOption'
import customConfig from 'customConfig'

const RedeemOptionWrapper = (props) => {
  return <RedeemOption icon={customConfig.giftCardIcon} {...props} />
}

export default memo(RedeemOptionWrapper)
