import React, { memo } from 'react'

import OptionalAndMandatoryCheckboxes from '../../../components/shared/optionalAndMandatoryCheckboxes/OptionalAndMandatoryCheckboxes'

const TermsNotAcceptedWrapper = ({
  signupProfile,
  onCancelTerms,
  onAcceptTermsSubmit,
  dispatchActionSignup
}) => {
  return (
    <OptionalAndMandatoryCheckboxes
      signupProfile={signupProfile}
      onAcceptTermsSubmit={onAcceptTermsSubmit}
      onCancelTerms={onCancelTerms}
      dispatchActionSignup={dispatchActionSignup}
    />
  )
}

export default memo(TermsNotAcceptedWrapper)
