import React, { memo } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons'
import {
  faClock,
  faCheckCircle,
  faTimesCircle,
  faArrowRotateLeft,
  faHourglass
} from '@fortawesome/pro-light-svg-icons'

import {
  TRANSACTION_TYPE,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_LABEL
} from '../../constants/transaction'
import BWPanel from '../BWPanel'
import BWText from '../BWText'
import {
  cssFontSize,
  cssGap,
  CSS_JUSTIFY_CONTENT_START,
  CSS_JUSTIFY_CONTENT_END,
  CSS_GRID,
  CSS_FULL_WIDTH,
  CSS_TEXT_ALIGN_RIGHT,
  CSS_ALIGN_ITEMS_CENTER
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes/units'
import { BW_COLOR_ACCENT } from '../../constants/theme'

const BWTransactionCard = ({
  heading,
  type,
  label,
  title,
  date,
  status,
  iconColor = BW_COLOR_ACCENT,
  onClick,
  anchor
}) => {
  const statusIcon = getStatusIcon(status)
  return (
    <BWPanel gap={10} padding={15} onClick={onClick} anchor={anchor}>
      <TransactionCardContainer>
        <TransactionTypeContainer>
          <TransactionIcon icon={getTypeIcon(type)} color={iconColor} />
          <BWText label={label} size={24} weight={800} />
        </TransactionTypeContainer>
        <TransactionStatusContainer>
          <TransactionDetailContainer>
            {heading && <BWText label={heading} size={12} weight={700} />}
            <BWText label={title} size={12} />
            <BWText label={date} size={12} />
          </TransactionDetailContainer>
          {statusIcon && (
            <TransactionIcon icon={statusIcon} color={iconColor} />
          )}
        </TransactionStatusContainer>
      </TransactionCardContainer>
    </BWPanel>
  )
}

export default memo(BWTransactionCard)

const TransactionCardContainer = styled.div`
  ${CSS_GRID};
  grid-template-columns: auto 1fr;
  ${CSS_FULL_WIDTH};
`

const TransactionIcon = styled(FontAwesomeIcon)`
  color: ${({ theme: { colors }, color }) => colors[color]};
  ${cssFontSize(SIZE_UNIT.MD)};
`

const TransactionTypeContainer = styled.div`
  ${CSS_GRID};
  grid-template-columns: repeat(2, auto);
  ${CSS_JUSTIFY_CONTENT_START};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${cssGap(SIZE_UNIT.SM)};
`

const TransactionStatusContainer = styled.div`
  ${CSS_GRID};
  grid-template-columns: repeat(2, auto);
  ${CSS_JUSTIFY_CONTENT_END};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${cssGap(SIZE_UNIT.SM)};
`

const TransactionDetailContainer = styled.div`
  ${CSS_GRID};
  ${CSS_TEXT_ALIGN_RIGHT};
`

const getTypeIcon = (type) => {
  switch (type) {
    case TRANSACTION_TYPE.REDEEM:
      return faMinus
    default:
      return faPlus
  }
}

const getStatusIcon = (status) => {
  switch (status) {
    case TRANSACTION_STATUS.PENDING:
      return faClock
    case TRANSACTION_STATUS.SUCCESS:
      return faCheckCircle
    case TRANSACTION_STATUS.FAILED:
      return faTimesCircle
    case TRANSACTION_STATUS.CANCELLED:
      return faArrowRotateLeft
    case TRANSACTION_STATUS_LABEL.FUTURE:
      return faHourglass
    default:
      return ''
  }
}
