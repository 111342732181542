import React, { memo } from 'react'
import {
  BWPanelHeader,
  BWInput,
  BWLink,
  BWPanelContent,
  requiredRule,
  passwordRule
} from 'src/libs/qb-brand-web-components'
import { Link } from 'react-router-dom'

import { FORGOT_PASSWORD_URL, LOG_IN_URL } from '../../constants/navigation'
import { RESET_PASSWORD_PROFILE_DISPATCH } from '../../store/reducers/auth/resetPassword'
import { ERROR_TEXT } from '../../constants/messages'
import Button from '../shared/button/Button'
import Panel from '../shared/panel/Panel'
import customConfig from 'customConfig'

const ResetPassword = ({
  onFormSubmit,
  dispatchAction,
  resetPasswordProfile: { error, newPassword },
  t
}) => {
  const title = t(customConfig.resetPasswordLabel)

  const tryAgainForgotPassword = (error) => {
    if (error && error === t(ERROR_TEXT.PASSWORD_LINK_EXPIRED)) {
      return (
        <>
          {error}{' '}
          <BWLink
            as={Link}
            size={10}
            label={t('reset-password.please-try-again')}
            to={FORGOT_PASSWORD_URL}
          />
        </>
      )
    }
    return t(error)
  }

  return (
    <>
      <Panel onFormSubmit={onFormSubmit} as='form' data-testid='reset-password'>
        <BWPanelHeader
          title={title}
          description={t('reset-password.enter-new-password-to-reset')}
        />
        <BWPanelContent>
          <BWInput
            label={t('common.password')}
            eyePassword
            error={tryAgainForgotPassword(
              error[RESET_PASSWORD_PROFILE_DISPATCH.NEW_PASSWORD]
            )}
            value={newPassword}
            validate={[requiredRule, passwordRule]}
            onChange={(value) =>
              dispatchAction(
                RESET_PASSWORD_PROFILE_DISPATCH.NEW_PASSWORD,
                value
              )
            }
          />
        </BWPanelContent>
        <Button label={title} type='submit' />
        <BWLink label={t('common.cancel')} to={LOG_IN_URL} as={Link} />
      </Panel>
    </>
  )
}

export default memo(ResetPassword)
