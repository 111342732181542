import React, { memo } from 'react'
import { BWOutsideLink } from 'src/libs/qb-brand-web-components'
import { Trans } from 'react-i18next'

const TermsPolicies = ({ i18nKey, links }) => {
  return (
    <Trans i18nKey={i18nKey} i18nIsDynamicList>
      {links.map(({ label, to }, index) => {
        return <BWOutsideLink key={index} label={label} to={to} />
      })}
    </Trans>
  )
}

export default memo(TermsPolicies)
