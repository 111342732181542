import {
  EXCHANGE_PROVIDER_ACTION,
  EXCHANGE_ACTION,
  BUY_POINTS_ACTION
} from '../../constants/actions'

// EXCHANGE PROVIDERS
const changeExchangeProvidersState = (property, value) => ({
  type: EXCHANGE_PROVIDER_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeExchangeProvidersInputAndErrorState = (property, value) => ({
  type: EXCHANGE_PROVIDER_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const connectExchangeProvider = (brandId, connectAccountNumber) => ({
  type: EXCHANGE_PROVIDER_ACTION.ON_CONNECT_EXCHANGE_PROVIDER,
  payload: {
    membership: {
      brandId,
      connectAccountNumber
    }
  }
})

const updateExchangeProvider = (
  membershipId,
  brandId,
  connectAccountNumber
) => ({
  type: EXCHANGE_PROVIDER_ACTION.ON_UPDATE_EXCHANGE_PROVIDER,
  payload: {
    membership: {
      membershipId,
      brandId,
      connectAccountNumber
    }
  }
})

const resetExchangeProvider = () => ({
  type: EXCHANGE_PROVIDER_ACTION.RESET_STATE
})

// RECEIVE EXTERNAL REWARDS
const receiveExternalRewardFromBrandPoints = (
  amount,
  provider,
  accountNumber
) => ({
  type: EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS,
  payload: {
    amount,
    provider,
    accountNumber
  }
})

const getRewardTypesForBrand = (brandId) => ({
  type: EXCHANGE_PROVIDER_ACTION.ON_GET_REWARD_TYPES_FOR_BRAND,
  payload: brandId
})

const getCryptoExchangeRates = () => ({
  type: EXCHANGE_ACTION.ON_START_CRYPTO_EXCHANGE_RATES_POLLING
})

const stopGetCryptoExchangeRates = () => ({
  type: EXCHANGE_ACTION.ON_STOP_CRYPTO_EXCHANGE_RATES_POLLING
})

const generateBuyPointsCheckoutSession = (pointsAmount) => ({
  type: BUY_POINTS_ACTION.ON_GENERATE_CHECKOUT_SESSION,
  payload: {
    pointsAmount
  }
})

const changeBuyPointsState = (property, value) => ({
  type: BUY_POINTS_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const getPaymentSessionDetails = (sessionId) => ({
  type: BUY_POINTS_ACTION.ON_GET_PAYMENT_SESSION,
  payload: {
    sessionId
  }
})

export {
  // EXCHANGE PROVIDERS
  changeExchangeProvidersState,
  changeExchangeProvidersInputAndErrorState,
  connectExchangeProvider,
  updateExchangeProvider,
  resetExchangeProvider,
  // RECEIVE EXTERNAL REWARDS
  receiveExternalRewardFromBrandPoints,
  // GET REWARD TYPES FOR BRAND
  getRewardTypesForBrand,
  // GET CRYPTO EXCHANGE RATE
  getCryptoExchangeRates,
  stopGetCryptoExchangeRates,
  generateBuyPointsCheckoutSession,
  changeBuyPointsState,
  getPaymentSessionDetails
}
