import React, { memo } from 'react'

import AuthorizedLayout from '../../../components/authorizedLayout/AuthorizedLayout'
import PoweredByWrapper from 'wrappers/poweredBy/PoweredByWrapper'

const AuthorizedLayoutWrapper = (props) => {
  return <AuthorizedLayout poweredBy={<PoweredByWrapper />} {...props} />
}

export default memo(AuthorizedLayoutWrapper)
