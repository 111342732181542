import { BW_AUTH_STATE } from 'src/libs/qb-brand-web-components'

import {
  formatUserDeviceDetails,
  isExistsElementFromList
} from './app/app.helpers'
import { LOYALTY_EVENT_TYPES } from '../constants/transactions'

const isAuthenticated = (authState) => {
  return authState === BW_AUTH_STATE.AUTHENTICATED
}

const isNotAuthenticated = (authState) => {
  return authState === BW_AUTH_STATE.NOT_AUTHENTICATED
}

const isCheckingAuth = (authState) => {
  return authState === BW_AUTH_STATE.CHECKING
}

const formatUserDeviceDetailsForCreation = () => {
  return {
    user_session_data: formatUserDeviceDetails()
  }
}

const checkIsAlreadyAuthenticated = () => {
  const { state } = window.history
  if (state) {
    return Boolean(state?.usr?.alreadyAuthenticated)
  } else {
    return false
  }
}

const checkIsDisabledPointsBurn = (campaignIds, transactions) => {
  if (transactions && transactions.length > 0) {
    return !transactions.some((transaction) =>
      isCodeToPointsCampaignIdMatching(
        transaction.loyaltyEventType,
        transaction?.code?.campaign?.id,
        campaignIds
      )
    )
  }
  return true
}

const isCodeToPointsCampaignIdMatching = (
  loyaltyEventType,
  targetCampaignId,
  campaignIds
) => {
  return (
    loyaltyEventType === LOYALTY_EVENT_TYPES.CODE_TO_POINTS &&
    isExistsElementFromList(campaignIds, targetCampaignId)
  )
}

export {
  isAuthenticated,
  isNotAuthenticated,
  isCheckingAuth,
  formatUserDeviceDetailsForCreation,
  checkIsAlreadyAuthenticated,
  checkIsDisabledPointsBurn,
  isCodeToPointsCampaignIdMatching
}
