import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons'

import BWPanel from '../BWPanel'
import BWText from '../BWText'
import {
  CSS_MAIN_GRID_CONTAINER,
  cssFontSize,
  CSS_ALIGN_ITEMS_CENTER,
  CSS_GRID,
  CSS_FULL_WIDTH
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes/units'
import { BW_COLOR_ACCENT } from '../../constants/theme'

const BWLoading = ({ main, label, iconColor = BW_COLOR_ACCENT, isInvert }) => {
  return (
    <LoadingContainer main={main} data-testid='bw-loading'>
      <BWPanel
        gap={15}
        css={isInvert ? { backgroundColor: 'transparent' } : CSS_LOADING_STYLES}
      >
        <LoadingIcon
          icon={faCircleNotch}
          color={isInvert ? BW_COLOR_ACCENT : iconColor}
          spin
        />
        <BWText alignCenter size={18} label={label} weight={700} />
      </BWPanel>
    </LoadingContainer>
  )
}

export default memo(BWLoading)

const LoadingIcon = styled(FontAwesomeIcon)`
  color: ${({ theme: { colors }, color }) => colors[color]};
  ${cssFontSize(SIZE_UNIT.XL4)};
`

const LoadingContainer = styled.div`
  ${({ main }) => main && CSS_MAIN_GRID_CONTAINER};
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  grid-template-rows: auto;
  margin: auto 0;
  ${CSS_FULL_WIDTH};
`

const CSS_LOADING_STYLES = css`
  padding: 90px 30px;
  height: fit-content;
`
