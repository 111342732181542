import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { SIZE_UNIT } from '../../themes/units'
import { cssFontSize, cssFontWeight, cssPadding } from '../../styles/shared'
import { MESSAGE_TYPE } from '../../constants/constants'

const BWHelpText = ({ help, success, error, invertErrorColor }) => {
  let element
  if (success) {
    element = <MessageContainer>{success}</MessageContainer>
  } else if (error) {
    element = (
      <MessageContainer color={invertErrorColor && MESSAGE_TYPE.ERROR}>
        {error}
      </MessageContainer>
    )
  } else if (help) {
    element = <MessageContainer>{help}</MessageContainer>
  }
  return (
    <DisplayHelpContainer data-testid='bw-help-text'>
      {element}
    </DisplayHelpContainer>
  )
}

export default memo(BWHelpText)

export const DisplayHelpContainer = styled.div`
  ${cssPadding(SIZE_UNIT.NONE, SIZE_UNIT.MD)}
  line-height: 14px;
`

export const MessageContainer = styled.span`
  display: block;
  ${cssFontSize(SIZE_UNIT.XS2)};
  ${cssFontWeight(SIZE_UNIT.MD)};
  ${(props) => messageDefaultStyles(props)};
`

const messageDefaultStyles = ({ theme: { colors }, color }) =>
  color &&
  css`
    color: ${colors[color]};
  `
