import React, { memo } from 'react'
import styled from '@emotion/styled'

import BWText from '../BWText'
import {
  CSS_ALIGN_ITEMS_CENTER,
  CSS_GRID,
  CSS_GRID_COLUMN,
  CSS_JUSTIFY_CONTENT_CENTER
} from '../../styles/shared'
import { BW_COLOR_ZAMBEZI } from '../../constants/theme'

const BWPoweredByQb = ({ qbxLogo }) => {
  return (
    <PoweredByContainer data-testid='bw-powered-by-qb'>
      <BWText color={BW_COLOR_ZAMBEZI} size={12} label='Powered by' />
      <QbIconContainer src={qbxLogo} alt='Icon' />
    </PoweredByContainer>
  )
}

export default memo(BWPoweredByQb)

const PoweredByContainer = styled.div`
  ${CSS_GRID};
  ${CSS_GRID_COLUMN};
  ${CSS_JUSTIFY_CONTENT_CENTER};
  ${CSS_ALIGN_ITEMS_CENTER};
  gap: 5px;
  color: ${({ theme }) => theme.colors?.zambezi};
  padding-top: 15px;
  padding-bottom: 10px;
`

const QbIconContainer = styled.img`
  height: 22px;
  margin-top: 1px;
`
