import React, { memo } from 'react'
import {
  CSS_GRID_TWO_COLUMNS,
  BWPageContent,
  CSS_NAV_PADDING_SMALL,
  CSS_NAV_PADDING_DEFAULT
} from 'src/libs/qb-brand-web-components'
import OverviewNavPanelWrapper from 'wrappers/OverviewNavPanelWrapper'
import OverviewMainPanelWrapper from 'wrappers/OverviewMainPanelWrapper'

const Overview = ({ navigationItems, ...restProps }) => {
  const navItemsLength = navigationItems.length
  const navItemsIsOdd = navItemsLength % 2 !== 0
  const navItemsMoreLength = navItemsLength > 2
  return (
    <>
      <OverviewMainPanelWrapper
        navItemsMoreLength={navItemsMoreLength}
        {...restProps}
      />
      <BWPageContent css={CSS_GRID_TWO_COLUMNS}>
        {navigationItems.map((navItemProps, index) => (
          <OverviewNavPanelWrapper
            {...navItemProps}
            key={index}
            fullColumn={navItemsIsOdd && index === navItemsLength - 1}
            customCss={
              navItemsMoreLength
                ? CSS_NAV_PADDING_SMALL
                : CSS_NAV_PADDING_DEFAULT
            }
          />
        ))}
      </BWPageContent>
    </>
  )
}

export default memo(Overview)
