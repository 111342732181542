import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { REDEEM_ACTION } from '../../../constants/actions'

export const redeemProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REDEEM_ACTION.ON_GET_SIGNED_URL:
      return mergeToNewObject(state, {
        signedUrl: ''
      })

    case REDEEM_ACTION.ON_GET_SIGNED_URL_SUCCESS:
      return mergeToNewObject(state, {
        signedUrl: payload.url
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  signedUrl: ''
}
