import React, { memo } from 'react'
import {
  BWPanel,
  BWText,
  BWDisplay,
  titleCase,
  BWPanelContent
} from 'src/libs/qb-brand-web-components'

import Facebook from '../socialMediaButtons/Facebook'
import Google from '../socialMediaButtons/Google'
import { SOCIAL_MEDIA } from '../../constants/app'
import Button from '../shared/button/Button'
import { LABEL_TEXT } from '../../constants/messages'

const DisplayEmailAddress = ({ email, signedUpVia, t }) => {
  return (
    <BWPanel>
      <BWPanelContent>
        <BWText
          label={t('user-account.logged-in-with', {
            provider: titleCase(signedUpVia)
          })}
          size={18}
          weight={700}
          alignCenter
        />
        <BWDisplay label={email} disabled />
      </BWPanelContent>
      <SocialMediaButton
        disabled
        type={signedUpVia}
        label={LABEL_TEXT.SIGNUP}
      />
    </BWPanel>
  )
}

export default memo(DisplayEmailAddress)

const SocialMediaButton = ({ type, ...restProps }) => {
  switch (type) {
    case SOCIAL_MEDIA.FACEBOOK:
      return <Facebook {...restProps} />
    case SOCIAL_MEDIA.GOOGLE:
      return <Google {...restProps} />
    default:
      return <Button {...restProps} />
  }
}
