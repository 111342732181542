import React, { memo } from 'react'
import { BWLink } from 'src/libs/qb-brand-web-components'

import customConfig from 'customConfig'

const OverviewHowToEarnWrapper = ({ t, onClick }) => {
  return <BWLink label={t(customConfig.howToEarnLabel)} onClick={onClick} />
}

export default memo(OverviewHowToEarnWrapper)
