import { mergeMap, catchError, map, tap, filter, mapTo } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import {
  AUTH_ACTION,
  REWARDS_ACTION,
  AUTH_SIGNUP_ACTION,
  AUTH_LOGIN_ACTION,
  AUTH_FORGOT_PASSWORD_ACTION,
  AUTH_RESET_PASSWORD_ACTION,
  POINTS_REWARD_ACTION,
  NFT_ACTION
} from '../../constants/actions'
import authService from '../../services/auth'
import {
  extractCognitoUser,
  extractCognitoUserEmailMissing,
  extractCognitoUserRewardCode,
  extractRewardCode
} from '../../util/auth.helpers'
import {
  AUTH_REWARD_CODE_ATTR,
  AUTH_TERMS_ACCEPTED_ATTR
} from '../../constants/auth'
import {
  getEmailMissing,
  removeEmailMissing,
  setEmailMissing,
  setRewardCode
} from '../../util/local.helpers'
import { logAuthErrors } from '../../util/app/appSignal.helpers'

// AUTH SIGNUP
const handleResendSignupEmail = (action$) =>
  action$.pipe(
    ofType(AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL),
    mergeMap(({ payload }) =>
      authService.resendSignUpEmail$(payload.email).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL_FAILED, err, {
            email: payload.email
          })
          return of({
            type: AUTH_SIGNUP_ACTION.ON_RESEND_EMAIL_FAILED,
            payload: err
          })
        })
      )
    )
  )

const handleSignUp = (action$) =>
  action$.pipe(
    ofType(AUTH_SIGNUP_ACTION.ON_SIGN_UP),
    mergeMap(({ payload }) =>
      authService
        .signup$(payload.email, payload.password, payload.attributes)
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_SIGNUP_ACTION.ON_SIGN_UP_SUCCESS,
            payload: data
          })),
          catchError((err) =>
            of({ type: AUTH_SIGNUP_ACTION.ON_SIGN_UP_FAILED, payload: err })
          )
        )
    )
  )

const handleConfirmSignup = (action$) =>
  action$.pipe(
    ofType(AUTH_SIGNUP_ACTION.ON_CONFIRM),
    mergeMap(({ payload }) =>
      authService.confirmSignUp$(payload.email, payload.code).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_SIGNUP_ACTION.ON_CONFIRM_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(AUTH_SIGNUP_ACTION.ON_CONFIRM_FAILED, err, {
            email: payload.email
          })
          return of({
            type: AUTH_SIGNUP_ACTION.ON_CONFIRM_FAILED,
            payload: err
          })
        })
      )
    )
  )

// AUTH_ACTION LOGIN
const handleLogin = (action$) =>
  action$.pipe(
    ofType(AUTH_LOGIN_ACTION.ON_LOG_IN),
    mergeMap(({ payload }) =>
      authService.logIn$(payload.email, payload.password).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(AUTH_LOGIN_ACTION.ON_LOG_IN_FAILED, err, {
            email: payload.email
          })
          return of({ type: AUTH_LOGIN_ACTION.ON_LOG_IN_FAILED, payload: err })
        })
      )
    )
  )

const handleSocialMediaLogin = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_LOGIN_ACTION.ON_SOCIAL_MEDIA_LOG_IN),
    tap(() => {
      const rewardCode = extractRewardCode(state$)
      if (rewardCode) {
        setRewardCode(rewardCode)
      }
      removeEmailMissing()
    }),
    mergeMap(({ payload }) =>
      authService.socialMediaLogIn$(payload.provider).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_LOGIN_ACTION.ON_SOCIAL_MEDIA_LOG_IN_SUCCESS,
          payload: data
        })),
        catchError((err) =>
          of({
            type: AUTH_LOGIN_ACTION.ON_SOCIAL_MEDIA_LOG_IN_FAILED,
            payload: err
          })
        )
      )
    )
  )

const handleSetEmailMissingOnUserFailed = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_GET_CURRENT_USER_FAILED),
    mapTo({
      type: AUTH_ACTION.ON_UPDATE_EMAIL_MISSING,
      payload: getEmailMissing()
    })
  )

const handleCloseEmailMissing = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_CLOSE_EMAIL_MISSING),
    tap(() => {
      removeEmailMissing()
    }),
    mapTo({
      type: AUTH_ACTION.ON_UPDATE_EMAIL_MISSING,
      payload: false
    })
  )

// RESET & CHANGE PASSWORD
const handleRequestPasswordChange = (action$) =>
  action$.pipe(
    ofType(AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE),
    mergeMap(({ payload }) =>
      authService.requestPasswordChange$(payload.email).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(
            AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_FAILED,
            err,
            { email: payload.email }
          )
          return of({
            type: AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_FAILED,
            payload: err
          })
        })
      )
    )
  )

const handleResetPassword = (action$) =>
  action$.pipe(
    ofType(AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD),
    mergeMap(({ payload }) =>
      authService
        .resetPassword$(payload.email, payload.code, payload.newPassword)
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            logAuthErrors(
              AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_FAILED,
              err,
              { email: payload.email }
            )
            return of({
              type: AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_FAILED,
              payload: err
            })
          })
        )
    )
  )

const handleChangePassword = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_CHANGE_PASSWORD),
    mergeMap(({ payload }) =>
      authService
        .changePassword$(
          extractCognitoUser(state$),
          payload.oldPassword,
          payload.newPassword
        )
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_ACTION.ON_CHANGE_PASSWORD_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            logAuthErrors(AUTH_ACTION.ON_CHANGE_PASSWORD_FAILED, err, {})
            return of({
              type: AUTH_ACTION.ON_CHANGE_PASSWORD_FAILED,
              payload: err
            })
          })
        )
    )
  )

// CURRENT USER
const handleGetCurrentUserCredentials = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_GET_CURRENT_USER),
    mergeMap((_) =>
      authService.getCurrentUser$().pipe(
        tap((data) => logger(data)),
        map((data) => {
          const emailMissing = extractCognitoUserEmailMissing(data)
          if (emailMissing) {
            setEmailMissing(true)
          }
          return {
            type: AUTH_ACTION.ON_GET_CURRENT_USER_SUCCESS,
            payload: data
          }
        }),
        catchError((err) =>
          of({ type: AUTH_ACTION.ON_GET_CURRENT_USER_FAILED, payload: err })
        )
      )
    )
  )

// SIGN OUT
const handleSignOut = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_SIGN_OUT),
    mergeMap((_) =>
      authService.signOut$().pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_ACTION.ON_SIGN_OUT_SUCCESS,
          payload: data
        })),
        catchError((err) =>
          of({ type: AUTH_ACTION.ON_SIGN_OUT_FAILED, payload: err })
        )
      )
    )
  )

// COGNITO USER ATTRIBUTES
const handleUpdateUserAttributes = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES),
    mergeMap(({ payload }) =>
      authService
        .updateUserAttributes$(extractCognitoUser(state$), payload.attributes)
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            logAuthErrors(
              AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_FAILED,
              err,
              payload.attributes
            )
            return of({
              type: AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_FAILED,
              payload: err
            })
          })
        )
    )
  )

const handleGetCognitoUserAfterUpdate = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_SUCCESS),
    mergeMap((_) =>
      authService.getCurrentUser$().pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS,
          payload: data
        })),
        catchError((err) =>
          of({
            type: AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_FAILED,
            payload: err
          })
        )
      )
    )
  )

const handleGetCognitoUserAfterTermsAcceptance = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_ACCEPT_TERMS_SUCCESS),
    mergeMap((_) =>
      authService.getCurrentUser$().pipe(
        map((data) => ({
          type: AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_TERMS_ACCEPTANCE_SUCCESS,
          payload: data
        })),
        catchError((err) =>
          of({
            type: AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_TERMS_ACCEPTANCE_FAILED,
            payload: err
          })
        )
      )
    )
  )

// REWARD CODE DELETION
const handleDeleteAttributeCodeIfUsed = (action$, state$) =>
  action$.pipe(
    ofType(REWARDS_ACTION.ON_REWARD_CODE_EXISTS),
    filter(
      ({ payload }) =>
        payload.used &&
        extractCognitoUserRewardCode(extractCognitoUser(state$)) ===
          payload.code
    ),
    mapTo({
      type: AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES,
      payload: {
        attributes: {
          [AUTH_REWARD_CODE_ATTR]: ''
        }
      }
    })
  )

const handleDeleteAttributeCodeOnRedeemResponse = (action$, state$) =>
  action$.pipe(
    ofType(
      POINTS_REWARD_ACTION.ON_RECEIVE_POINTS_FROM_CODE_SUCCESS,
      NFT_ACTION.ON_RECEIVE_NFT_FROM_CODE_SUCCESS,
      REWARDS_ACTION.ON_REWARD_CODE_DOES_NOT_EXIST
    ),
    tap((data) => logger(data)),
    filter(
      ({ payload }) =>
        extractCognitoUserRewardCode(extractCognitoUser(state$)) ===
        payload.code
    ),
    mapTo({
      type: AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES,
      payload: {
        attributes: {
          [AUTH_REWARD_CODE_ATTR]: ''
        }
      }
    })
  )

const handleUpdateAcceptTermsOnSocialSignup = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_ACCEPT_TERMS),
    mergeMap(() =>
      authService
        .updateUserAttributes$(extractCognitoUser(state$), {
          [AUTH_TERMS_ACCEPTED_ATTR]: String(true)
        })
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_ACTION.ON_ACCEPT_TERMS_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            logAuthErrors(AUTH_ACTION.ON_ACCEPT_TERMS_FAILED, err, {})
            return of({
              type: AUTH_ACTION.ON_ACCEPT_TERMS_FAILED,
              payload: err
            })
          })
        )
    )
  )

export const authEpic = combineEpics(
  // AUTH_SIGNUP
  handleResendSignupEmail,
  handleConfirmSignup,

  // AUTH
  handleLogin,
  handleSocialMediaLogin,
  handleRequestPasswordChange,
  handleResetPassword,
  handleGetCurrentUserCredentials,
  handleSignOut,
  handleSignUp,
  handleUpdateUserAttributes,
  handleChangePassword,
  handleGetCognitoUserAfterUpdate,
  handleGetCognitoUserAfterTermsAcceptance,
  handleSetEmailMissingOnUserFailed,
  handleCloseEmailMissing,
  handleUpdateAcceptTermsOnSocialSignup,

  // REWARD CODE DELETION
  handleDeleteAttributeCodeIfUsed,
  handleDeleteAttributeCodeOnRedeemResponse
)
