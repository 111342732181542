import React, { memo } from 'react'
import {
  BWCheckbox,
  BWPanelContent,
  BW_LABEL_TEXT
} from 'src/libs/qb-brand-web-components'

import { SIGNUP_PROFILE_DISPATCH } from '../../../store/reducers/auth/signup'
import TermsPoliciesWrapper from 'wrappers/TermsPoliciesWrapper'

const TermsCheckbox = ({
  dispatchAction,
  signupProfile: { error, termsAccepted },
  t
}) => {
  return (
    <BWPanelContent data-testid='terms-accept-checkbox'>
      <BWCheckbox
        label={
          <TermsPoliciesWrapper
            t={t}
            i18nKey={BW_LABEL_TEXT.ACCEPT_TERMS_POLICY}
          />
        }
        checked={termsAccepted}
        onChange={() =>
          dispatchAction(SIGNUP_PROFILE_DISPATCH.TERMS_ACCEPTED, !termsAccepted)
        }
        error={error[SIGNUP_PROFILE_DISPATCH.TERMS_ACCEPTED]}
      />
    </BWPanelContent>
  )
}

export default memo(TermsCheckbox)
