import { combineEpics } from 'redux-observable'

import { authEpic } from './auth'
import { mainEpic } from './main'
import { accountEpic } from './account'
import { transactionsEpic } from './transactions'
import { rewardsEpic } from './rewards'
import { pointsEpic } from './points'
import { redeemEpic } from './redeem'
import { surveyEpic } from './survey'
import { exchangeEpic } from './exchange'
import { nftEpic } from './nft'
import { cashbackEpic } from './cashback'
import { engravingsEpic } from './engravings'
import { stakingEpic } from './staking'
import { earnEpic } from './earn'

export const rootEpic = combineEpics(
  authEpic,
  mainEpic,
  accountEpic,
  transactionsEpic,
  rewardsEpic,
  pointsEpic,
  redeemEpic,
  surveyEpic,
  exchangeEpic,
  nftEpic,
  cashbackEpic,
  engravingsEpic,
  stakingEpic,
  earnEpic
)
