import Color from 'color'

const hexToRGBA = (hexCode, opacity) => {
  let hex = hexCode?.replace('#', '')
  if (hex?.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  const r = parseInt(hex?.substring(0, 2), 16)
  const g = parseInt(hex?.substring(2, 4), 16)
  const b = parseInt(hex?.substring(4, 6), 16)
  return `rgba(${r},${g},${b},${opacity / 100})`
}

const getShadows = (colors) => ({
  0: 'none',
  1: `0px 6px 18px ${hexToRGBA(colors?.black, 20)}`,
  2: `0px 12px 18px ${hexToRGBA(colors?.black, 20)}`
})

const getColorByBackground = (backgroundColor, colors) => {
  const bgColor = Color(backgroundColor)
  return bgColor.luminosity() > 0.5 ? colors?.liteBlack : colors?.liteWhite
}

const isColorIsLight = (color) => {
  const elementColor = Color(color)
  return elementColor.luminosity() > 0.9
}

const getColorByLuminosity = (color, colors) => {
  return isColorIsLight(color) ? colors?.liteBlack : color
}

const getCustomTextColor = ({ disabled, colors, color, themeColor }) => {
  if (disabled) {
    return colors.liteWhite
  } else if (color) {
    return colors[color]
  }
  return getColorByBackground(themeColor, colors)
}

const getCustomTextColorByDefaultThemeColor = ({
  disabled,
  colors,
  color,
  themeColor
}) => {
  if (disabled) {
    return colors.liteWhite
  } else if (color) {
    return colors[color]
  }
  return themeColor
}

const getCustomThemeColor = ({ disabled, colors, color, noDefault }) => {
  if (disabled) {
    return colors.gray
  } else if (color && colors[color]) {
    return colors[color]
  }
  return !noDefault ? colors.accent : ''
}

export {
  hexToRGBA,
  getShadows,
  getColorByBackground,
  getColorByLuminosity,
  isColorIsLight,
  getCustomTextColor,
  getCustomTextColorByDefaultThemeColor,
  getCustomThemeColor
}
