import { MEDIA_TYPES } from '../constants/media'

const isMediaTypeImage = (type) => {
  return type === MEDIA_TYPES.IMAGE
}

const isMediaTypeAudio = (type) => {
  return type === MEDIA_TYPES.AUDIO
}

const isMediaTypeVideo = (type) => {
  return type === MEDIA_TYPES.VIDEO
}

export { isMediaTypeImage, isMediaTypeAudio, isMediaTypeVideo }
