const userAgent = () => {
  return navigator.userAgent.toLowerCase()
}

const isMobile = () => {
  return /(android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini)/i.test(
    userAgent()
  )
}

const isIOSPlatform = () => {
  return /(iphone|ipad|ipod)/i.test(navigator.platform.toLowerCase())
}

const isAndroid = () => {
  return /(android)/i.test(userAgent())
}

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(userAgent())
}

const isInStandaloneMode = () => {
  return 'standalone' in window.navigator && window.navigator.standalone
}

export { isMobile, isIOSPlatform, isAndroid, isSafari, isInStandaloneMode }
