import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { CSS_TEXT_ALIGN_CENTER, CSS_TEXT_UPPERCASE } from '../../styles/shared'

const BWText = ({ label, ...restProps }) => (
  <TextContainer data-testid='bw-text' {...restProps}>
    {label}
  </TextContainer>
)

export default memo(BWText)

const TextContainer = styled.span`
  ${({ theme, color, size, weight, alignCenter, textUppercase, customCss }) =>
    textBoxStyles(
      theme.colors,
      size,
      color,
      weight,
      alignCenter,
      textUppercase,
      customCss
    )};
`

const textBoxStyles = (
  colors,
  size,
  themeColor,
  weight,
  alignCenter,
  textUppercase,
  customCss
) => css`
  ${`font-size: ${size || 16}px`};
  ${weight && `font-weight: ${weight}`};
  ${themeColor && `color: ${colors[themeColor]}`};
  ${alignCenter && CSS_TEXT_ALIGN_CENTER};
  ${textUppercase && CSS_TEXT_UPPERCASE};
  ${customCss && customCss}
`
