export const CAMPAIGN_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

export const REWARD_CODE_STATUS = {
  CODE_USED: 'code_used',
  CAMPAIGN_OVER: 'campaign_over',
  CAMPAIGN_INACTIVE: 'campaign_inactive'
}
