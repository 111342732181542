const COE_SSO_SESSION_PATH = '/coe/sso-session'
const SIGNED_PATH = '/signed-url'
const LOYALIZE_API = 'https://api.loyalize.com/resources/stores/'
const NFT_MEMBERSHIPS_PATH = '/nft-memberships'
const BALANCES_SHOULD_FETCH_NFT_URL_PARAM = '?should_fetch_nft_url=true'

const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}

const API_RESPONSE = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  CONFLICT: 409
}

export {
  COE_SSO_SESSION_PATH,
  SIGNED_PATH,
  LOYALIZE_API,
  NFT_MEMBERSHIPS_PATH,
  HTTP_METHOD,
  API_RESPONSE,
  BALANCES_SHOULD_FETCH_NFT_URL_PARAM
}
