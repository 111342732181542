import React from 'react'
import {
  faCheckCircle,
  faUserCircle,
  faTimesCircle
} from '@fortawesome/pro-light-svg-icons'
import {
  BWText,
  BWLink,
  BW_LABEL_TEXT,
  BW_COLOR_ACCENT,
  BW_COLOR_ERROR,
  BW_COLOR_SUCCESS
} from 'src/libs/qb-brand-web-components'
import { useTranslation } from 'react-i18next'

import Popup from '../../shared/popup/Popup'
import brandConfig from 'brandConfig'
import { MESSAGE_TEXT, ERROR_TEXT } from '../../../constants/messages'
import {
  isResendSignupVerifyFailed,
  isResendSignupVerifySuccess
} from '../../../util/auth.helpers'
import customConfig from 'customConfig'

const SignupSuccess = ({
  onCancel,
  signupState,
  email,
  onResendVerification,
  t
}) => (
  <Popup
    title={
      !customConfig?.hideSignUpSuccessTitle && t('sign-up.you-are-signed-up')
    }
    description={t(customConfig.checkYourEmailLabel, { email })}
    icon={faCheckCircle}
    color={BW_COLOR_SUCCESS}
    cancelLabel={t(BW_LABEL_TEXT.CLOSE)}
    onCancel={onCancel}
  >
    <BWLink
      label={t(customConfig.resendVerificationEmailLabel)}
      onClick={onResendVerification}
      alignCenter
    />
    {isResendSignupVerifySuccess(signupState) && (
      <BWText
        label={t(MESSAGE_TEXT.VERIFICATION_EMAIL_SENT)}
        size={12}
        color={BW_COLOR_ACCENT}
        alignCenter
      />
    )}
    {isResendSignupVerifyFailed(signupState) && (
      <BWText
        label={t(ERROR_TEXT.RESEND_EMAIL_VERIFICATION_FAILED)}
        size={12}
        color={BW_COLOR_ERROR}
        alignCenter
      />
    )}
  </Popup>
)

const SignupVerificationSuccess = ({ onSubmit, t }) => (
  <Popup
    title={t(customConfig.youAreVerifiedLabel)}
    description={t(customConfig.youSuccessfullyVerifiedLabel)}
    icon={faUserCircle}
    color={BW_COLOR_ACCENT}
    submitLabel={t('login.log-in')}
    onFormSubmit={onSubmit}
  />
)

const TermsNotAccepted = ({
  onAcceptTermsSubmit,
  onCancelTerms,
  children,
  t
}) => {
  return (
    <Popup
      title={t(customConfig.termsAcceptPageTitle)}
      description={t(customConfig.carefullyReadTermsLabel)}
      submitLabel={t('sign-up.sign-up')}
      onFormSubmit={onAcceptTermsSubmit}
      cancelLabel={t('common.back')}
      onCancel={onCancelTerms}
      color={BW_COLOR_ACCENT}
    >
      {children}
    </Popup>
  )
}

const EmailMissing = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation()
  return (
    <Popup
      title={t('missing-email.missing-email')}
      description={t('missing-email.need-email-to-send-points', {
        brand: brandConfig.fbAppName
      })}
      icon={faTimesCircle}
      color={BW_COLOR_ERROR}
      submitLabel={t('missing-email.share-email')}
      onFormSubmit={onSubmit}
      cancelLabel={t(BW_LABEL_TEXT.CLOSE)}
      onCancel={onCancel}
    />
  )
}

export {
  SignupSuccess,
  SignupVerificationSuccess,
  TermsNotAccepted,
  EmailMissing
}
