import React, { memo } from 'react'
import {
  BWPanel,
  BWText,
  isColorIsLight,
  CSS_CUSTOM_PANEL_PADDING_30,
  BWIcon,
  FrontCenterContent,
  CSS_FULL_WIDTH
} from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import { faIcons } from '@fortawesome/pro-light-svg-icons'

import { CircleBgContainer } from '../shared/common'
import NftChip from '../shared/nft/NftChip'
import {
  CSS_GRID_COLUMN_THREE,
  CSS_REDEMPTION_OPTION_PROGRESS,
  CUSTOM_REDEEM_PANEL,
  faIconCss,
  moonIconCss
} from '../../styles/shared'
import NftIcon from '../shared/nft/NftIcon'
import { formatTicks } from '../../util/string.helpers'

const RedeemOption = ({
  currentUserPoints,
  redeemOption: { value, metadata, name, isNft },
  fullColumn,
  onClick,
  tokenSymbol,
  customPanelCss,
  noBgOpacity,
  hideRewardTitle,
  icon
}) => {
  const theme = useTheme()
  const pointsRequiredToClaim = 100 - (currentUserPoints * 100) / value
  const checkPointsRequired = pointsRequiredToClaim > 0
  const bgImage = metadata?.coverImage
  return (
    <BWPanel
      bgImage={bgImage}
      onClick={onClick}
      noBgOpacity={noBgOpacity}
      gap={10}
      css={[
        CUSTOM_REDEEM_PANEL,
        CSS_CUSTOM_PANEL_PADDING_30,
        fullColumn && [CSS_GRID_COLUMN_THREE, CSS_FULL_WIDTH],
        customPanelCss
      ]}
      anchor
    >
      {isNft && (
        <>
          <NftChip />
          {!bgImage && <NftIcon metadata={metadata} />}
        </>
      )}
      <FrontCenterContent>
        <CircleBgContainer isBgColor={!checkPointsRequired}>
          {checkPointsRequired ? (
            <StatusPieChart status={pointsRequiredToClaim} bgImage={bgImage} />
          ) : (
            <BWIcon
              icon={isNft ? faIcons : icon}
              moonIconCss={moonIconCss(theme)}
              faIconCss={faIconCss(theme)}
            />
          )}
        </CircleBgContainer>
        <BWText
          alignCenter
          label={`${formatTicks(currentUserPoints)} / ${formatTicks(
            value
          )} ${tokenSymbol}`}
          size={12}
        />
        {!hideRewardTitle && <BWText alignCenter label={name} weight={700} />}
      </FrontCenterContent>
    </BWPanel>
  )
}

export default memo(RedeemOption)

const StatusPieChart = styled.div`
  ${CSS_REDEMPTION_OPTION_PROGRESS};
  border-radius: 50%;
  ${(props) => cssStatusPieChart(props)};
`

const cssStatusPieChart = ({ theme: { colors }, status, bgImage }) => {
  const slugColor =
    !bgImage && isColorIsLight(colors.main)
      ? colors.liteBlack
      : colors?.liteWhite
  return css`
    border: 2px solid ${slugColor};
    background: conic-gradient(
      ${slugColor} calc(${100 - status} * 1%),
      transparent 0%
    );
  `
}
