import React, { memo } from 'react'
import styled from '@emotion/styled'
import { Trans } from 'react-i18next'
import { BWOutsideLink, BWText } from 'src/libs/qb-brand-web-components'
import TranslationTextWithBold from '../shared/translation/TranslationTextWithBold'

const HowManyPoints = ({ data }) => {
  return (
    <PointsList>
      {data?.map((transProps, index) => {
        const { i18nKey, number, point, tokenSymbol } = transProps
        return (
          <li key={index}>
            {transProps.url ? (
              <TranslationWithLink {...transProps} />
            ) : (
              <TranslationTextWithBold
                i18nKey={i18nKey}
                values={{ number }}
                label={`${point ? point : ''} ${tokenSymbol}`}
              />
            )}
          </li>
        )
      })}
    </PointsList>
  )
}

export default memo(HowManyPoints)

const PointsList = styled.ul`
  padding-left: 20px;
`

const TranslationWithLink = ({
  i18nKey,
  url,
  to,
  point,
  tokenSymbol,
  number
}) => {
  return (
    <Trans i18nKey={i18nKey} values={number && { number }} i18nIsDynamicList>
      <BWOutsideLink label={url} to={to} />
      <BWText label={`${point ? point : ''} ${tokenSymbol}`} weight='bold' />
    </Trans>
  )
}
