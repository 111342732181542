export const countriesWithContinent = {
  AFG: { country: 'Afghanistan', continent: 'Asia' },
  ALA: { country: 'Åland Islands', continent: 'Europe' },
  ALB: { country: 'Albania', continent: 'Europe' },
  DZA: { country: 'Algeria', continent: 'Africa' },
  ASM: { country: 'American Samoa', continent: 'Australia / Oceania' },
  AND: { country: 'Andorra', continent: 'Europe' },
  AGO: { country: 'Angola', continent: 'Africa' },
  AIA: { country: 'Anguilla', continent: 'North America' },
  ATG: { country: 'Antigua and Barbuda', continent: 'North America' },
  ARG: { country: 'Argentina', continent: 'South America' },
  ARM: { country: 'Armenia', continent: 'Asia' },
  ABW: { country: 'Aruba', continent: 'North America' },
  AUS: { country: 'Australia', continent: 'Australia / Oceania' },
  AUT: { country: 'Austria', continent: 'Europe' },
  AZE: { country: 'Azerbaijan', continent: 'Asia' },
  BHS: { country: 'Bahamas', continent: 'North America' },
  BHR: { country: 'Bahrain', continent: 'Asia' },
  BGD: { country: 'Bangladesh', continent: 'Asia' },
  BRB: { country: 'Barbados', continent: 'North America' },
  BLR: { country: 'Belarus', continent: 'Europe' },
  BEL: { country: 'Belgium', continent: 'Europe' },
  BLZ: { country: 'Belize', continent: 'North America' },
  BEN: { country: 'Benin', continent: 'Africa' },
  BMU: { country: 'Bermuda', continent: 'North America' },
  BTN: { country: 'Bhutan', continent: 'Asia' },
  BOL: { country: 'Bolivia', continent: 'South America' },
  BES: {
    country: 'Bonaire, Sint Eustatius and Saba',
    continent: 'North America'
  },
  BIH: { country: 'Bosnia and Herzegovina', continent: 'Europe' },
  BWA: { country: 'Botswana', continent: 'Africa' },
  BVT: { country: 'Bouvet Island', continent: 'North America' },
  BRA: { country: 'Brazil', continent: 'South America' },
  IOT: { country: 'British Indian Ocean Territory', continent: 'Africa' },
  BRN: { country: 'Brunei Darussalam', continent: 'Asia' },
  BGR: { country: 'Bulgaria', continent: 'Europe' },
  BFA: { country: 'Burkina Faso', continent: 'Africa' },
  BDI: { country: 'Burundi', continent: 'Africa' },
  CPV: { country: 'Cabo Verde', continent: 'Africa' },
  KHM: { country: 'Cambodia', continent: 'Asia' },
  CMR: { country: 'Cameroon', continent: 'Africa' },
  CAN: { country: 'Canada', continent: 'North America' },
  CYM: { country: 'Cayman Islands', continent: 'North America' },
  CAF: { country: 'Central African Republic', continent: 'Africa' },
  TCD: { country: 'Chad', continent: 'Africa' },
  CHL: { country: 'Chile', continent: 'South America' },
  CHN: { country: 'China', continent: 'Asia' },
  CXR: { country: 'Christmas Island', continent: 'Australia / Oceania' },
  CCK: { country: 'Cocos Islands', continent: 'Australia / Oceania' },
  COL: { country: 'Colombia', continent: 'South America' },
  COM: { country: 'Comoros', continent: 'Africa' },
  COD: { country: 'Congo', continent: 'Africa' },
  COK: { country: 'Cook Islands', continent: 'Australia / Oceania' },
  CRI: { country: 'Costa Rica', continent: 'North America' },
  CIV: { country: "Côte d'Ivoire", continent: 'Africa' },
  HRV: { country: 'Croatia', continent: 'Europe' },
  CUB: { country: 'Cuba', continent: 'North America' },
  CUW: { country: 'Curaçao', continent: 'North America' },
  CYP: { country: 'Cyprus', continent: 'Asia' },
  CZE: { country: 'Czechia', continent: 'Europe' },
  DNK: { country: 'Denmark', continent: 'Europe' },
  DJI: { country: 'Djibouti', continent: 'Africa' },
  DMA: { country: 'Dominica', continent: 'North America' },
  DOM: { country: 'Dominican Republic', continent: 'North America' },
  ECU: { country: 'Ecuador', continent: 'South America' },
  EGY: { country: 'Egypt', continent: 'Africa' },
  SLV: { country: 'El Salvador', continent: 'North America' },
  GNQ: { country: 'Equatorial Guinea', continent: 'Africa' },
  ERI: { country: 'Eritrea', continent: 'Africa' },
  EST: { country: 'Estonia', continent: 'Europe' },
  SWZ: { country: 'Eswatini', continent: 'Africa' },
  ETH: { country: 'Ethiopia', continent: 'Africa' },
  FLK: { country: 'Falkland Islands', continent: 'North America' },
  FRO: { country: 'Faroe Islands', continent: 'Europe' },
  FJI: { country: 'Fiji', continent: 'Australia / Oceania' },
  FIN: { country: 'Finland', continent: 'Europe' },
  FRA: { country: 'France', continent: 'Europe' },
  GUF: { country: 'French Guiana', continent: 'North America' },
  PYF: { country: 'French Polynesia', continent: 'Australia / Oceania' },
  ATF: { country: 'French Southern Territories', continent: 'Africa' },
  GAB: { country: 'Gabon', continent: 'Africa' },
  GMB: { country: 'Gambia', continent: 'Africa' },
  GEO: { country: 'Georgia', continent: 'Asia' },
  DEU: { country: 'Germany', continent: 'Europe' },
  GHA: { country: 'Ghana', continent: 'Africa' },
  GIB: { country: 'Gibraltar', continent: 'Europe' },
  GRC: { country: 'Greece', continent: 'Europe' },
  GRL: { country: 'Greenland', continent: 'North America' },
  GRD: { country: 'Grenada', continent: 'North America' },
  GLP: { country: 'Guadeloupe', continent: 'North America' },
  GUM: { country: 'Guam', continent: 'Australia / Oceania' },
  GTM: { country: 'Guatemala', continent: 'North America' },
  GGY: { country: 'Guernsey', continent: 'Europe' },
  GIN: { country: 'Guinea', continent: 'Africa' },
  GNB: { country: 'Guinea-Bissau', continent: 'Africa' },
  GUY: { country: 'Guyana', continent: 'South America' },
  HTI: { country: 'Haiti', continent: 'North America' },
  HMD: {
    country: 'Heard Island and McDonald Islands',
    continent: 'Australia / Oceania'
  },
  VAT: { country: 'Holy See', continent: 'Europe' },
  HND: { country: 'Honduras', continent: 'North America' },
  HKG: { country: 'Hong Kong', continent: 'Asia' },
  HUN: { country: 'Hungary', continent: 'Europe' },
  ISL: { country: 'Iceland', continent: 'Europe' },
  IND: { country: 'India', continent: 'Asia' },
  IDN: { country: 'Indonesia', continent: 'Asia' },
  IRN: { country: 'Iran', continent: 'Asia' },
  IRQ: { country: 'Iraq', continent: 'Asia' },
  IRL: { country: 'Ireland', continent: 'Europe' },
  IMN: { country: 'Isle of Man', continent: 'Europe' },
  ISR: { country: 'Israel', continent: 'Asia' },
  ITA: { country: 'Italy', continent: 'Europe' },
  JAM: { country: 'Jamaica', continent: 'North America' },
  JPN: { country: 'Japan', continent: 'Asia' },
  JEY: { country: 'Jersey', continent: 'Europe' },
  JOR: { country: 'Jordan', continent: 'Asia' },
  KAZ: { country: 'Kazakhstan', continent: 'Asia' },
  KEN: { country: 'Kenya', continent: 'Africa' },
  KIR: { country: 'Kiribati', continent: 'Australia / Oceania' },
  PRK: { country: 'Korea, North', continent: 'Asia' },
  KOR: { country: 'Korea, South', continent: 'Asia' },
  KWT: { country: 'Kuwait', continent: 'Asia' },
  KGZ: { country: 'Kyrgyzstan', continent: 'Asia' },
  LAO: { country: "Lao People's Democratic Republic", continent: 'Asia' },
  LVA: { country: 'Latvia', continent: 'Europe' },
  LBN: { country: 'Lebanon', continent: 'Asia' },
  LSO: { country: 'Lesotho', continent: 'Africa' },
  LBR: { country: 'Liberia', continent: 'Africa' },
  LBY: { country: 'Libya', continent: 'Africa' },
  LIE: { country: 'Liechtenstein', continent: 'Europe' },
  LTU: { country: 'Lithuania', continent: 'Europe' },
  LUX: { country: 'Luxembourg', continent: 'Europe' },
  MAC: { country: 'Macao', continent: 'Asia' },
  MDG: { country: 'Madagascar', continent: 'Africa' },
  MWI: { country: 'Malawi', continent: 'Africa' },
  MYS: { country: 'Malaysia', continent: 'Asia' },
  MDV: { country: 'Maldives', continent: 'Asia' },
  MLI: { country: 'Mali', continent: 'Africa' },
  MLT: { country: 'Malta', continent: 'Europe' },
  MHL: { country: 'Marshall Islands', continent: 'Australia / Oceania' },
  MTQ: { country: 'Martinique', continent: 'North America' },
  MRT: { country: 'Mauritania', continent: 'Africa' },
  MUS: { country: 'Mauritius', continent: 'Africa' },
  MYT: { country: 'Mayotte', continent: 'Africa' },
  MEX: { country: 'Mexico', continent: 'North America' },
  FSM: { country: 'Micronesia', continent: 'Australia / Oceania' },
  MDA: { country: 'Moldova, Republic of', continent: 'Europe' },
  MCO: { country: 'Monaco', continent: 'Europe' },
  MNG: { country: 'Mongolia', continent: 'Asia' },
  MNE: { country: 'Montenegro', continent: 'Europe' },
  MSR: { country: 'Montserrat', continent: 'North America' },
  MAR: { country: 'Morocco', continent: 'Africa' },
  MOZ: { country: 'Mozambique', continent: 'Africa' },
  MMR: { country: 'Myanmar', continent: 'Asia' },
  NAM: { country: 'Namibia', continent: 'Africa' },
  NRU: { country: 'Nauru', continent: 'Australia / Oceania' },
  NPL: { country: 'Nepal', continent: 'Asia' },
  NLD: { country: 'Netherlands', continent: 'Europe' },
  NCL: { country: 'New Caledonia', continent: 'Australia / Oceania' },
  NZL: { country: 'New Zealand', continent: 'Australia / Oceania' },
  NIC: { country: 'Nicaragua', continent: 'North America' },
  NER: { country: 'Niger', continent: 'Africa' },
  NGA: { country: 'Nigeria', continent: 'Africa' },
  NIU: { country: 'Niue', continent: 'Australia / Oceania' },
  NFK: { country: 'Norfolk Island', continent: 'Australia / Oceania' },
  MKD: { country: 'North Macedonia', continent: 'Europe' },
  MNP: {
    country: 'Northern Mariana Islands',
    continent: 'Australia / Oceania'
  },
  NOR: { country: 'Norway', continent: 'Europe' },
  OMN: { country: 'Oman', continent: 'Asia' },
  PAK: { country: 'Pakistan', continent: 'Asia' },
  PLW: { country: 'Palau', continent: 'Australia / Oceania' },
  PSE: { country: 'Palestine, State of', continent: 'Asia' },
  PAN: { country: 'Panama', continent: 'North America' },
  PNG: { country: 'Papua New Guinea', continent: 'Australia / Oceania' },
  PRY: { country: 'Paraguay', continent: 'South America' },
  PER: { country: 'Peru', continent: 'South America' },
  PHL: { country: 'Philippines', continent: 'Asia' },
  PCN: { country: 'Pitcairn', continent: 'Australia / Oceania' },
  POL: { country: 'Poland', continent: 'Europe' },
  PRT: { country: 'Portugal', continent: 'Europe' },
  PRI: { country: 'Puerto Rico', continent: 'North America' },
  QAT: { country: 'Qatar', continent: 'Asia' },
  REU: { country: 'Réunion', continent: 'Africa' },
  ROU: { country: 'Romania', continent: 'Europe' },
  RUS: { country: 'Russian Federation', continent: 'Europe' },
  RWA: { country: 'Rwanda', continent: 'Africa' },
  BLM: { country: 'Saint Barthélemy', continent: 'North America' },
  SHN: {
    country: 'Saint Helena, Ascension and Tristan da Cunha',
    continent: 'Africa'
  },
  KNA: { country: 'Saint Kitts and Nevis', continent: 'North America' },
  LCA: { country: 'Saint Lucia', continent: 'North America' },
  MAF: { country: 'Saint Martin', continent: 'North America' },
  SPM: { country: 'Saint Pierre and Miquelon', continent: 'North America' },
  VCT: {
    country: 'Saint Vincent and the Grenadines',
    continent: 'North America'
  },
  WSM: { country: 'Samoa', continent: 'Australia / Oceania' },
  SMR: { country: 'San Marino', continent: 'Europe' },
  STP: { country: 'Sao Tome and Principe', continent: 'Africa' },
  SAU: { country: 'Saudi Arabia', continent: 'Asia' },
  SEN: { country: 'Senegal', continent: 'Africa' },
  SRB: { country: 'Serbia', continent: 'Europe' },
  SYC: { country: 'Seychelles', continent: 'Africa' },
  SLE: { country: 'Sierra Leone', continent: 'Africa' },
  SGP: { country: 'Singapore', continent: 'Asia' },
  SXM: { country: 'Sint Maarten', continent: 'North America' },
  SVK: { country: 'Slovakia', continent: 'Europe' },
  SVN: { country: 'Slovenia', continent: 'Europe' },
  SLB: { country: 'Solomon Islands', continent: 'Australia / Oceania' },
  SOM: { country: 'Somalia', continent: 'Africa' },
  ZAF: { country: 'South Africa', continent: 'Africa' },
  SGS: {
    country: 'South Georgia and the South Sandwich Islands',
    continent: 'North America'
  },
  SSD: { country: 'South Sudan', continent: 'Africa' },
  ESP: { country: 'Spain', continent: 'Europe' },
  LKA: { country: 'Sri Lanka', continent: 'Asia' },
  SDN: { country: 'Sudan', continent: 'Africa' },
  SUR: { country: 'Suriname', continent: 'South America' },
  SJM: { country: 'Svalbard and Jan Mayen', continent: 'Europe' },
  SWE: { country: 'Sweden', continent: 'Europe' },
  CHE: { country: 'Switzerland', continent: 'Europe' },
  SYR: { country: 'Syrian Arab Republic', continent: 'Asia' },
  TWN: { country: 'Taiwan', continent: 'Asia' },
  TJK: { country: 'Tajikistan', continent: 'Asia' },
  TZA: { country: 'Tanzania', continent: 'Africa' },
  THA: { country: 'Thailand', continent: 'Asia' },
  TLS: { country: 'Timor-Leste', continent: 'Asia' },
  TGO: { country: 'Togo', continent: 'Africa' },
  TKL: { country: 'Tokelau', continent: 'Australia / Oceania' },
  TON: { country: 'Tonga', continent: 'Australia / Oceania' },
  TTO: { country: 'Trinidad and Tobago', continent: 'North America' },
  TUN: { country: 'Tunisia', continent: 'Africa' },
  TUR: { country: 'Turkey', continent: 'Asia' },
  TKM: { country: 'Turkmenistan', continent: 'Asia' },
  TCA: { country: 'Turks and Caicos Islands', continent: 'North America' },
  TUV: { country: 'Tuvalu', continent: 'Australia / Oceania' },
  UGA: { country: 'Uganda', continent: 'Africa' },
  UKR: { country: 'Ukraine', continent: 'Europe' },
  ARE: { country: 'United Arab Emirates', continent: 'Asia' },
  GBR: { country: 'United Kingdom', continent: 'Europe' },
  USA: { country: 'United States of America', continent: 'North America' },
  UMI: {
    country: 'United States Minor Outlying Islands',
    continent: 'Australia / Oceania'
  },
  URY: { country: 'Uruguay', continent: 'South America' },
  UZB: { country: 'Uzbekistan', continent: 'Asia' },
  VUT: { country: 'Vanuatu', continent: 'Australia / Oceania' },
  VEN: { country: 'Venezuela', continent: 'South America' },
  VNM: { country: 'Viet Nam', continent: 'Asia' },
  VGB: { country: 'Virgin Islands (British)', continent: 'North America' },
  VIR: { country: 'Virgin Islands (U.S.)', continent: 'North America' },
  WLF: { country: 'Wallis and Futuna', continent: 'Australia / Oceania' },
  ESH: { country: 'Western Sahara', continent: 'Africa' },
  YEM: { country: 'Yemen', continent: 'Asia' },
  ZMB: { country: 'Zambia', continent: 'Africa' },
  ZWE: { country: 'Zimbabwe', continent: 'Africa' }
}
