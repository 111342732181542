import { POINTS_REWARD_ACTION } from '../../constants/actions'

const receiveRewardFromBrandPoints = (redeemOption) => ({
  type: POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS,
  payload: {
    redeemOption
  }
})

export { receiveRewardFromBrandPoints }
