import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { AUTH_ACTION, EARN_VIR_ACTION } from '../../constants/actions'
import apiService from '../../services/api'
import { commonParser } from '../../util/apiParser.helpers'
import {
  extractAccessToken,
  wrapUserAccessToken
} from '../../util/auth.helpers'
import { extractAccountProfile } from '../../util/epics.helpers'
import authService from '../../services/auth'

const handleGetXProfileEpic = (action$, state$) =>
  action$.pipe(
    ofType(EARN_VIR_ACTION.GET_PROFILE),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getXProfile(extractAccountProfile(state$).authId, accessToken)
          .pipe(
            map(({ response }) => ({
              type: EARN_VIR_ACTION.GET_PROFILE_SUCCESS,
              payload: commonParser(response)
            })),
            catchError((err) =>
              of({
                type: EARN_VIR_ACTION.GET_PROFILE_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleDisconnectXAccountEpic = (action$, state$) =>
  action$.pipe(
    ofType(EARN_VIR_ACTION.DISCONNECT_ACCOUNT),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .disconnectXAccount(extractAccountProfile(state$).authId, accessToken)
          .pipe(
            map(() => ({
              type: EARN_VIR_ACTION.DISCONNECT_ACCOUNT_SUCCESS
            })),
            catchError((err) =>
              of({
                type: EARN_VIR_ACTION.DISCONNECT_ACCOUNT_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleConnectXAccount = (action$) =>
  action$.pipe(
    ofType(EARN_VIR_ACTION.CONNECT_ACCOUNT),
    mergeMap((_) =>
      authService.getUserSession$().pipe(
        map((userSession) => {
          return {
            type: AUTH_ACTION.ON_REFRESHED_AUTH,
            payload: extractAccessToken(userSession)
          }
        })
      )
    )
  )

export const earnEpic = combineEpics(
  handleGetXProfileEpic,
  handleDisconnectXAccountEpic,
  handleConnectXAccount
)
