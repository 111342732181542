export const ENGRAVING_MESSAGE_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected'
}

export const NFT_EXCLUSIVE_CONTENT_STATUS = {
  AVAILABLE: 'available',
  REDEEMED: 'redeemed'
}

export const NFT_ACTIONS_STEPS = {
  TRANSFER: 'transfer',
  SELL: 'sell',
  MORE_ACTIONS: 'moreActions',
  CANCEL_LISTING: 'cancelListing',
  CONFIRM_TRANSFER: 'confirmTransfer',
  CONFIRM_SELL: 'confirmSell',
  TRANSFER_CONFIRMED: 'transferConfirmed',
  NFT_LISTED: 'nftListed'
}

export const NFT_CONTENT_TYPE = {
  TEXT: 'text',
  MARKDOWN: 'markdown',
  HTML: 'html',
  URL: 'url'
}
