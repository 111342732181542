import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  BWPanel,
  BWPanelContent,
  CSS_GRID,
  SIZE_UNIT,
  CSS_ALIGN_ITEMS_CENTER,
  cssFontSize,
  cssCustomFontSize,
  getColorByLuminosity,
  CSS_BORDER_RADIUS_HALF,
  CSS_FULL_WIDTH,
  cssCustomGap,
  CSS_JUSTIFY_CONTENT_END,
  CSS_TEXT_ALIGN_RIGHT,
  CSS_FLEX_COLUMN,
  CSS_FLEX,
  CSS_JUSTIFY_CONTENT_CENTER,
  CSS_ITEMS_CENTER
} from 'src/libs/qb-brand-web-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  CSS_REDEMPTION_OPTION_PROGRESS,
  OVERVIEW_PADDING_DEFAULT,
  OVERVIEW_PADDING_SMALL
} from '../../styles/shared'

const WhiteBgPanel = ({ children }) => {
  return (
    <BWPanel padding={30} color='white'>
      {children}
    </BWPanel>
  )
}

const CircleBgContainer = styled.div`
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${CSS_BORDER_RADIUS_HALF};
  ${(props) => circleBgStyles(props)};
`

const MediumIconContainer = styled(FontAwesomeIcon)`
  ${cssFontSize(SIZE_UNIT.LG)};
  color: ${({ theme: { colors }, color }) =>
    getColorByLuminosity(color ? colors[color] : colors.main, colors)};
`

const MediumIcon = styled(FontAwesomeIcon)`
  ${cssFontSize(SIZE_UNIT.LG)};
`

const SmallIconContainer = styled(FontAwesomeIcon)`
  ${cssCustomFontSize()};
`

const PanelContentWithGap = styled(BWPanelContent)`
  gap: 5px;
`

const HorizontalLine = styled.hr`
  ${CSS_FULL_WIDTH};
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors.liteWhite};
`

const ExchangeDivider = styled.div`
  ${CSS_GRID};
  ${CSS_FULL_WIDTH};
  ${cssCustomGap(4)};
  margin-top: 6px;
`

const ProviderCardContainer = styled.div`
  ${CSS_GRID};
  ${CSS_FULL_WIDTH};
  ${CSS_ALIGN_ITEMS_CENTER};
  grid-template-columns: auto 1fr;
`

const ProviderLabel = styled.div`
  ${CSS_FLEX};
  ${CSS_FLEX_COLUMN};
  ${CSS_TEXT_ALIGN_RIGHT};
  ${CSS_JUSTIFY_CONTENT_END};
  ${CSS_FULL_WIDTH};
  ${cssCustomGap(({ gap }) => gap || 10)};
`

const OverviewTitleContent = styled.div`
  ${CSS_GRID};
  ${CSS_JUSTIFY_CONTENT_CENTER};
  ${CSS_FULL_WIDTH};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${({ navItemsMoreLength }) =>
    navItemsMoreLength ? OVERVIEW_PADDING_SMALL : OVERVIEW_PADDING_DEFAULT};
`

const FullAutoContent = styled.div`
  ${CSS_GRID};
  ${CSS_FULL_WIDTH};
  ${CSS_ITEMS_CENTER};
  grid-template-columns: 1fr auto;
`

export {
  WhiteBgPanel,
  CircleBgContainer,
  MediumIconContainer,
  PanelContentWithGap,
  SmallIconContainer,
  HorizontalLine,
  MediumIcon,
  ExchangeDivider,
  ProviderCardContainer,
  ProviderLabel,
  OverviewTitleContent,
  FullAutoContent
}

const circleBgStyles = ({ theme: { colors }, isBgColor, customCss }) => {
  return [
    isBgColor
      ? css`
          ${CSS_REDEMPTION_OPTION_PROGRESS};
          border: 2px solid ${colors?.liteWhite};
          background-color: ${colors?.liteWhite};
        `
      : css`
          height: 34px;
          width: 34px;
        `,
    customCss
  ]
}
