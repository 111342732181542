import {
  logger,
  BW_TESTING,
  deepMerge,
  isProduction
} from 'src/libs/qb-brand-web-components'
import AppSignal from '@appsignal/javascript'

import brandConfig from 'brandConfig'
import testBrandConfig from '../brands/common/testBrandConfig'
import { parseJson } from '../util/app/app.helpers'
import localConfig from '../brands/common/localConfig'

const isEnvProduction = isProduction()

const APP_CONFIG = {
  API_URL: getApiUrl(),
  COGNITO_CONFIG: getCognitoConfig(),
  RECAPTCHA_ID: getRecaptchaId(),
  GA_TRACKING_ID: getGaTrackingId(),
  FACEBOOK_PIXEL_ID: getFacebookPixelId(),
  GOOGLE_TAG_MANAGER_ID: getGoogleTagManagerId(),
  HOTJAR_CONFIG: getHotjarConfig(),
  BRAND_ID: getBrandId(),
  SLS_URL: getSlsUrl()
}

const GOOGLE_RECAPTCHA_SCRIPT_URL =
  'https://www.google.com/recaptcha/api.js?render='

export { APP_CONFIG, GOOGLE_RECAPTCHA_SCRIPT_URL }

function getApiUrl() {
  return process.env.REACT_APP_API_URL
}

function getCognitoConfig() {
  logger(`Current Environment is: ${process.env.REACT_APP_ENV}`)
  if (isEnvProduction) {
    return buildCognitoConfig(brandConfig.cognito)
  }
  return buildCognitoConfig({
    ...testBrandConfig.cognito,
    redirectUri: getCognitoRedirectUri()
  })
}

function buildCognitoConfig({
  region,
  poolId,
  clientId,
  poolDomain,
  redirectUri
}) {
  return wrapDefaultCognitoConfig({
    region,
    userPoolId: poolId,
    userPoolWebClientId: clientId,
    oauth: {
      domain: poolDomain,
      redirectSignIn: redirectUri,
      redirectSignOut: redirectUri
    }
  })
}

function wrapDefaultCognitoConfig(cognitoConfig) {
  return deepMerge(
    {
      mandatorySignIn: true,
      oauth: {
        scope: [
          'phone',
          'email',
          'profile',
          'aws.cognito.signin.user.admin',
          'openid'
        ],
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      }
    },
    cognitoConfig
  )
}

function getCognitoRedirectUri() {
  if (process.env.REACT_APP_ENV === BW_TESTING) {
    return testBrandConfig.cognito.redirectUri
  }
  return localConfig.cognito.redirectUri
}

function getRecaptchaId() {
  return process.env.REACT_APP_RECAPTCHA_ID || localConfig.recaptchaId
}

function getGaTrackingId() {
  if (isEnvProduction) {
    return brandConfig.gaTrackingId
  }
  return testBrandConfig.gaTrackingId
}

function getFacebookPixelId() {
  if (isEnvProduction) {
    return brandConfig.facebookPixelId
  }
  return testBrandConfig.facebookPixelId
}

function getGoogleTagManagerId() {
  if (isEnvProduction) {
    return brandConfig.googleTagManagerId
  }
  return testBrandConfig.googleTagManagerId
}

function getHotjarConfig() {
  const hotjarEnv = process.env.REACT_APP_HOTJAR_CONFIG
  return hotjarEnv ? parseJson(hotjarEnv) : localConfig.hotjarConfig
}

function getBrandId() {
  if (isEnvProduction) {
    return brandConfig.brandId
  }
  return testBrandConfig.brandId
}

function getSlsUrl() {
  return process.env.REACT_APP_SLS_URL
}

export function getAppSignalConfig(userId) {
  const instance = appSignalInstance()
  if (instance) {
    const tags = {
      brand_id: APP_CONFIG.BRAND_ID
    }
    if (userId) {
      tags.user_id = userId
    }
    return {
      instance,
      tags
    }
  }
  return {}
}

export function appSignalInstance() {
  const key = process.env.REACT_APP_APP_SIGNAL_KEY
  if (!key) {
    return null
  }
  const brandId = APP_CONFIG.BRAND_ID
  return new AppSignal({
    key,
    namespace: brandId
  })
}
