import React, { memo } from 'react'
import { faLanguage } from '@fortawesome/pro-light-svg-icons'

import BWMenuItem from '../BWTopBar/BWMenuItem'
import BWLink from '../BWLink'
import { BW_COLOR_ACCENT } from '../../constants/theme'

const BWLanguageLink = ({
  isNotMenu,
  color = BW_COLOR_ACCENT,
  ...restProps
}) => {
  return isNotMenu ? (
    <BWLink size={12} color={color} {...restProps} />
  ) : (
    <BWMenuItem icon={faLanguage} {...restProps} />
  )
}

export default memo(BWLanguageLink)
