import React, { memo } from 'react'
import {
  BWText,
  BWInput,
  BWPanelContent,
  requiredRule
} from 'src/libs/qb-brand-web-components'

import { ACCOUNT_PROFILE_DISPATCH } from '../../store/reducers/account/account'
import Button from '../shared/button/Button'
import Panel from '../shared/panel/Panel'

const ChangePersonalInfo = ({
  onFormSubmit,
  dispatchAction,
  accountProfile: { error, success, firstNameTemp, lastNameTemp },
  t
}) => {
  return (
    <Panel onFormSubmit={onFormSubmit} as='form'>
      <BWPanelContent>
        <BWText
          label={t('user-account.change-personal-info')}
          size={18}
          weight={700}
          alignCenter
        />
        <BWInput
          label={t('common.first-name')}
          error={t(error[ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP])}
          success={t(success[ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP])}
          value={firstNameTemp}
          validate={[requiredRule]}
          onChange={(value) =>
            dispatchAction(ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP, value)
          }
        />
        <BWInput
          label={t('common.last-name')}
          error={t(error[ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP])}
          success={t(success[ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP])}
          value={lastNameTemp}
          validate={[requiredRule]}
          onChange={(value) =>
            dispatchAction(ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP, value)
          }
        />
      </BWPanelContent>
      <Button label={t('common.save')} type='submit' />
    </Panel>
  )
}

export default memo(ChangePersonalInfo)
