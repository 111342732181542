import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { nftParams } from '../utils/link.helpers'
import { LABEL_TEXT } from '../../../constants/messages'

const overviewNftLink = (t) => {
  return mergeToNewObject(nftParams(), {
    label: t(LABEL_TEXT.YOUR_NFTS)
  })
}

export default overviewNftLink
