const colors = {
  main: '#374E69',
  accent: '#3BAC89',
  success: '#00BB00',
  error: '#FF0000',
  gray: '#A7A7A7',
  liteBlack: '#2D2D2D',
  liteWhite: '#F5F5F5',
  black: '#000000',
  white: '#FFFFFF',
  zambezi: '#5F5F5F',
  facebook: '#1877F2'
}

const defaultTheme = {
  colors
}

export default defaultTheme
