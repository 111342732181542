import {
  mergeToNewObject,
  extractInputValueAndError
} from 'src/libs/qb-brand-web-components'

import {
  DEFAULT_CONTAINER_STATE,
  REDEEM_POINTS_PROCESS_STEP,
  REWARD_NFT_PROCESS_STEP,
  REWARD_POINTS_PROCESS_STEP
} from '../../../constants/containerStates'
import {
  EXCHANGE_ACTION,
  NFT_ACTION,
  POINTS_REWARD_ACTION,
  REWARDS_ACTION,
  SURVEY_ACTION
} from '../../../constants/actions'
import { API_RESPONSE_ERROR_TEXT } from '../../../constants/errors'
import { SERVER_ERROR_MESSAGES } from '../../../constants/messages'
import { REWARD_CODE_STATUS } from '../../../constants/campaign'
import { API_RESPONSE } from '../../../constants/api'

export const rewardsProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REWARDS_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case REWARDS_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case REWARDS_ACTION.ON_REWARD_CODE_EXISTS:
      if (payload.used) {
        return mergeToNewObject(state, {
          loading: false,
          codeValidityState: REWARD_POINTS_PROCESS_STEP.CODE_ALREADY_USED
        })
      } else {
        return mergeToNewObject(state, extractRewardCodeValidity(payload))
      }

    case REWARDS_ACTION.ON_REWARD_CODE_DOES_NOT_EXIST:
      return mergeToNewObject(state, {
        codeValidityState: REWARD_POINTS_PROCESS_STEP.WRONG_REWARD_CODE,
        loading: false
      })

    case REWARDS_ACTION.RESET_STATE:
      return INITIAL_STATE

    case REWARDS_ACTION.ON_RECEIVE_REWARD_FROM_CODE:
    case POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS:
    case EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS:
    case REWARDS_ACTION.ON_VALIDATE_REWARD_CODE:
      return mergeToNewObject(state, { loading: true })

    case POINTS_REWARD_ACTION.ON_RECEIVE_POINTS_FROM_CODE_SUCCESS:
      return mergeToNewObject(state, {
        codeValidityState: REWARD_POINTS_PROCESS_STEP.POINTS_RECEIVED,
        loading: false
      })

    case NFT_ACTION.ON_RECEIVE_NFT_FROM_CODE_SUCCESS:
      return mergeToNewObject(state, {
        codeValidityState: REWARD_NFT_PROCESS_STEP.NFT_RECEIVED,
        loading: false
      })

    case REWARDS_ACTION.ON_RECEIVE_REWARD_FROM_CODE_FAILED:
      return mergeToNewObject(state, {
        codeValidityState: extractRewardFailure(payload),
        loading: false
      })

    case POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS_SUCCESS:
    case SURVEY_ACTION.ON_SUBMIT_REDEMPTION_FULFILLMENT_SUCCESS:
      const { isRedeemed } = payload
      return mergeToNewObject(
        state,
        isRedeemed
          ? {
              redeemState: REDEEM_POINTS_PROCESS_STEP.POINTS_REDEEMED,
              loading: false
            }
          : {}
      )

    case NFT_ACTION.BUY_NFT_SUCCESS:
      return mergeToNewObject(state, {
        redeemState: REDEEM_POINTS_PROCESS_STEP.POINTS_REDEEMED
      })

    case EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_SUCCESS:
      return mergeToNewObject(state, {
        redeemState: REDEEM_POINTS_PROCESS_STEP.EXTERNAL_REWARDS_REDEEMED,
        loading: false
      })

    case POINTS_REWARD_ACTION.ON_RECEIVE_REWARD_FROM_BRAND_POINTS_FAILED:
      return mergeToNewObject(state, {
        redeemState: extractRedeemFailed(
          payload?.error?.response?.errors?.detail,
          payload?.redeemOption?.isNft
        ),
        loading: false
      })

    case EXCHANGE_ACTION.ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_FAILED:
      return mergeToNewObject(state, {
        redeemState: extractRedeemFailed(
          payload?.response?.errors?.detail,
          false
        ),
        loading: false
      })

    case NFT_ACTION.BUY_NFT_FAILED:
      return mergeToNewObject(state, {
        redeemState: extractRedeemFailed(
          payload?.response?.errors?.detail,
          true
        ),
        loading: false
      })

    default:
      return state
  }
}

export const REWARDS_PROFILE_DISPATCH = {
  CODE_VALIDITY_STATE: 'codeValidityState',
  REDEEM_STATE: 'redeemState',
  REWARD_CODE: 'rewardCode',
  REWARD: 'reward',
  ERROR: 'error',
  loading: 'loading'
}

const INITIAL_STATE = {
  codeValidityState: DEFAULT_CONTAINER_STATE.NONE,
  redeemState: DEFAULT_CONTAINER_STATE.NONE,
  rewardCode: '',
  reward: {},
  error: {},
  loading: false
}

const extractRewardCodeValidity = (reward) => {
  return {
    codeValidityState: reward.status,
    reward,
    rewardCode: reward.code,
    loading: false
  }
}

const extractRedeemFailed = (errorMessage, isNft) => {
  if (errorMessage === API_RESPONSE_ERROR_TEXT.INSUFFICIENT_BALANCE) {
    return REDEEM_POINTS_PROCESS_STEP.NO_ENOUGH_POINTS
  } else if (isNft) {
    return DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG
  }
  return REDEEM_POINTS_PROCESS_STEP.REDEEM_FAILED
}

const extractRewardFailure = (payload) => {
  if (payload.status === API_RESPONSE.BAD_REQUEST || API_RESPONSE.CONFLICT) {
    const errorMessage = payload?.response?.errors?.detail
    switch (errorMessage) {
      case REWARD_CODE_STATUS.CODE_USED:
      case SERVER_ERROR_MESSAGES.NFT_ALREADY_CLAIMED:
      case SERVER_ERROR_MESSAGES.USER_HAS_ALREADY_USED_CODE:
        return REWARD_POINTS_PROCESS_STEP.CODE_ALREADY_USED
      case REWARD_CODE_STATUS.CAMPAIGN_OVER:
        return REWARD_POINTS_PROCESS_STEP.CODE_EXPIRED
      default:
        return REWARD_POINTS_PROCESS_STEP.WRONG_REWARD_CODE
    }
  }
  return REWARD_POINTS_PROCESS_STEP.REWARD_FROM_CODE_FAILED
}
