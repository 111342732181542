import { mergeMap, catchError, map, tap, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'
import { logger } from 'src/libs/qb-brand-web-components'

import apiService from '../../services/api'
import {
  NFT_ACTION,
  SELL_NFT_ACTION,
  TRANSFER_NFT_ACTION
} from '../../constants/actions'
import brandConfig from 'brandConfig'
import { extractTokenId, wrapUserAccessToken } from '../../util/auth.helpers'
import { formatTokens } from '../../util/token.helpers'
import { buildListNftForSaleData } from '../../util/nft.helpers'
import {
  ENGRAVING_MESSAGE_STATUS,
  NFT_ACTIONS_STEPS
} from '../../constants/nft'
import recaptchaService from '../../services/recaptcha'
import { commonParser } from '../../util/apiParser.helpers'
import { LOYALTY_EVENT_TYPES } from '../../constants/transactions'
import {
  exclusiveContentData,
  exclusiveContentUrl
} from 'wrappers/nftExclusiveContentWrapper'

const handleListNftForSale = (action$) =>
  action$.pipe(
    ofType(SELL_NFT_ACTION.LIST_NFT_FOR_SALE),
    mergeMap(({ payload: { nftId, tokenId, usdPrice } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .listNftForSale(
            buildListNftForSaleData(
              tokenId,
              brandConfig.brandId,
              nftId,
              usdPrice
            ),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: SELL_NFT_ACTION.LIST_NFT_FOR_SALE_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((err) =>
              of({
                type: SELL_NFT_ACTION.LIST_NFT_FOR_SALE_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetNftsListedForSale = (action$) =>
  action$.pipe(
    ofType(SELL_NFT_ACTION.GET_LISTED_NFTS, NFT_ACTION.BUY_NFT_SUCCESS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService.getNftsListedForSale(buildNftParams(), accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: SELL_NFT_ACTION.GET_LISTED_NFTS_SUCCESS,
            payload: formatTokens(response.data)
          })),
          catchError((err) =>
            of({ type: SELL_NFT_ACTION.GET_LISTED_NFTS_FAILED, payload: err })
          )
        )
      )
    )
  )

const handleGetListedNftById = (action$) =>
  action$.pipe(
    ofType(SELL_NFT_ACTION.GET_LISTED_NFT_BY_ID),
    mergeMap(({ payload: { nftId, nftTokenId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getNftsListedForSale(buildNftParams(nftId, nftTokenId), accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: SELL_NFT_ACTION.GET_LISTED_NFT_BY_ID_SUCCESS,
              payload: formatTokens(response.data)[0]
            })),
            catchError((err) =>
              of({
                type: SELL_NFT_ACTION.GET_LISTED_NFT_BY_ID_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetNftClaims = (action$) =>
  action$.pipe(
    ofType(NFT_ACTION.GET_NFT_CLAIMS),
    mergeMap(({ payload: { nftId, nftTokenId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getNftClaims(nftTokenId, nftId, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: NFT_ACTION.GET_NFT_CLAIMS_SUCCESS,
            payload: commonParser(response.data)
          })),
          catchError((err) =>
            of({
              type: NFT_ACTION.GET_NFT_CLAIMS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleCancelNftListing = (action$) =>
  action$.pipe(
    ofType(SELL_NFT_ACTION.CANCEL_NFT_LISTING),
    mergeMap(({ payload: { orderId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.cancelNftListing(orderId, accessToken).pipe(
          tap((data) => logger(data)),
          map(() => ({
            type: SELL_NFT_ACTION.CANCEL_NFT_LISTING_SUCCESS
          })),
          catchError((err) =>
            of({
              type: SELL_NFT_ACTION.CANCEL_NFT_LISTING_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleBuyNft = (action$, state$) =>
  action$.pipe(
    ofType(NFT_ACTION.BUY_NFT),
    mergeMap(({ payload: { redeemOption } }) =>
      wrapUserAccessToken((accessToken) =>
        recaptchaService.execute$().pipe(
          mergeMap((recaptchaToken) =>
            apiService
              .nftAction(
                buildParamsForBuyNft(extractTokenId(state$), redeemOption),
                accessToken,
                recaptchaToken
              )
              .pipe(
                tap((data) => logger(data)),
                map(() => ({
                  type: NFT_ACTION.BUY_NFT_SUCCESS
                })),
                catchError((err) =>
                  of({
                    type: NFT_ACTION.BUY_NFT_FAILED,
                    payload: err
                  })
                )
              )
          )
        )
      )
    )
  )

const handleTransferNft = (action$) =>
  action$.pipe(
    ofType(TRANSFER_NFT_ACTION.TRANSFER_NFT),
    mergeMap(({ payload: { tokenNftId, accountNumber, nftId } }) =>
      wrapUserAccessToken((accessToken) =>
        recaptchaService.execute$().pipe(
          mergeMap((recaptchaToken) =>
            apiService
              .nftAction(
                buildParamsForTransferNft(tokenNftId, accountNumber, nftId),
                accessToken,
                recaptchaToken
              )
              .pipe(
                tap((data) => logger(data)),
                map(() => ({
                  type: TRANSFER_NFT_ACTION.TRANSFER_NFT_SUCCESS
                })),
                catchError((err) =>
                  of({
                    type: TRANSFER_NFT_ACTION.TRANSFER_NFT_FAILED,
                    payload: err
                  })
                )
              )
          )
        )
      )
    )
  )

const handleGetNftExclusiveContent = (action$) =>
  action$.pipe(
    ofType(
      NFT_ACTION.ON_GET_EXCLUSIVE_CONTENT,
      NFT_ACTION.ON_REFRESH_MEMBERSHIP_SUCCESS
    ),
    filter(({ payload }) => payload?.nftUrl),
    mergeMap(({ payload: { nftUrl } }) =>
      apiService
        .getNftExclusiveContent(
          `${exclusiveContentUrl(nftUrl)}?unique=${Date.now()}`
        )
        .pipe(
          map(({ response }) => ({
            type: NFT_ACTION.ON_GET_EXCLUSIVE_CONTENT_SUCCESS,
            payload: exclusiveContentData(response)
          })),
          catchError((err) =>
            of({
              type: NFT_ACTION.ON_GET_EXCLUSIVE_CONTENT_FAILED,
              payload: err
            })
          )
        )
    )
  )

const handleRefreshNftMembership = (action$) =>
  action$.pipe(
    ofType(NFT_ACTION.ON_REFRESH_MEMBERSHIP),
    mergeMap(
      ({
        payload: {
          token: { nftId, id, nftUrl }
        }
      }) =>
        wrapUserAccessToken((accessToken) =>
          apiService
            .refreshMembership(
              {
                nft_id: parseInt(nftId),
                token_id: parseInt(id),
                nft_url: nftUrl
              },
              accessToken
            )
            .pipe(
              map(() => ({
                type: NFT_ACTION.ON_REFRESH_MEMBERSHIP_SUCCESS,
                payload: { nftUrl }
              })),
              catchError((err) =>
                of({
                  type: NFT_ACTION.ON_REFRESH_MEMBERSHIP_FAILED,
                  payload: err
                })
              )
            )
        )
    )
  )

export const nftEpic = combineEpics(
  handleListNftForSale,
  handleGetNftsListedForSale,
  handleCancelNftListing,
  handleBuyNft,
  handleGetListedNftById,
  handleTransferNft,
  handleGetNftClaims,
  handleGetNftExclusiveContent,
  handleRefreshNftMembership
)

const buildNftParams = (nftId, nftTokenId) => {
  return {
    type: NFT_ACTIONS_STEPS.SELL,
    status: ENGRAVING_MESSAGE_STATUS.PENDING,
    ...(nftId !== undefined && { nft_id: nftId, token_id: nftTokenId })
  }
}

const buildParamsForBuyNft = (tokenId, redeemOption) => {
  const { id: tokenNftId, nftId, usdPrice } = redeemOption
  return {
    transaction: {
      reward_type: LOYALTY_EVENT_TYPES.NFT_SALE,
      buy_order: {
        token_id: tokenNftId,
        nft_id: nftId,
        usd_price: usdPrice
      },
      token_id: tokenId
    }
  }
}

const buildParamsForTransferNft = (tokenNftId, accountNumber, nftId) => {
  return {
    transaction: {
      reward_type: LOYALTY_EVENT_TYPES.NFT_TRANSFER,
      token_id: tokenNftId,
      user_b_auth_id: accountNumber,
      nft_id: nftId
    }
  }
}
