import React, { memo } from 'react'
import {
  BWFlexiblePanelContainer,
  CSS_FULL_HEIGHT
} from 'src/libs/qb-brand-web-components'
import { Link } from 'react-router-dom'

import PageHeaderWrapper from 'wrappers/PageHeaderWrapper'

const OverviewNavPanel = ({
  label,
  panelCss,
  icon,
  to,
  customCss,
  fullColumn,
  disabled,
  iconCss,
  headingCss,
  ...restProps
}) => {
  return (
    <BWFlexiblePanelContainer
      as={to ? Link : ''}
      full={fullColumn ? 1 : 0}
      css={panelCss}
      to={to}
      disabled={disabled}
      {...restProps}
    >
      <PageHeaderWrapper
        label={label}
        icon={icon}
        css={[CSS_FULL_HEIGHT, customCss]}
        disabled={disabled}
        iconCss={iconCss}
        headingCss={headingCss}
        anchor
      />
    </BWFlexiblePanelContainer>
  )
}

export default memo(OverviewNavPanel)
