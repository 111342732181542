import { BW_AUTH_LANGUAGE_ATTR } from 'src/libs/qb-brand-web-components'
import moment from 'moment'

import brandConfig from 'brandConfig'
import {
  commonParser,
  parseTransaction,
  parseTransactionResponse
} from './apiParser.helpers'
import { isNftToken } from './brand.helpers'
import { CAMPAIGN_STATUS } from '../constants/campaign'
import { REWARD_POINTS_PROCESS_STEP } from '../constants/containerStates'
import {
  isExchangeEventType,
  isNftSaleEventType
} from './pointsExchange.helpers'

const extractAuthReducer = (state) => state.value.authReducer

const extractMainReducer = (state) => state.value.mainReducer

const extractRewardsReducer = (state) => state.value.rewardsReducer

const extractAccountReducer = (state) => state.value.accountReducer

export function extractMainProfile(state) {
  return extractMainReducer(state).mainProfile
}

export function extractSignupProfile(state) {
  return extractAuthReducer(state).signupProfile
}

export function extractRewardsProfile(state) {
  return extractRewardsReducer(state).rewardsProfile
}

export function extractAccountProfile(state) {
  return extractAccountReducer(state).accountProfile
}

export function hasUserAccountStateChanged(updatedCognitoUser, accountProfile) {
  const {
    email,
    family_name,
    given_name,
    [BW_AUTH_LANGUAGE_ATTR]: language
  } = updatedCognitoUser.attributes
  return (
    email !== accountProfile.email ||
    family_name !== accountProfile.firstName ||
    given_name !== accountProfile.lastName ||
    language !== accountProfile.language
  )
}

export const reverseTransactionsAndParseTx = (transactions, authId) => {
  return transactions
    .sort((a, b) => moment.utc(b.inserted_at).diff(moment.utc(a.inserted_at)))
    .reduce((current, next) => {
      const transaction = parseTransaction(next)
      const loyaltyEventType = transaction.loyaltyEventType
      if (isExchangeEventType(loyaltyEventType)) {
        return [
          ...current,
          { ...transaction, pointsBurned: 0, token: transaction.tokenTo },
          { ...transaction, amountReceived: 0, showBrandName: true }
        ]
      } else if (isNftSaleEventType(loyaltyEventType)) {
        const isNftSeller = authId && authId === transaction?.user?.authId
        return [
          ...current,
          { ...transaction, showBrandName: true, isNftSeller },
          ...(isNftSeller
            ? [{ ...transaction, isSaleFee: true, isNftSeller }]
            : []),
          { ...transaction, token: transaction.tokenTo, isNftSeller }
        ]
      }
      return [...current, transaction]
    }, [])
}

export const formatTransactions = (transactionResponse, { authId }) => {
  if (!transactionResponse) {
    return {}
  }
  transactionResponse = parseTransactionResponse(transactionResponse)
  if (transactionResponse.transactions) {
    transactionResponse.transactions = reverseTransactionsAndParseTx(
      transactionResponse.transactions,
      authId
    )
  }
  return transactionResponse
}

export const formatRewardTypes = (rewardTypes) => {
  return rewardTypes.map((rewardType) => commonParser(rewardType))
}

export const formatCashbackCategories = (categories) => {
  if (categories) {
    return Object.keys(categories).filter((key) => categories[key] === true)
  } else return []
}

export const formatRewardCodeResponse = (reward) => {
  const campaign = reward.campaign
  const token = campaign?.token
  if (token) {
    const isNft = isNftToken(token)
    if (
      (brandConfig.features.disablePoints && !isNft) ||
      campaign.status.toLowerCase() === CAMPAIGN_STATUS.INACTIVE
    ) {
      reward.status = REWARD_POINTS_PROCESS_STEP.WRONG_REWARD_CODE
      reward.invalid = true
    } else if (campaign.validUntil < moment.utc().unix()) {
      reward.status = REWARD_POINTS_PROCESS_STEP.CODE_EXPIRED
      reward.invalid = true
    }
  }
  return reward
}
