import React, { memo } from 'react'

import RedeemPointsConfirm from '../../../components/popups/redeem/RedeemPointsConfirm'
import customConfig from 'customConfig'

const RedeemConfirmWrapper = (props) => {
  const { t } = props
  return (
    <RedeemPointsConfirm
      title={t(customConfig.redeemPointsLinkLabel, {
        pointsLabel: t(customConfig.pointsLabel)
      })}
      confirmTitle={t(customConfig.redeemPointsConfirmLabel, {
        pointsLabel: t(customConfig.pointsLabel)
      })}
      {...props}
    />
  )
}

export default memo(RedeemConfirmWrapper)
