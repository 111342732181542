import React, { memo } from 'react'

import ReceiveReward from '../../reward/ReceiveReward'
import Popup from '../../shared/popup/Popup'
import customConfig from 'customConfig'

const EnterRewardCode = ({
  onSubmit,
  onCancel,
  t,
  rewardsProfile,
  ...restProps
}) => (
  <Popup
    cancelLabel={t(customConfig.cancelLabel)}
    onCancel={onCancel}
    submitLabel={t(customConfig.receiveRewardLabel)}
    onFormSubmit={onSubmit}
    disabledSubmit={rewardsProfile.loading}
  >
    <ReceiveReward t={t} {...restProps} rewardsProfile={rewardsProfile} />
  </Popup>
)

export default memo(EnterRewardCode)
