import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Redeem from '../components/redeem/Redeem'
import { HOME_URL } from '../constants/navigation'
import { changeRewardsState, resetRewardsState } from '../store/actions/rewards'
import { getRewardTypes } from '../store/actions/redeem'
import { receiveRewardFromBrandPoints } from '../store/actions/points'
import { REWARDS_PROFILE_DISPATCH } from '../store/reducers/rewards/rewards'
import {
  RedeemPointsSuccess,
  RedeemPointsNoBalance,
  RedeemPointsFailed
} from '../components/popups/redeem'
import {
  DEFAULT_CONTAINER_STATE,
  REDEEM_POINTS_PROCESS_STEP,
  REWARD_POINTS_PROCESS_STEP
} from '../constants/containerStates'
import { ERROR_TEXT } from '../constants/messages'
import RedeemConfirmWrapper from 'wrappers/RedeemConfirmWrapper'
import {
  getSurveys,
  changeRedemptionSurveyState,
  changeRedemptionSurveyInputAndErrorState,
  resetRedemptionSurveyState,
  changeSurveyState
} from '../store/actions/survey'
import { isRedemptionFulfillmentEnabled } from '../util/feature.helpers'
import { trackGTMDataLayer } from 'wrappers/trackGTMDataLayer'
import i18n from '../i18n'
import filterRewardTypesForExchange from 'wrappers/filterRewardTypesForExchange'
import {
  filterSameRedeemOptions,
  formattedNftsForRedemption,
  formattedNftsForSale,
  sortRedeemOptionsByValue
} from '../util/pointsExchange.helpers'
import { buyNft, getNftsListedForSale } from '../store/actions/nft'
import SomethingWentWrong from '../components/popups/common/SomethingWentWrong'

const RedeemContainer = ({
  getRewardTypes,
  changeRewardsState,
  receiveRewardFromBrandPoints,
  rewardsProfile,
  mainProfile: {
    redemptionOptions,
    exchangeMemberships,
    currentUserPoints,
    currentBrandToken,
    isDisabledPointsBurn
  },
  tokenProfile,
  resetRewardsState,
  accountProfile: { email },
  getSurveys,
  surveysData,
  redemptionProfile,
  changeRedemptionSurveyState,
  changeRedemptionSurveyInputAndErrorState,
  resetRedemptionSurveyState,
  changeSurveyState,
  nftsForRedemption,
  getNftsListedForSale,
  nftsForSale,
  buyNft
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    trackGTMDataLayer(location.pathname)
  }, [])

  useEffect(() => {
    getRewardTypes()
  }, [])

  useEffect(() => {
    getNftsListedForSale()
  }, [])

  useEffect(() => {
    if (isRedemptionFulfillmentEnabled()) {
      getSurveys()
    }
  }, [])

  useEffect(() => {
    if (rewardsProfile.redeemState) {
      setPopupState(rewardsProfile.redeemState)
    }
  }, [rewardsProfile.redeemState])

  useEffect(() => {
    handleNoPopup()
    return () => {
      resetRewardsState()
    }
  }, [resetRewardsState])

  const [popupState, setPopupState] = useState()
  const [redeemOption, setRedeemOption] = useState()

  const redeemOptions = useMemo(() => {
    const filteredRewardTypes = filterRewardTypesForExchange({
      redemptionOptions,
      exchangeMemberships,
      language: i18n.language
    })
    const formattedNfts = formattedNftsForRedemption({
      nftsForRedemption,
      currentBrandToken
    })
    const formattedSaleNfts = formattedNftsForSale({
      nftsForSale,
      currentBrandToken
    })
    const filteredSameNfts = filterSameRedeemOptions(
      formattedNfts,
      formattedSaleNfts
    )
    return sortRedeemOptionsByValue(filteredRewardTypes, filteredSameNfts)
  }, [
    redemptionOptions,
    exchangeMemberships,
    currentBrandToken,
    nftsForRedemption,
    nftsForSale,
    i18n.language
  ])

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  const handleGiftCardSelect = (option) => {
    setPopupState(REDEEM_POINTS_PROCESS_STEP.REDEEM_POINTS)
    setRedeemOption(option)
  }

  const handlePopupSubmit = () => {
    switch (popupState) {
      case REDEEM_POINTS_PROCESS_STEP.REDEEM_POINTS:
        const neededPoints = redeemOption?.value - currentUserPoints
        if (neededPoints > 0) {
          setPopupState(REDEEM_POINTS_PROCESS_STEP.NO_ENOUGH_POINTS)
        } else if (redeemOption?.isForSale) {
          buyNft(redeemOption)
        } else {
          receiveRewardFromBrandPoints(redeemOption)
        }
        break

      case REDEEM_POINTS_PROCESS_STEP.NO_ENOUGH_POINTS:
        navigate(HOME_URL)
        changeRewardsState(
          REWARDS_PROFILE_DISPATCH.CODE_VALIDITY_STATE,
          REWARD_POINTS_PROCESS_STEP.ENTER_CODE
        )
        break

      case REDEEM_POINTS_PROCESS_STEP.POINTS_REDEEMED:
        changeRewardsState(
          REWARDS_PROFILE_DISPATCH.REDEEM_STATE,
          DEFAULT_CONTAINER_STATE.NONE
        )
        handleNoPopup()
        break

      case REDEEM_POINTS_PROCESS_STEP.REDEEM_FAILED:
        changeRewardsState(
          REWARDS_PROFILE_DISPATCH.REDEEM_STATE,
          DEFAULT_CONTAINER_STATE.NONE
        )
        handleNoPopup()
        break

      default:
        break
    }
  }

  const handlePopupCancel = () => {
    switch (popupState) {
      case REDEEM_POINTS_PROCESS_STEP.REDEEM_POINTS:
        handleNoPopup()
        if (isRedemptionFulfillmentEnabled()) {
          resetRedemptionSurveyState()
        }
        break
      case REDEEM_POINTS_PROCESS_STEP.NO_ENOUGH_POINTS:
        handleNoPopup()
        break

      default:
        break
    }
  }

  const renderPopup = () => {
    switch (popupState) {
      case REDEEM_POINTS_PROCESS_STEP.REDEEM_POINTS:
        return (
          <RedeemConfirmWrapper
            redeemOption={redeemOption}
            onSubmit={handlePopupSubmit}
            onCancel={handlePopupCancel}
            tokenProfile={tokenProfile}
            surveysData={surveysData}
            redemptionProfile={redemptionProfile}
            changeRedemptionSurveyState={changeRedemptionSurveyState}
            dispatchAction={changeRedemptionSurveyInputAndErrorState}
            changeSurveyState={changeSurveyState}
            rewardsProfile={rewardsProfile}
            t={t}
            isDisabledPointsBurn={isDisabledPointsBurn}
          />
        )

      case REDEEM_POINTS_PROCESS_STEP.NO_ENOUGH_POINTS:
        return (
          <RedeemPointsNoBalance
            redeemOption={redeemOption}
            onSubmit={handlePopupSubmit}
            onCancel={handlePopupCancel}
            currentUserPoints={currentUserPoints}
            tokenProfile={tokenProfile}
            t={t}
          />
        )

      case REDEEM_POINTS_PROCESS_STEP.POINTS_REDEEMED:
        return (
          <RedeemPointsSuccess
            email={email}
            onSubmit={handlePopupSubmit}
            redeemOption={redeemOption}
            t={t}
          />
        )

      case REDEEM_POINTS_PROCESS_STEP.REDEEM_FAILED:
        return (
          <RedeemPointsFailed
            description={t(ERROR_TEXT.RECEIVE_REWARD_FAILED, {
              tokenName: tokenProfile.name
            })}
            onSubmit={handlePopupSubmit}
            t={t}
          />
        )

      case DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG:
        return <SomethingWentWrong onSubmit={handleNoPopup} t={t} />

      default:
        break
    }
  }

  return (
    <>
      <Redeem
        redeemOptions={redeemOptions}
        currentUserPoints={currentUserPoints}
        tokenProfile={tokenProfile}
        onGiftCardSelect={handleGiftCardSelect}
        t={t}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  mainReducer,
  accountReducer,
  rewardsReducer,
  surveyReducer,
  nftReducer
}) => {
  const { mainProfile, tokenProfile } = mainReducer
  const { accountProfile } = accountReducer
  const { rewardsProfile } = rewardsReducer
  const { surveyProfile, redemptionProfile } = surveyReducer
  const { surveysData } = surveyProfile
  return {
    rewardsProfile,
    mainProfile,
    tokenProfile,
    accountProfile,
    surveysData,
    redemptionProfile,
    nftsForRedemption: nftReducer?.nftProfile?.nftsForRedemption || [],
    nftsForSale: nftReducer?.nftProfile?.nftsForSale || []
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRewardTypes,
      changeRewardsState,
      receiveRewardFromBrandPoints,
      resetRewardsState,
      getSurveys,
      changeRedemptionSurveyState,
      changeRedemptionSurveyInputAndErrorState,
      resetRedemptionSurveyState,
      changeSurveyState,
      getNftsListedForSale,
      buyNft
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(RedeemContainer)
