import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import htmlParser from 'html-react-parser'
import styled from '@emotion/styled'
import {
  CSS_FULL_WIDTH,
  CSS_TEXT_ALIGN_CENTER,
  CSS_GRID,
  CSS_ALIGN_ITEMS_CENTER
} from 'src/libs/qb-brand-web-components'

import { isValidHtmlContent } from '../../../util/app/app.helpers'
import { NAVIGATION_TARGET_BLANK } from '../../../constants/externalUrls'

const RichText = ({ content, isHTML, customCss }) => {
  return (
    <RichTextContainer css={customCss}>
      {isHTML || isValidHtmlContent(content) ? (
        htmlParser(content)
      ) : (
        <MarkdownParser content={content} />
      )}
    </RichTextContainer>
  )
}

export default memo(RichText)

const MarkdownParser = ({ content }) => {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
      linkTarget={NAVIGATION_TARGET_BLANK}
      className='markdown'
    >
      {content}
    </ReactMarkdown>
  )
}

const RichTextContainer = styled.div`
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${CSS_FULL_WIDTH};
  ${CSS_TEXT_ALIGN_CENTER};
  height: auto;
  img,
  div {
    width: 100% !important;
    max-width: 100% !important;
  }
`
