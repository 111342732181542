const TEXT_DISPLAY_TYPES = {
  TEXT: 'text',
  MARKDOWN: 'markdown',
  HTML: 'html'
}

const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio'
}

export { TEXT_DISPLAY_TYPES, MEDIA_TYPES }
