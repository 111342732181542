const foundationApiUrl = (path) => {
  return `${process.env.REACT_APP_X_API_URL}/${path}`
}

const connectToXUrl = (accountNumber, token) => {
  return foundationApiUrl(`?account_number=${accountNumber}&token=${token}`)
}

const postXMessageUrl = (text) => {
  return `https://x.com/intent/post?text=${encodeURIComponent(text)}`
}

export { foundationApiUrl, connectToXUrl, postXMessageUrl }
