import React, { memo } from 'react'
import { BW_COLOR_LITE_WHITE } from 'src/libs/qb-brand-web-components'

import GoogleIconSvg from './GoogleIconSvg'
import Button from '../shared/button/Button'

const Google = ({ disabled, customLabel, label, onClick }) => {
  return (
    <Button
      color={BW_COLOR_LITE_WHITE}
      invert={false}
      disabled={disabled}
      onClick={!disabled && onClick}
      label={
        <>
          <GoogleIconSvg disabled={disabled} />{' '}
          {customLabel ? customLabel : 'Google ' + label}
        </>
      }
    />
  )
}

export default memo(Google)
