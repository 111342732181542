import React, { lazy, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Loading from '../components/shared/loading/Loading'
import Transactions from '../components/transactions/Transactions'
import { getTransactions } from '../store/actions/transactions'
import { trackGTMDataLayer } from 'wrappers/trackGTMDataLayer'
import {
  DEFAULT_CONTAINER_STATE,
  TRANSACTIONS_PROCESS_STEP
} from '../constants/containerStates'

const FuturePointsInfoPopup = lazy(
  () => import('../components/popups/transactions/FuturePointsInfo')
)

const TransactionsContainer = ({
  getTransactions,
  transactionsProfile: { transactions, loading, loadingTx, offset, totalTx },
  tokenProfile,
  accountProfile
}) => {
  const { t } = useTranslation()

  const [popupState, setPopupState] = useState({
    id: DEFAULT_CONTAINER_STATE.NONE,
    data: null
  })

  useEffect(() => {
    getTransactions(0, true, false)
  }, [getTransactions])

  const location = useLocation()
  useEffect(() => {
    trackGTMDataLayer(location.pathname)
  }, [])

  const handleScroll = useCallback(
    (ev) => {
      const { scrollTop, scrollHeight } = ev.currentTarget
      if (scrollTop + window.innerHeight + 1 >= scrollHeight) {
        if (offset === 0 || offset < totalTx) {
          getTransactions(offset + 10, false, true)
        }
      }
    },
    [offset]
  )

  useEffect(() => {
    document.querySelector('#root').addEventListener('scroll', handleScroll)
    return () => {
      document
        .querySelector('#root')
        .removeEventListener('scroll', handleScroll)
    }
  }, [offset])

  const handleNoPopup = () => {
    setPopupState({ data: null, id: DEFAULT_CONTAINER_STATE.NONE })
  }

  const handlePopupSubmit = () => {
    switch (popupState.id) {
      case TRANSACTIONS_PROCESS_STEP.FUTURE_POINTS_INFO:
        handleNoPopup()
        break

      default:
        break
    }
  }

  const renderPopup = () => {
    switch (popupState.id) {
      case TRANSACTIONS_PROCESS_STEP.FUTURE_POINTS_INFO:
        return (
          <FuturePointsInfoPopup
            onSubmit={handlePopupSubmit}
            t={t}
            isNft={popupState.data?.isNft}
          />
        )
      default:
        break
    }
  }

  const handleShowFuturePointsInfo = (isNft) => {
    setPopupState({
      id: TRANSACTIONS_PROCESS_STEP.FUTURE_POINTS_INFO,
      data: { isNft }
    })
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <Transactions
        transactions={transactions}
        loading={loadingTx}
        tokenProfile={tokenProfile}
        accountProfile={accountProfile}
        onShowFuturePointsInfo={handleShowFuturePointsInfo}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  transactionsReducer,
  mainReducer,
  accountReducer
}) => {
  const { transactionsProfile } = transactionsReducer
  const { tokenProfile } = mainReducer
  const { accountProfile } = accountReducer

  return {
    transactionsProfile,
    tokenProfile,
    accountProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTransactions
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsContainer)
