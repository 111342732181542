import React, { memo } from 'react'
import {
  BWPanel,
  BWPanelHeader,
  openExternalUrl
} from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import OverviewEnterCode from './OverviewEnterCode'
import { exchangeProviderLabel } from '../../util/pointsExchange.helpers'
import Button from '../shared/button/Button'
import OverviewHowToEarnWrapper from 'wrappers/OverviewHowToEarnWrapper'
import { OverviewTitleContent } from '../shared/common'
import BalanceWithAnimation from '../shared/overview/BalanceWithAnimation'

const OverviewMainPanel = ({
  onEnterRewardCodeClick,
  currentUserPoints,
  previousUserPoints,
  tokenProfile: { name, symbol },
  t,
  onClickHowToEarn,
  icon,
  panelCss,
  navItemsMoreLength,
  customCss
}) => {
  return (
    <BWPanel padding={15} css={[{ maxHeight: '500px' }, panelCss]}>
      <OverviewTitleContent
        navItemsMoreLength={navItemsMoreLength}
        css={customCss}
      >
        <BWPanelHeader
          title={
            <BalanceWithAnimation
              previousUserPoints={previousUserPoints}
              currentUserPoints={currentUserPoints}
            />
          }
          description={exchangeProviderLabel({ name, symbol })}
          icon={icon}
          color={brandConfig.prefIconColor}
        />
        <OverviewEnterCode
          t={t}
          onEnterRewardCodeClick={onEnterRewardCodeClick}
          howToEarnLink={
            !brandConfig.features.disableHowToEarnRewards && (
              <OverviewHowToEarnWrapper t={t} onClick={onClickHowToEarn} />
            )
          }
          howDoesItWorkButton={
            brandConfig.redirectUrl && (
              <Button
                label={t('brand:reward.how-to-earn-rewards')}
                onClick={() => openExternalUrl(brandConfig.redirectUrl)}
              />
            )
          }
        />
      </OverviewTitleContent>
    </BWPanel>
  )
}

export default memo(OverviewMainPanel)
