import { ajax } from 'rxjs/ajax'
import moment from 'moment'
import { compare } from 'compare-versions'

import { getAppVersion, hotReloadApp } from './app/app.helpers'

async function clearCache() {
  const { caches } = window
  if (caches) {
    const names = await caches.keys()
    await Promise.all(names.map((name) => caches.delete(name)))
  }
}

async function clearCacheOnNewAppVersion() {
  ajax.get(`/meta.json?nocache=${moment().valueOf()}`).subscribe((data) => {
    console.log('Downloaded meta.json metadata')
    console.log(data)
    if (data && data.response) {
      const metadata = data.response
      const metaVersion = metadata.version
      if (metaVersion) {
        const currentVersion = getAppVersion()
        console.log('## CURRENT VERSION: ', currentVersion)
        console.log('## META VERSION: ', metaVersion)
        if (currentVersion && compare(currentVersion, metaVersion, '<')) {
          clearCache().then(hotReloadApp)
        }
      }
    }
  })
}

export { clearCacheOnNewAppVersion }
