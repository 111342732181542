import { REWARDS_ACTION } from '../../constants/actions'

const changeRewardsState = (property, value) => ({
  type: REWARDS_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeRewardsInputAndErrorState = (property, value) => ({
  type: REWARDS_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const validateRewardCode = (rewardCode) => ({
  type: REWARDS_ACTION.ON_VALIDATE_REWARD_CODE,
  payload: {
    rewardCode
  }
})

const resetRewardsState = () => ({
  type: REWARDS_ACTION.RESET_STATE
})

const receiveRewardFromCode = (code, rewardType) => ({
  type: REWARDS_ACTION.ON_RECEIVE_REWARD_FROM_CODE,
  payload: {
    code,
    rewardType
  }
})

export {
  changeRewardsState,
  changeRewardsInputAndErrorState,
  validateRewardCode,
  resetRewardsState,
  receiveRewardFromCode
}
