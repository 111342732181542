import React, { memo } from 'react'
import { BrandLogoContainer } from 'src/libs/qb-brand-web-components'

import brandLogo from 'assets/logo.png'

const UnauthHeaderLogoWrapper = ({ customCss }) => {
  return <BrandLogoContainer src={brandLogo} css={customCss} />
}

export default memo(UnauthHeaderLogoWrapper)
