import { combineReducers } from 'redux'

import { nftProfile } from './nft'
import { transferNftProfile } from './transferNft'
import { sellNftProfile } from './sellNft'

export const nftReducer = combineReducers({
  nftProfile,
  transferNftProfile,
  sellNftProfile
})
