import React, { memo } from 'react'
import { css } from '@emotion/react'

import BWIcon from '../BWIcon'
import { cssFontSize } from '../../styles/shared'
import { SIZE_UNIT } from '../../themes'

const BWMenuIcon = ({ icon }) => (
  <BWIcon
    icon={icon}
    faIconCss={faIconCss()}
    moonIconCss={moonIconCss()}
    size={32}
  />
)

export default memo(BWMenuIcon)

const faIconCss = () => css`
  ${cssFontSize(SIZE_UNIT.MD)};
`

const moonIconCss = () => css`
  margin: -8px;
`
