export const buildFontFace = () => `
  @font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
      url("../fonts/OpenSans/assets/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
  }

  @font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
      url("../fonts/OpenSans/assets/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
      url("../fonts/OpenSans/assets/OpenSans-Medium.ttf") format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
      url("../fonts/OpenSans/assets/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
  }

  @font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
      url("../fonts/OpenSans/assets/OpenSans-Bold.ttf") format("truetype");
    font-weight: 700;
  }

  @font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
      url("../fonts/OpenSans/assets/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
  }
`
