import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { BUY_POINTS_ACTION } from '../../../constants/actions'

export const buyPointsProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BUY_POINTS_ACTION.ON_GENERATE_CHECKOUT_SESSION:
      return mergeToNewObject(state, {
        checkoutSessionUrl: '',
        isProcessing: true
      })

    case BUY_POINTS_ACTION.ON_GENERATE_CHECKOUT_SESSION_FAILED:
      return mergeToNewObject(state, {
        checkoutSessionUrl: '',
        isProcessing: false
      })

    case BUY_POINTS_ACTION.ON_GENERATE_CHECKOUT_SESSION_SUCCESS:
      return mergeToNewObject(state, {
        checkoutSessionUrl: payload.url,
        isProcessing: false
      })

    case BUY_POINTS_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case BUY_POINTS_ACTION.ON_GET_PAYMENT_SESSION:
      return mergeToNewObject(state, { paymentSession: null })

    case BUY_POINTS_ACTION.ON_GET_PAYMENT_SESSION_SUCCESS:
      return mergeToNewObject(state, { paymentSession: payload })

    default:
      return state
  }
}

const INITIAL_STATE = {
  isProcessing: false,
  checkoutSessionUrl: '',
  paymentSession: null
}

export const BUY_POINTS_PROFILE_DISPATCH = {
  PAYMENT_SESSION: 'paymentSession',
  SESSION_ID: 'sessionId'
}
