import React, { memo } from 'react'

import { LinkContainer } from '.'

const BWOutsideLink = ({
  label,
  isEmail,
  to,
  size,
  color,
  onClick,
  ...restProps
}) => {
  return (
    <LinkContainer
      size={size}
      color={color}
      href={isEmail ? `mailto:${to}` : to}
      target='_blank'
      rel='noopener noreferrer'
      onClick={onClick}
      {...restProps}
    >
      {label}
    </LinkContainer>
  )
}

export default memo(BWOutsideLink)
