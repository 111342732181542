import UAParser from 'ua-parser-js'

import packageJson from '../../../package.json'
import { EMAIL_TYPE_EXCHANGE_PROVIDERS } from '../../constants/pointsExchange'
import { NAVIGATION_TARGET_BLANK } from '../../constants/externalUrls'

const getAppVersion = () => packageJson.version

const parseJson = (input) => {
  return input ? JSON.parse(input) : {}
}

const stringifyJson = (input) => {
  return input ? JSON.stringify(input) : ''
}

const errorLogger = (message) => {
  console.log('### ERROR ###')
  console.error(message)
}

const isRunningTesting = () => {
  return process.env.NODE_ENV === 'test'
}

const isListNotEmpty = (list) => {
  return list?.length > 0
}

const isExistsElementFromList = (list, element) => {
  return list?.includes(element)
}

const isExchangeProviderEmailType = (brandId) => {
  return isExistsElementFromList(EMAIL_TYPE_EXCHANGE_PROVIDERS, brandId)
}

const formatUserDeviceDetails = () => {
  return {
    app_version: `${getAppVersion()}`,
    browser_details: new UAParser().getResult()
  }
}

const isValidHtmlContent = (text) => {
  return text.toLowerCase().includes('!doctype html')
}

const openBlankRedirectPage = (url) => {
  window.open(url, NAVIGATION_TARGET_BLANK)
}

const hotReloadApp = () => {
  window.location.reload(true)
}

export {
  getAppVersion,
  parseJson,
  stringifyJson,
  errorLogger,
  isRunningTesting,
  isListNotEmpty,
  isExistsElementFromList,
  formatUserDeviceDetails,
  isExchangeProviderEmailType,
  isValidHtmlContent,
  openBlankRedirectPage,
  hotReloadApp
}
