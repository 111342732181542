import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { ENGRAVING_ACTION } from '../../../constants/actions'

export const engravingsProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ENGRAVING_ACTION.ON_GET_ENGRAVINGS:
      return mergeToNewObject(state, { loading: true })

    case ENGRAVING_ACTION.ON_GET_ENGRAVINGS_SUCCESS:
      return mergeToNewObject(state, { loading: false, engravings: payload })

    case ENGRAVING_ACTION.ON_GET_ENGRAVINGS_FAILED:
      return mergeToNewObject(state, { loading: false })
    default:
      return state
  }
}

const INITIAL_STATE = {
  engravings: [],
  loading: false
}

export const ENGRAVINGS_PROFILE_DISPATCH = {
  ENGRAVINGS: 'engravings',
  LOADING: 'loading'
}
