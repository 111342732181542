import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { BWText } from 'src/libs/qb-brand-web-components'

import { TermsNotAccepted } from '../../popups/signUp'
import TermsCheckbox from '../termsCheckbox/TermsCheckbox'
import OptionalCheckbox from '../optionalCheckbox/OptionalCheckbox'
import brandConfig from 'brandConfig'
import PrivacyPolicyCheckbox from '../privacyPolicyCheckbox/PrivacyPolicyCheckbox'
import { SIGNUP_PROFILE_DISPATCH } from '../../../store/reducers/auth/signup'

const OptionalAndMandatoryCheckboxes = ({
  signupProfile,
  onCancelTerms,
  onAcceptTermsSubmit,
  dispatchActionSignup,
  onClickPrivacyPolicy,
  privacyPolicyClickError,
  children
}) => {
  const { t } = useTranslation()
  const {
    newsletterCheckbox,
    disablePrivacyCheckbox,
    disableTermsCheckbox,
    enableMandatoryCheckbox
  } = brandConfig.features
  const { error, optionalCheckbox, mandatoryCheckbox } = signupProfile

  return (
    <TermsNotAccepted
      signupProfile={signupProfile}
      dispatchAction={dispatchActionSignup}
      onAcceptTermsSubmit={onAcceptTermsSubmit}
      onCancelTerms={onCancelTerms}
      t={t}
    >
      {!disableTermsCheckbox && (
        <TermsCheckbox
          dispatchAction={dispatchActionSignup}
          signupProfile={signupProfile}
          t={t}
        />
      )}
      {!disablePrivacyCheckbox && (
        <PrivacyPolicyCheckbox
          dispatchAction={dispatchActionSignup}
          signupProfile={signupProfile}
          onClickPrivacyPolicy={onClickPrivacyPolicy}
          privacyPolicyClickError={privacyPolicyClickError}
          t={t}
        />
      )}
      {enableMandatoryCheckbox && (
        <OptionalCheckbox
          dispatchAction={dispatchActionSignup}
          error={error}
          checkbox={mandatoryCheckbox}
          description={<BWText label={t('brand:common.mandatory-checkbox')} />}
          dispatchCheckbox={SIGNUP_PROFILE_DISPATCH.MANDATORY_CHECKBOX}
        />
      )}
      {children}
      {newsletterCheckbox && (
        <OptionalCheckbox
          dispatchAction={dispatchActionSignup}
          error={error}
          checkbox={optionalCheckbox}
          description={<BWText label={t('brand:common.opt-for-newsletter')} />}
          dispatchCheckbox={SIGNUP_PROFILE_DISPATCH.OPTIONAL_CHECKBOX}
        />
      )}
    </TermsNotAccepted>
  )
}

export default memo(OptionalAndMandatoryCheckboxes)
