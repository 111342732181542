import { css } from '@emotion/react'
import {
  cssCustomFontSize,
  CSS_POSITION_RELATIVE,
  getColorByLuminosity,
  mq,
  SIZE_UNIT
} from 'src/libs/qb-brand-web-components'

const SOCIAL_MEDIA_ICON_STYLES = css`
  position: absolute;
  left: 9px;
  width: 22px;
  height: 22px;
`

const OVERVIEW_PANEL_DEFAULT_PADDING = css`
  padding: 50px 0;
`

const OVERVIEW_PADDING_SMALL = css`
  ${OVERVIEW_PANEL_DEFAULT_PADDING};
  gap: 60px;
`

const OVERVIEW_PADDING_DEFAULT = css`
  ${OVERVIEW_PANEL_DEFAULT_PADDING};
  gap: 90px;
  ${mq(SIZE_UNIT.SM)} {
    ${OVERVIEW_PADDING_SMALL};
  }
`

const OVERVIEW_PANEL_MAX_HEIGHT = css`
  max-height: 500px;
`

const CSS_MARGIN_TOP_10 = css`
  margin-top: 10px;
`

const CSS_DISABLED = css`
  pointer-events: none;
`

const borderWithRadius = (
  colors,
  size,
  borderRadius = '10px',
  customCss
) => css`
  border: 1px solid ${colors.liteWhite};
  width: ${size};
  height: ${size};
  border-radius: ${borderRadius};
  ${customCss && customCss};
`

const CSS_REDEMPTION_OPTION_PROGRESS = css`
  height: 36px;
  width: 36px;
`

const CSS_GRID_COLUMN_THREE = css`
  grid-column: 1/3;
`

const faIconCss = (theme, size = 18) => css`
  ${cssCustomFontSize(size)};
  color: ${getColorByLuminosity(theme.colors.main, theme.colors)};
`

const moonIconCss = (theme) => css`
  color: ${getColorByLuminosity(theme.colors.main, theme.colors)};
`

const CUSTOM_REDEEM_PANEL = css`
  min-height: 200px;
  ${mq(SIZE_UNIT.SM)} {
    min-height: 173px;
  }
  place-content: center;
  ${CSS_POSITION_RELATIVE};
`

const COE_BANNER_STYLES = css`
  min-height: 110px;
  padding: 10px;
  align-content: end;
`

export {
  SOCIAL_MEDIA_ICON_STYLES,
  OVERVIEW_PADDING_SMALL,
  OVERVIEW_PADDING_DEFAULT,
  OVERVIEW_PANEL_MAX_HEIGHT,
  CSS_MARGIN_TOP_10,
  CSS_DISABLED,
  borderWithRadius,
  CSS_REDEMPTION_OPTION_PROGRESS,
  CSS_GRID_COLUMN_THREE,
  faIconCss,
  moonIconCss,
  CUSTOM_REDEEM_PANEL,
  COE_BANNER_STYLES
}
