import React, { memo } from 'react'
import { BWCheckbox, BWPanelContent } from 'src/libs/qb-brand-web-components'

const OptionalCheckbox = ({
  dispatchAction,
  error,
  checkbox,
  description,
  dispatchCheckbox
}) => {
  return (
    <BWPanelContent>
      <BWCheckbox
        label={description}
        checked={checkbox}
        onChange={() => dispatchAction(dispatchCheckbox, !checkbox)}
        error={error[dispatchCheckbox]}
      />
    </BWPanelContent>
  )
}

export default memo(OptionalCheckbox)
