import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  faCheckCircle,
  faCircle,
  faTimesCircle
} from '@fortawesome/pro-regular-svg-icons'

import {
  cssBorderRadius,
  cssGap,
  CSS_ALIGN_CONTENT_START,
  CSS_ALIGN_ITEMS_CENTER,
  CSS_BOX_SIZING_BORDER_BOX,
  CSS_DEFAULT_WIDTH_PADDING,
  CSS_FULL_HEIGHT,
  CSS_CURSOR_POINTER,
  CSS_FULL_WIDTH,
  CSS_GRID,
  CSS_INPUT_BOX_HEIGHT,
  cssBoxShadow
} from '../../../styles/shared'
import { MESSAGE_TYPE } from '../../../constants/constants'
import { hexToRGBA } from '../../../util/style.helpers'
import { SIZE_UNIT } from '../../../themes/units'

export const defaultBoxStyles = (colors) => css`
  ${CSS_INPUT_BOX_HEIGHT};
  ${CSS_BOX_SIZING_BORDER_BOX};
  background-color: ${colors?.liteWhite};
  color: ${colors?.liteBlack};
`

export const OverlayContainer = styled.div`
  ${CSS_FULL_HEIGHT};
  ${CSS_FULL_WIDTH};
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => hexToRGBA(theme.colors?.black, 60)};
`

export const buildFieldIcon = (type) => {
  switch (type) {
    case MESSAGE_TYPE.SUCCESS:
      return faCheckCircle
    case MESSAGE_TYPE.ERROR:
      return faTimesCircle
    default:
      return faCircle
  }
}

export const PanelMainContainer = styled.div`
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${CSS_DEFAULT_WIDTH_PADDING};
  ${CSS_BOX_SIZING_BORDER_BOX};
  ${CSS_ALIGN_CONTENT_START};
  ${cssGap(SIZE_UNIT.XL2)};
  ${cssBorderRadius(SIZE_UNIT.SM)};
  background-size: cover;
  background-position: center;
  // ${CSS_FULL_HEIGHT};
  height: auto;
  ${(props) => panelStyles(props)};
`

export const PanelContentContainer = styled.div`
  ${cssGap(SIZE_UNIT.SM)};
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${CSS_FULL_WIDTH};
`

const panelStyles = ({
  padding,
  theme: { colors },
  bgImage,
  noBgOpacity,
  anchor,
  bgImageOpacity = 60
}) => css`
  ${padding && `padding: ${padding}px`};
  ${bgImage &&
  !noBgOpacity &&
  `background-image: linear-gradient(${hexToRGBA(
    colors?.black,
    bgImageOpacity
  )}, ${hexToRGBA(colors?.black, bgImageOpacity)}), url(${bgImage});`}
  ${bgImage && noBgOpacity && `background-image: url(${bgImage});`}
  ${anchor && CSS_CURSOR_POINTER};
  &:hover {
    ${anchor && cssBoxShadow(colors)};
  }
`
